<template>
  <act-container>

    <action-modal v-show="isModalOpen"
    @onOverlayClick="toggleUpdateCreditTransactionModal">

      <br>
      <h6>Editar transação</h6>
      <br>

      <act-form>

        <act-row>
          <act-col :w="12">
            <action-input
            label="Cliente"
            type="text"
            disabled
            :value="creditTransactionModal.client_name"/>
          </act-col>
          <act-col :w="12">

            <action-input
            label="Valor"
            type="number"
            disabled
            :value="creditTransactionModal.value"
            />
          </act-col>
          <act-col :w="6">  

            <action-input
            label="Data da compra"
            v-model="creditTransactionModal.transaction_date"
            disabled
            type="date"/>
          </act-col>

          <act-col :w="6" style="margin: auto 0;">
            <label for="credit-modal__payment_method">
              <p>Forma de pagamento</p>
            </label>
            <select name="credit-modal__payment_method"
              :disabled="isStatusPaid"
              v-model="creditTransactionModal.payment_method_id">
              <option v-for="(paymentMethod, paymentMethodIndex) in Object.keys(paymentMethodsMap)" :key="paymentMethodIndex"
              :value="paymentMethodsMap[paymentMethod]">
                {{ paymentMethod }}
              </option>
            </select>
          </act-col>

        </act-row>

        <act-row>
          <act-col :w="6">

            <action-input
            label="Data do pagamento"
            :disabled="isStatusPaid"
            v-model="creditTransactionModal.payment_date"
            type="date"/>
          </act-col>
          <act-col :w="6">

            <action-input
            label="Data do Vencimento"
            :disabled="isStatusPaid"
            v-model="creditTransactionModal.expiration_date"
            type="date"/>
          </act-col>
        </act-row>

        <act-row>
          <act-col :w="12">
            <action-input
            label="Descrição"
            type="text"
            :disabled="isStatusPaid"
            v-model="creditTransactionModal.description"
            />
          </act-col>
        </act-row>


        <act-row>
          <action-button
          :disabled="isStatusPaid"
          @click.native.prevent="updateTransaction($event)">
            <template v-slot:content>
              Salvar
            </template>
          </action-button>
        </act-row>
      </act-form>

    </action-modal>

    <br>
    <act-row style="margin-inline: 2em">
      <p style="color: #624CAB;">Histórico de Crédito</p>
    </act-row>
    <br>

    <act-search-bar style="color: white;" v-model="filterString"/>

    <act-row>
      
      <act-col :w="12">
        <h6 style="color: #624CAB;">Transações</h6>
      </act-col>
      <act-table style="color: white;">
        <tr>
          <th>Id</th>
          <th>Cliente</th>
          <th>Valor da transação</th>
          <th>Forma de pagamento</th>
          <th>Data da transação</th>
          <th>Data do pagamento</th>
          <th>Vencimento</th>
          <th>Status</th>
          <th><small>Opções</small></th>
        </tr>
        <tr v-for="purchaseTransaction, purchaseTransactionIndex in filteredClientsBalanceList" :key="purchaseTransactionIndex">
          <td>{{ purchaseTransaction.id  }}</td>
          <td>{{ purchaseTransaction.client_name }}</td>
          <td>
            <p style="padding-inline: 2em;">R$ {{ parseInt(purchaseTransaction.value).toFixed(2) }}</p>
          </td>

          <td>
            <act-chips>{{ parsedPaymentMethod(purchaseTransaction.payment_method_id) }}</act-chips>
          </td>

          <td>{{ purchaseTransaction.transaction_date ? purchaseTransaction.transaction_date : "Pendente" }}</td>
          <td>{{ purchaseTransaction.payment_date ? purchaseTransaction.payment_date : "Pendente" }}</td>
          <td>{{ purchaseTransaction.expiration_date ? purchaseTransaction.expiration_date : "Não atribuído" }}</td>

          <td><act-chips>{{ parsedTransactionStatus(purchaseTransaction.status_id) }}</act-chips></td>
          <!-- <td>{{ purchaseTransaction.status }}</td> -->

          <td class="act-table__end-dropdown-button">
            <act-dropdown-button :bind="purchaseTransaction" :label="'...'" :items="creditTransactionListDropdownOptions"/>
          </td>
        </tr>
      </act-table>
    </act-row>

  </act-container>
</template>

<script>
import { mapGetters } from 'vuex'
import ActChips from '../../../components/ActChips.vue'
import ActDropdownButton from '../../../components/ActDropdownButton.vue'
import ActionButton from '../../../components/ActionButton.vue'
import ActSearchBar from '../../../components/ActSearchBar.vue'
import ActTable from '../../../components/ActTable.vue'
import ActForm from '../../../components/forms/actForm.vue'
import ActionInput from '../../../components/forms/ActionInput.vue'
import ActCol from '../../../components/layout/ActCol.vue'
import ActContainer from '../../../components/layout/ActContainer.vue'
import ActRow from '../../../components/layout/ActRow.vue'
import ActionModal from '../../../components/modals/actionModal.vue'

export default {
	name: 'dashboard-operations-credit-history',
	components: { ActContainer, ActRow, ActSearchBar, ActCol, ActDropdownButton, ActTable, ActChips, ActionModal, ActForm, ActionInput, ActionButton },

	data()
	{
		return {

			filterString: '',
      
			isModalOpen: false,
			modalFlag: null,

			paymentMethodsMap: {
				'Boleto': 1,
				'Pix' : 2,
				'Depósito/Transição Bancária': 3,
				'Cartão': 4
			},

			creditTransactionModal: {
				id: null,
				client_name: '',
				value: 0,
				transaction_date: null,
				payment_date: null,
				status_id: null,
				payment_method_id: null,
				expiration_date: null,
				description: ''
			},

			creditTransactionListDropdownOptions: {
				'Atualizar' : this.toggleUpdateCreditTransactionModal,
				'Cancelar' : 'disabled'
			}
		}
	},

	computed: {
		
		...mapGetters('creditStore', ['computedCreditTransactionHistory']),
		...mapGetters('authStore', ['loggedInUser']),


		filteredClientsBalanceList()
		{
			return this.computedCreditTransactionHistory.filter(transaction => {

				const fields = [ transaction.id, transaction.client_name ]

				let match = fields.some(field => 
				{
					if(typeof field === undefined)
					{
						return true
					}

					return field
						?.toString()
						.toLowerCase()
						.includes(this.filterString)
				})

				if(match)
				{
					return transaction
				}
			})
		},

		isStatusPaid()
		{
			return this.creditTransactionModal.status_id === 7
		}
	},

	async beforeMount()
	{
		await this.$store.dispatch('creditStore/getCreditTransactionHistory', this.loggedInUser.partner_id)
	},

	methods: {

		async updateTransaction()
		{
			const action = await this.$store.dispatch('creditStore/updateCreditTransaction',
				{
					transactionId: this.creditTransactionModal.id,
					transactionRecord: this.creditTransactionModal
				})

			this.toggleUpdateCreditTransactionModal()

			if('status' in action && action.status === 'error')
			{
				throw 'Error while updating transaction entry'
			}
		},

		toggleModal()
		{
			this.isModalOpen = !this.isModalOpen
		},

		toggleUpdateCreditTransactionModal(transaction)
		{
			this.toggleModal()

			if(!this.isModalOpen)
			{
				this.creditTransactionModal = {
					id: null,
					client_name: '',
					value: 0,
					status_id: null,
					transaction_date: null,
					payment_date: null,
					expiration_date: null
				}
			}
			else
			{
				this.creditTransactionModal = Object.assign(this.creditTransactionModal, transaction)
			}
		},

		parsedTransactionStatus(statusId)
		{
			let value = 'Pendente'
			switch(statusId)
			{
				case 6: value = 'Pendente'
					break

				case 7: value = 'Pago'
					break

				case 8: value = 'Vencido'
					break

				default:
					break
			}

			return value
		},

		parsedPaymentMethod(paymentMethodId)
		{
			return Object.keys(this.paymentMethodsMap).find(k => {

				return this.paymentMethodsMap[k] === paymentMethodId
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.act-table__end-dropdown-button
{
  display: flex;
  text-align: end;
  align-items: center;
  padding: .5em
}
</style>