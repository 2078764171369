<template>
  <div id="loading-screen" class="loading-screen">
    <div>
      <svg width="287" height="251" viewBox="0 0 287 251" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0)">
          <path
            d="M262 152.1C257.9 152.1 254 151 250.5 149C249.8 148.6 249.3 148.6 248.6 148.9C229.3 156.2 209.9 163.3 190.6 170.8C164.2 181 137.9 191.2 111.7 201.7C86.4 211.9 61.2 222.4 36 232.8C35.3 233.1 35 233.5 34.9 234.2C33.8 242.3 28.5 247.7 20.6 249.1C11.8 250.7 2.60001 244.2 1.30001 235.3C0.200006 228.1 2.60001 222.3 8.60001 218C9.00001 217.7 9.3 217.3 9.40001 216.8C13.4 199.3 17.5 181.8 21.4 164.2C24.9 148.2 28.4 132.2 31.7 116.2C34.8 101.2 37.8 86.2 40.8 71.2C43.4 58 46 44.5 48.6 31C49 29 49.4 26.9 49.7 24.9C49.8 24.5 49.6 23.9 49.2 23.6C45.4 19.9 44 15.4 45.5 10.3C47 5.1 50.7 2.1 56 1.2C62.9 -1.78814e-06 69.9 5.2 70.7 12.1C70.8 13.1 71.2 13.6 72.1 14C98.2 27.1 124.2 40.4 150.3 53.4C174 65.2 197.8 76.8 221.6 88.4C233.3 94.1 245.1 99.7 256.8 105.3C257.3 105.6 258 105.6 258.6 105.6C270.8 103.7 282.7 112.2 284.9 124.3C287.3 137.3 279.1 149.3 266.1 151.7C264.7 152 263.4 152 262 152.1ZM30.9 221.1C31.3 220.9 31.7 220.8 32 220.6C67 205 102.1 189.6 137 173.9C170.6 158.7 204.1 143.1 237.6 127.7C238.4 127.4 238.6 126.9 238.7 126.1C238.9 124.6 239.2 123.1 239.6 121.7C239.9 120.8 239.7 120.4 238.9 119.9C199.9 97 160.6 74.6 120.9 53C103.3 43.2 85.4 33.7 67.6 24C67 23.7 66.6 23.7 65.9 24.1C64.5 25 63 25.7 61.4 26.4C60.7 26.7 60.4 26.9 60.3 27.7C58.8 36.7 57.3 45.7 55.8 54.7C53.9 66.3 51.9 77.9 50 89.5C48.3 100 46.6 110.5 45 121.1C43.3 131.9 41.6 142.7 39.9 153.6C38.2 164.8 36.5 175.9 34.9 187.1C33.3 197.8 31.8 208.5 30.1 219.2C29.9 220 30.1 220.5 30.9 221.1Z"
            fill=""
            stroke="#62266F"
            stroke-width="2"
            stroke-miterlimit="10"
            class="loading-anim-001"
          ></path>
          <path d="M57 185.2C64 141.5 71 98 78 54.4C119 76.3 160 98.1 201.1 120C153 141.8 105.1 163.4 57 185.2Z" fill="" stroke="#62266F" stroke-width="2" stroke-miterlimit="10" class="loading-anim-002"></path>
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="286.3" height="250.4" fill="white" class="loading-3"></rect>
          </clipPath>
        </defs>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
	name: 'loadingScreen'
}
</script>

<style lang="scss">
.loading-screen {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(17, 13, 20);
  z-index: 99;
  top: 0px;
  flex-direction: column;
  flex-wrap: nowrap;
  & > div {
    margin-bottom: 2%;
  }
}
.loading-anim-001 {
  -webkit-animation: a_anim-svg-001 3s cubic-bezier(0.47, 0, 0.745, 0.715) 0s infinite, a_anim-svg-001__fill 5s cubic-bezier(0.47, 0, 0.745, 0.715) 1s infinite;
  animation: a_anim-svg-001 3s cubic-bezier(0.47, 0, 0.745, 0.715) 0s infinite, a_anim-svg-001__fill 5s cubic-bezier(0.47, 0, 0.745, 0.715) 1s infinite;
}
.loading-anim-001 {
  -webkit-animation: a_anim-svg-002 2s cubic-bezier(0.47, 0, 0.745, 0.715) 0s infinite, a_anim-svg-002__fill 5s cubic-bezier(0.47, 0, 0.745, 0.715) 1s infinite;
  animation: a_anim-svg-002 2s cubic-bezier(0.47, 0, 0.745, 0.715) 0s infinite, a_anim-svg-002__fill 5s cubic-bezier(0.47, 0, 0.745, 0.715) 1s infinite;
}

//#region Keyframes
@keyframes a_anim-svg-001 {
  0% {
    stroke-dashoffset: 735.7491455078125px;
    stroke-dasharray: 735.7491455078125px;
  }
  50% {
    stroke-dashoffset: 0;
    stroke-dasharray: 1471.7491455078125px;
  }
  100% {
    stroke-dashoffset: 735.7491455078125px;
    stroke-dasharray: 735.7491455078125px;
  }
}
@keyframes a_anim-svg-001__fill {
  0% {
    fill: transparent;
  }
  50% {
    fill: rgb(108, 37, 126);
  }
  100% {
    fill: transparent;
  }
}

@keyframes a_anim-svg-002 {
  0% {
    stroke-dashoffset: 214.75936889648438px;
    stroke-dasharray: 214.75936889648438px;
  }
  50% {
    stroke-dashoffset: 0;
    stroke-dasharray: 428.75936889648438px;
  }
  100% {
    stroke-dashoffset: 214.75936889648438px;
    stroke-dasharray: 214.75936889648438px;
  }
}
@keyframes a_anim-svg-002__fill {
  0% {
    fill: transparent;
  }
  50% {
    fill: rgb(108, 37, 126);
  }
  100% {
    fill: transparent;
  }
}
//#endregion Keyframes
</style>
