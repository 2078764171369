<template>
  <form class="action-multistep-form" @submit.prevent>

    <slot name="form-header"></slot>

    <br>

    <!-- Displayed only if it is not the first step -->
    <div v-if="currentStep > 1"
      class="action-form-previous-step-container step-nav-container">
      <ActionButton 
        class="previous-step-btn"
        @click.native="goPreviousStep">
        <template #content>{{ goBackButtonContent }}</template>
      </ActionButton>
    </div>

    <div class="action-form-step-container">
      <div v-for="n in stepCount" :key="n"
      v-show="n === currentStep">
        <slot :name="`step-${n}`"></slot>
      </div>
    </div>

    <!-- Displayed only if it is not the last step. User can decide how to handle last form step. -->
    <div class="action-form-next-step-container step-nav-container">
      <slot name="nextStepTrigger" v-if="currentStep < steps">
      <ActionButton class="next-step-btn"
      @click.native="goNextStep">
        <template #content>Próximo</template>
      </ActionButton>
      </slot>
    </div>

  </form>
</template>

<script>
import ActionButton from './ActionButton.vue'
export default {
	components: { ActionButton },
	emits: [
		'nextStep',
		'previousStep'
	],

	name: 'ActionMultistepForm',
	data(){
		return {
			steps: 1,
			currentStep: 1
		}
	},

	computed: {
		stepCount(){
			return this.steps
		}
	},

	props:{

		goBackButtonContent:{
			type: String,
			required: false,
			default: () => '↩'
		},

		stepValidation: {
			type: Boolean,
			default: function(){
				return false
			}
		},

		validationRule: {
			type: Function,
			default: function(){
				return null
			}
		}
	},

	mounted(){

		/** Counts only templates step-n */
		this.steps = Object.keys(this.$slots).filter(step => step.includes('step-')).length

		/** Handle parent component comunication through events */
		this.$el.addEventListener('gotoStep', ({ detail: { stepNum } }) => this.gotoStep(stepNum))
	},

	methods: {

		gotoStep(stepNum)
		{
			if(typeof stepNum !== 'number')
				console.warn('Expected number as input to gotoStep')
			
			if(stepNum >= 1 && stepNum <= this.steps)
				this.currentStep = stepNum
		},

		goNextStep(){
			if(this.currentStep + 1 > this.steps)
				return

			/** Runs user defined validation on form when changing steps if specified. */
			/** Retuns if do not pass validation */
			if(this.stepValidation)
			{
				if(!this.validationRule())
					return
			}

			this.currentStep += 1
			this.$emit('nextStep', { currentStep: this.currentStep })
		},

		goPreviousStep(){
			if(this.currentStep - 1 < 1)
				return

			this.currentStep -= 1
			this.$emit('previousStep', { currentStep: this.currentStep })
		}
	}
}
</script>

<style lang="scss" scoped>
.action-form-step-container
{
  width: 100%; height: 100%;
  padding: .5rem;

  margin-bottom: 4rem;
}
.previous-step-btn{
  margin: .5rem 0;
  padding: .5rem 2rem !important;
  max-width: max-content;
}
.step-nav-container{
  min-height: 2rem;
}
.action-form-previous-step-container{  
  text-align: center;
}

.action-form-next-step-container{  
  text-align: end;
}

</style>