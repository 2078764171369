<template>
  <act-container id="dashboard">
    <!-- Hotfix conditional do hide tabs in the home view -->
    <div class="act-tab" v-if="$route.fullPath.match('\(projects\)\|\(playlists\)')"
      style="padding-inline: 8.9% !important">
      <div v-for="(tab, tabIndex) in navigationTabs" :key="tabIndex" class="tab-item" :data-tab-active="$route.path.includes(tab.to)">
        <router-link :to="tab.to">
          <span>{{ tab.label }}</span>
        </router-link>
      </div>
    </div>
    <router-view>
    </router-view>
  </act-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import ActContainer from '../../components/layout/ActContainer.vue'

export default {
	components: { ActContainer },
	name: 'DashboardView',
	data(){
		return {

			navigationTabs: [
				{
					label: 'Projetos',
					to: '/dashboard/projects'
				},
				{
					label: 'Playlists',
					to: '/dashboard/playlists'
				}
			]
		}
	},

	computed: {
		...mapState({
			projects: state => state.dashboardStore.projects,
			currentProject: state => state.dashboardStore.currentProject,

			authStore: state => state.authStore,
			uiState: state => state.workspaceStore.uiState
		}),

		...mapGetters('accountStore', ['currentAccount']),

		userData()
		{
			return this.authStore.user
		}
	},

	async mounted()
	{
		/** Preemptivelly clears components and its lines from store.
     *  Prevents leftover data when navigation outside of the application */
		this.$store.dispatch('workspaceStore/resetState')

		await Promise.allSettled(
			[
				this.$store.dispatch('dashboardStore/fetchProjectsFromCurrentUser')
					.catch(error => console.warn(`Something wrong occured while fetching projects. Setting array to empty.\n Error:${error}`)),
				this.$store.dispatch('dashboardStore/getPlaylists', { clientId: this.currentAccount.id })
			])
	}
}
</script>

<style lang="scss">
.act-tab
{
  display: flex;
  margin-inline: 0rem;

  font-size: var(--fs-200);
}

.tab-item
{
  border: none;
  appearance: none;
  padding: .5rem 3rem;

  & > a { color: var(--clr-neutral-600); text-decoration: none !important; }
  
  &[data-tab-active=true]
  {
    --active-highlight: var(--clr-neutral-200);

    background-color: var(--clr-neutral-700);
    border-bottom: .125rem solid var(--active-highlight);

    & > a { color: var(--active-highlight) !important; }
  }
}

#dashboard
{
  display: block;
  padding: 1rem;
  background-color: var(--clr-neutral-900);
}
.dashboard
{
  display: grid;
  position: relative;
  grid-template-columns: repeat(12, 1fr);
  background: var(--clr-neutral-100);
  column-gap: 1.75rem;
  grid-template-rows: 1fr;
  padding: 1rem 1rem 0 1rem;
  height: calc(100% - 2rem); // Gets delta from navbar
  
  background: var(--clr-neutral-900);

  .dashboard-view-btn
  {
    background: var(--clr-purple-300);
    color: var(--clr-neutral-100) !important;

    &:hover
    {
      background: var(--clr-purple-200);
    }
  }
}

.user-info-container
{
  max-height: 92vh;
  grid-column: 1/4;
  padding: .5rem 1rem;
  border-radius: .5rem;
  background: var(--clr-neutral-900);

  .user-name-info
  {
    color: var(--clr-purple-300);
    margin-bottom: .5rem;
  }
}

.user-profile-icon
{
  font-size: 6rem; // Not a type font
  padding: .25rem;
  margin-bottom: 1rem;
  background: var(--clr-neutral-800);
  color: var(--clr-purple-300) !important;
  border: .5rem solid var(--clr-purple-300);
  border-radius: 50%;
}
.material-symbols-outlined
{
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}
</style>