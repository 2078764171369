<template>
  <div :class="classes">

    <!-- Vanilla label -->
    <label v-if="label && !labelPlaceholder" for="a-input"
    :class="['a-label', 'a-vanilla']">
      {{ label }}
    </label>

    <!-- @todo Wrap all default html input attributes as component props -->

    <input
    :type="type"
    :pattern="inputPattern"
    :value="value"
    :required="required"
    :disabled="disabled"
    :checked="checked"
    :name="`${name ? name : false}`"
    :min="min"
    :max="max"
    :autofocus="autofocus"
    @input="updateValue($event)"
    @keydown.enter.prevent
    :placeholder="placeholder"
    :class="inputClasses"/>

    <!-- Cooler floating label -->
    <label v-if="labelPlaceholder && !label" for="a-input"
    :class="labelClasses">
      {{ labelPlaceholder }}
    </label>
  </div>
</template>

<script>
export default {
	name: 'ActionInput',
	data() {
		return {
			regexPatterns: {
				email: null // /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/
			}
		}
	},

	computed: {

		inputPattern()
		{
			/** @var {regex} pattern */
			return this.regexPatterns[this.type]
		},

		classes()
		{
			return {
				'a-input-component': true,
				'with-label-placeholder' : this.labelPlaceholder,
				'with__check_or_radio' : this.type === 'checkbox' || this.type === 'radio',
				'with__checkbox' : this.type === 'checkbox',
				'with__radio' : this.type === 'radio'
			}
		},

		inputClasses()
		{
			return {
				'a-input' : true,
				'a-type-range' : this.type === 'range',
				'a-type-checkbox' : this.type === 'checkbox'
			}
		},

		labelClasses()
		{
			return {
				'a-label' : true,
				'a-label-placeholder' : this.labelPlaceholder
			}
		}
	},

	props: {
		value: {
			type: [String, Number]
		},

		name: {
			required: false
		},

		required: {
			type: Boolean,
			default(){
				return false
			}
		},
		autofocus: {
			type: Boolean,
			default(){
				return false
			}
		},
		min: {
			type: String,
			required: false
		},
		max: {
			type: String,
			required: false
		},

		checked: {
			type: Boolean,
			required: false
		},

		type: {
			type: String,
			required: false,
			validator(value){
				return ['text', 'tel', 'time', 'date', 'checkbox', 'radio', 'email', 'number', 'range', 'url', 'password']
					.includes(value)
			},
			default(){
				return 'text'
			}
		},
		label: {
			type: String,
			required: false
		},

		disabled: {
			type: Boolean,
			required: false,
			default(){
				return false
			}
		},

		placeholder: {

			type: String,
			required: false,
			default: () => { return ' '}
		},

		labelPlaceholder: {
			type: String,
			required: false
		}
	},

	methods: {
		updateValue({ target: { value }}){

			this.$emit('input', value)
		}
	}
}
</script>

<style lang="scss">

.a-input-component
{
  position: relative;
  margin: .25rem 0;

  &.with-label-placeholder
  {
    height: 4rem;
    padding-top: 2rem;
  }

  &.with__check_or_radio
  {
    @include flex(null, $align: baseline);

    label
    {
      margin: 0;
    }    
  }

  .a-input
  {
    @include borderless;
    width: 100%;
    position: relative;
    padding: .5rem 1rem;

    border-radius: .5rem;
    background: var(--clr-neutral-800);

    &.a-type-range
    {
      -webkit-appearance: none;
      padding: .5rem 0 !important;
      background-color: var(--clr-neutral-800);
      border: 2px solid var(--clr-neutral-700);

      &::-webkit-slider-runnable-track
      {
        -webkit-appearance: none;
        height: .25rem;
        padding: .25rem;
        margin-top: -.65rem;
        color: var(--clr-blue-800);
      }

      &::-webkit-slider-thumb
      {
        -webkit-appearance: none;
        cursor: ew-resize;
        width: 10px; height: 10px;
        border-radius: 50%;
        border: 2px solid var(--clr-purple-400);
        background-color: var(--clr-purple-400);
      }
    }

    &.a-type-checkbox
    {
      margin: 0 .5rem 0 .5rem;
    }

    &.with__radio
    {
      margin: inherit 1em !important;
    }

    /** Update label based on input states */
    &:focus
    {
      background: var(--clr-neutral-1000);

      & + .a-label-placeholder 
      {
        opacity: 1 !important;
        margin-block: -.25rem;
      }

      &::placeholder
      {
        opacity: 0 !important;
      }
    }

    &:not(:focus)
    {
      padding: .5rem .75rem;
      transition: .2s ease-in;

      &:not(:placeholder-shown)
      {
        & + .a-label-placeholder
        {
          left: .5rem;
        }
      }
    }

    &:focus, &:not(:placeholder-shown)
    {
      color: var(--clr-neutral-200);

      & + .a-label-placeholder
      {
        left: .25rem; top: 1rem;
        margin-block: -.25rem;

        font-size: var(--fs-200);
        font-weight: var(--fw-500); // medium

        color: var(--clr-purple-100) !important;
      }
    }

    &:required
    {
      &:invalid
      {
        &:focus
        {
          & + .a-label-placeholder
          {
          //
          }
        }
      }

      &:valid
      {
        &:focus
        {
          & + .a-label-placeholder
          {
            color: var(--clr-green-400) !important;
          }
        }
      }
    }

    &:user-valid
    {
      & + .a-label-placeholder
      {
        color: var(--clr-blue-800) !important
      }
    }

    /** Disabled state */
    &:disabled
    {
      background-color: var(--clr-neutral-600) !important;

      &.a-type-range
      {
        cursor: no-drop;

        &::-webkit-slider-thumb
        {
          background-color:  darken(clr(neutral), 20);
          border: 2px solid darken(clr(neutral), 20);
          cursor: no-drop;
          border-radius: 50%;
        }
      }
    }
  }

  .a-label
  {
    font-weight: normal;

    @include non-selectable;

    &.a-vanilla
    {
      line-height: 2.4rem;
    }

    &.a-label-placeholder
    {
      position: absolute;
      transition: all .25s ease-in-out;
      top: 2.5rem; left: .75rem;

      color: gray !important;
    }
  }
}
</style>
