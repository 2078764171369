import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import axios from 'axios'
import moment from 'moment'

import { accountStore, analyticsStore, authStore, creditStore, dashboardStore, ioStore, partnerStore, workspaceStore } from './modules'
import { RootState } from './index.d'
moment.locale('pt-br')


Vue.use(Vuex)

axios.defaults.baseURL = `${process.env.VUE_APP_API_BASE_URL}/api`


export const storeOptions: StoreOptions<RootState> = {
	state: {

		contextMenu: {
			isOpen: false,
			context: 'workspace' || 'component' || 'workspaceInteraction' || 'workspaceInteractionElement',
			x: 0, y: 0
		}
	},
	modules: { authStore, creditStore, analyticsStore, accountStore, dashboardStore, workspaceStore, ioStore, partnerStore },
	plugins: [createPersistedState()],
	getters: {

		globalIsProjectEditable(_, rootGetters) {
			// Meant to prevent external control over client projects
			if (rootGetters['accountStore/isMaster']) { return false }

			return rootGetters['dashboardStore/isProjectEditable']
		}
	},
	mutations: {},
	actions: {

		/**
		 * Handles form submit action.
		 *
		 * @param {Object} formData - Data received from the form.
		 * @param {formDataCallback} callbackFn  - Method to be used to submit data
		 * @param {array} validationRules  - Method to be used to submit data
		 *
		 * @todo Think of edge cases around this implementation,
		 *       for example, callbackFn that receive destructured parameters
		 */
		submitForm({ dispatch }, { formData, callbackFn, rules = [] }) {
			dispatch('validateFormData', { formData: formData, rules: rules })
				.then(validation => {

					if (validation.success === true) {

						callbackFn(formData)
					}
					else return validation
				})
		},

		/**
		 * Validates form data.
		 *
		 * @param {Object} formData - Data to be validated.
		 * @param {boolean} acceptNullOrEmpty - If set to true,
		 *        function will accept  should `null` or `empty` values. Default: `false`.
		 *
		 * @returns boolean
		 *
		 * @todo For additional validation methods, they should always
		 *       return as soon as the condition is not met.
		*/
		validateFormData(context, { formData, rules }) {
			let isValid = true
			let errors: Array<{ field: string, message: string }> = []

			if (rules.includes('EmptyOrNull')) {
				Object.keys(formData).forEach(key => {

					if (formData[key] === null || formData[key] === '') {
						isValid = false
						errors.push({
							field: key,
							message: 'contains empty or null characters.'
						})
					}
				})
			}

			return { success: isValid, withErrors: errors }
		}
	}
}

export default new Vuex.Store<RootState>(storeOptions)