<script>
export default {

	methods: {

		/**
     * @param {HTMLEvent} event
     * @param {{ isOpen: bool, id: number }} dropdown
     * @param {{ id: number }} payload
     */
		toggleDropdownMenu(event, dropdown, { id })
		{
			let { isOpen } = dropdown

			dropdown.isOpen = !isOpen

			if(isOpen)
			{
				dropdown.id = id
			}
		}
	}
}
</script>