<template>
  <div :class="classes">
    <slot></slot>
  </div>
</template>

<script>
export default {
	name: 'act-side-menu-header',


	computed: {

		classes()
		{
			return {
				'act-side-menu-header' : true 
			}
		}
	}

}
</script>

<style lang="scss" scoped>

@import '@/styles/scss/mixins.scss';

.act-side-menu-header
{
  @include flex;

  padding: 1rem .5rem;

  user-select: none;
}
</style>