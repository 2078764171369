import { AxiosInstance } from 'axios'

export default class BaseService {

	provider: AxiosInstance

	resourcePrefix?: string

	constructor(provider: AxiosInstance) {
		this.provider = provider

		// Todo: Add requests and responses definition
	}
}
