var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"a-interaction",attrs:{"id":"interaction-drag-n-drop"}},[_c('div',{staticClass:"interaction-drag-n-drop"},[_vm._l((_vm.draggableElements),function(element){return _c('VueDraggableResizable',{key:element.id,class:[
          'a-interaction-element',
          {
            '__draggable' : element.type === 2,
            '__selected_pair_drag': _vm.selectedElement.id === element.attributes.pairElementId
          }
        ],style:(`background: url('${_vm.$ImgLink}/interaction/${element.properties.style.backgroundImg}');
        ${_vm.selectedElement.id === element.id ? 'border: 2px dashed purple;' : ''}
        `),attrs:{"id":`element-${element.id}`,"title":element.label,"parent":true,"resizable":true,"draggable":true,"lockAspectRatio":true,"w":parseInt(element.properties.transform.width),"h":parseInt(element.properties.transform.height),"x":parseInt(element.properties.transform.x),"y":parseInt(element.properties.transform.y)},on:{"dragging":function($event){return _vm.onDragging(element)},"dragstop":function($event){return _vm.onDragStop(element)},"resizing":function($event){return _vm.$emit('elementresizing', element)},"resizestop":function($event){return _vm.$emit('element-resize-stop', element)}},nativeOn:{"mouseenter":function($event){return _vm.$emit('elementmouseenter', element)},"mouseleave":function($event){return _vm.$emit('elementmouseleave', element)},"click":function($event){$event.preventDefault();return _vm.selectElement(element)},"contextmenu":function($event){$event.preventDefault();return _vm.selectElement(element)}}})}),_vm._l((_vm.dropableElements),function(element){return _c('VueDraggableResizable',{key:element.id,class:[
        'a-interaction-element',
          {
            '__dropable' : element.type === 3,
            '__selected_pair_drop' : _vm.selectedElement.id === element.attributes.pairElementId
          }
        ],style:(`background: url('${_vm.$ImgLink}/interaction/${element.properties.style.backgroundImg}');
        ${_vm.selectedElement.id === element.id ? 'border: 2px dashed purple;' : ''}`),attrs:{"id":`element-${element.id}`,"title":element.label,"parent":true,"resizable":true,"draggable":true,"lockAspectRatio":true,"w":parseInt(element.properties.transform.width),"h":parseInt(element.properties.transform.height),"x":parseInt(element.properties.transform.x),"y":parseInt(element.properties.transform.y)},on:{"dragging":function($event){return _vm.$emit('elementdragging', element)},"resizing":function($event){return _vm.$emit('elementresizing', element)}},nativeOn:{"mouseenter":function($event){return _vm.$emit('elementmouseenter', element)},"mouseleave":function($event){return _vm.$emit('elementmouseleave', element)},"click":function($event){$event.preventDefault();return _vm.selectElement(element)},"contextmenu":function($event){$event.preventDefault();return _vm.selectElement(element)}}})})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }