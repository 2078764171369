import {ActionContext, Module} from 'vuex'
import type { RootState, IoStoreState } from '../index.d'

const namespaced = true

const state : Readonly<IoStoreState> = {
	mouseState: {
		x: 0, y: 0,
		isDrawingLine: false,
		elementSelected: true
	},
	keyboardState: {
		isCtrlDown: false,
		isSpaceDown: false
	}
}
const getters = {}
const mutations = {
	/**
     * Binds mouse position to global variable mouseState.
     */
	trackMouseCoordinates(state: IoStoreState, event : MouseEvent) {
		state.mouseState.x = event.clientX
		state.mouseState.y = event.clientY
	},

	/**
   * Toggles the `isDrawingLine` state variable.
   *
   * @param {state} state
   * @param {boolean} value - Value to set the variable to. If a value is not provied
   *                          sets the inverse.
   */
	toggleIsDrawingLine(state : IoStoreState, value: boolean = !state.mouseState.isDrawingLine){
		state.mouseState.isDrawingLine = value
	},

	/**
   * Toggles the `ElementSelected` state variable.
   * 
   * @param {state} state 
   * @param {boolean} value - Value to set the variable to. If a value is not provied
   *                          sets the inverse.
   */
	toggleElementSelected(state: IoStoreState, value: boolean = !state.mouseState.elementSelected){
		state.mouseState.elementSelected = value
	}
}
const actions = {
	//#region API requests

	//#endregion
	//#region Mutation wrappers
	trackMouseCoordinates({ commit } : ActionContext<IoStoreState, any>, payload : MouseEvent) {
		commit('trackMouseCoordinates', payload)
	},

	toggleIsDrawingLine({ commit }: ActionContext<IoStoreState, any>, payload: boolean){
		commit('toggleIsDrawingLine', payload)
	},

	toggleElementSelected({ commit } : ActionContext<IoStoreState, any>, payload: boolean){
		commit('toggleElementSelected', payload)
	}
	//#endregion
}


/** User input handling module. */
const ioStore: Module<IoStoreState, RootState> = {
	namespaced,
	state,
	getters,
	mutations,
	actions
}

export default ioStore