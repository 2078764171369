<template>
  <form @submit.prevent="submitForm(form)"
  class="flex-vertical"
  style="gap: 1rem; width: 16rem; height: 14rem;">
    <h6>Editar</h6>

    <fieldset class="flex-vertical" style="gap: .5rem;">

      <legend class="fieldset-legend">Informações do usuário</legend>
      <hr style="width: 100%">

      <span readonly>Nome: {{ user.name }}</span>
      <label for="account-user-role">Função do usuário</label>
      <select id="account-user-role" :selected="form.role_id" v-model="form.role_id">
        <option v-for="(role, roleIndex) in computedAccountRoles" :key="roleIndex"
        :value="role.value">{{ role.name }}
        </option>
      </select>
    </fieldset>

    <section style="margin-top: 1rem;">
      <button style="width: 100%">Salvar alterações</button>
    </section>
  </form>
</template>

<script>
import AccountRolesMixin from './AccountRolesMixin.vue'
export default {
	name: 'EditAccountUserForm',
	extends: AccountRolesMixin,
	props: {
		user: {
			required: true,
			type: Object
		}
	},
	data()
	{
		return {
			
			form: { role_id: null }
		}
	},
	created()
	{
		this.form.role_id = this.user.role_id
	},

	methods: {

		submitForm({ role_id })
		{
			console.log(role_id)
		}
	}
}
</script>

<style lang="scss">
* {
  [readonly]
  {
    color: var(--clr-neutral-200);
  }
}

.fieldset-legend
{
  font-size: var(--fs-200);
  color: var(--clr-neutral-200);
}
</style>