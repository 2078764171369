<template>
  <div :class="classes">
    <small>
      <slot></slot>
    </small>
  </div>
</template>

<script>
export default {
	name: 'act-chips',

	props: {

		color: {
			type: String,
			default: 'primary',
			validator: ($value) => ['primary', 'secondary'].includes($value)
		}
	},

	computed:
	{
		classes()
		{
			return {

				'act-chips' : true,
				[`act-chips__${this.color}`] : true
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.act-chips
{
  font-size: 12px;
  padding: .25em .75em;
  border-radius: 1em;
  max-width: max-content;
  user-select: none;
  text-align: center;
}

.act-chips__primary
{
  color: var(--clr-neutral-100);
  background-color: clr(act_purple, base);
}

.act-chips__secondary
{
  background-color: var(--clr-neutral-200);
}
</style>