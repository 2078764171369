<template>
  <div class="operation__sub-header">
    <act-row class="operation__sub-header">
      <slot/>
    </act-row>
  </div>
</template>

<script>
import ActRow from '@/components/layout/ActRow.vue'

export default {
	components: { ActRow },
	name: 'operation-header'
}
</script>

<style lang="scss">
.operation__sub-header
{
  margin: 1rem;
  color: var(--clr-purple-300);
}
</style>