<script>
export default {

	data() {
		return {
			selectedElement: '' // empty
		}
	},

	props: {
		elements: {
			type: Array,
			required: true
		}
	},

	methods: {

		selectElement(element)
		{
			this.selectedElement = element
			this.$emit('elementclick', element)
		},

		/**
     * Computed style object to fix interactive element positioning.
     * @param {object} elObj Element object
     */
		elStyle(elObj){

			/**
       * Converts PX to Percentage based on a pixel font-size value.
       * 
       * @param {number} value  Value in pixels to be converted.
       * @param {number} defaultPxSize Default `font-size` value used in the document. Defaults to `16`.
       * @returns {number} Percentage value
       * 
       * @see https://www.unitsconverters.com/en/Px-To/Utu-4853-4854#formulaDiv
       */
			function convertPxToPercentage(value, defaultPxSize = 16) {
				const SCALE_FACTOR = 100 / defaultPxSize // Defaults to 6.25

				return value / SCALE_FACTOR
			}

			let scale = Math.max(
				convertPxToPercentage(elObj.properties.transform.width),
				convertPxToPercentage(elObj.properties.transform.height)
			)

			/**
       * Uses some magic numbers:
       * 
       * 1.625 - Kinda derives from SCALE_FACTOR
       * 1.88
       * 1.205
       * 1.25 - Have no idea where it comes from.
       * 
       */
			return {
				// width: `${scale}%`,
				height: `${scale}%`,
				top: `${convertPxToPercentage(elObj.properties.transform.y * 1.88)}%`,
				left: `clamp(
        0%,
        ${convertPxToPercentage(elObj.properties.transform.x / 1.205)}%,
        100%)`
			}
		},

		// Recebe os dados de uma interação e os emite para validação.
		submitInteraction(interactionData)
		{
			this.$emit('submitinteraction', { data: interactionData })
		}
	}

}
</script>

<style lang="scss">
@import '@/styles/scss/mixins.scss';

/* Overrides default VueDraggleResize class */
.vdr
{
  // border-radius: 100%; // Kinda helps fix image 'hitbox' issue.
  min-width: 30px !important; min-height: 30px !important;
}

.a-interaction
{
  @include fit-container;
  @include non-selectable;

  position: relative;
}

.a-submit-container
{
  @include non-selectable;
  position: relative;
  width: 100%;
  z-index: 1000;
  background: transparent;

  .a-submit-btn
  {
    @include hover-pointer;

    display: block;
    width: max-content;
    white-space: nowrap;
    border: none; border-radius: 0.5rem;
    padding: 0.5rem 1rem; margin: .65rem auto;
    font-weight: bold;
    color: #f5f5f5;
    background: #558b2f;
  }
}

/** animation scale-up-center */
@keyframes scale-up-center {
	0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
            opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
            opacity: 1;
  }
}
@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
            opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
            opacity: 1;
  }
}
</style>