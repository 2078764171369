<template>
  <act-row>
  <act-col :w="12">
    <ActionInput
    type="text"
    label="Procurar"
    placeholder="O que você está procurando?"
    :class="'act-search-input'"
    @input.native="updateValue($event)"
    />
  </act-col>
</act-row>
</template>

<script>
import ActRow from '@/components/layout/ActRow.vue'
import ActCol from '@/components/layout/ActCol.vue'
import { ActionInput } from '@/components'
export default {
	name: 'act-search-bar',
	components: { ActionInput, ActRow, ActCol },

	props :{

		value: {
			type: [String, Number],
			required: false,
			default: () => null
		}
	},

	methods:{

		updateValue({ target: { value }})
		{
			this.$emit('input', value)
		}
	}

}
</script>

<style>

</style>