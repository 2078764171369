<template>
  <aside :class="classes" :style="styles">
    <slot></slot>
  </aside>
</template>

<script>
export default {
	name: 'act-side-menu',

	props: {

		w: {
			type: Number,
			default: 16
		}
	},
  
	computed:{

		classes()
		{
			return {
				'act-side-menu' : true
			}
		},

		styles()
		{
			return {

				'width' : `${this.w}em`,
				'max-width' : `${this.w}em`
			}
		}
	}

}
</script>

<style lang="scss" scoped>

@import '@/styles/scss/mixins.scss';

.act-side-menu
{
  @include fit-container;

  position: fixed;
  z-index: 9999;

  // box-shadow: 1rem .25rem 1rem 0 rgba(0, 0, 0, .125);
}
</style>