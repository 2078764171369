<template>
  <act-container>

    <action-modal v-show="isAddCreditModalOpen"
    @onOverlayClick="toggleAddCreditModal">

      <br>
      <h6>Adicionar crédito</h6>
      <br>
      
      <act-form>

        <act-row>
          <act-col :w="12">
            <action-input name="credit-modal__value"
            label="Valor"
            type="number"
            v-model="addCreditForm.value"
            />
          </act-col>
        </act-row>

        <act-row style="align-items:center;">
          <act-col :w="6" :class="'credit__time_input'">

            <action-input name="credit-modal__value"
            label="Data da compra"
            type="date" 
            v-model="addCreditForm.transaction_date"/>
          </act-col>

          <act-col :w="6" :class="'credit__time_input'">
            <action-input
            label="Data do vencimento"
            type="date"
            v-model="addCreditForm.expiration_date"/>
          </act-col>
        </act-row>

        <act-row>

          <act-col :w="6" style="margin: auto 0;">
            <label for="credit-modal__payment_method_id">
              <p>Forma de pagamento</p>
            </label>
            <select name="credit-modal__payment_method_id"
              v-model="addCreditForm.payment_method_id">
              <option v-for="(paymentMethod, paymentMethodIndex) in Object.keys(paymentMethodsMap)" :key="paymentMethodIndex"
              :value="paymentMethodsMap[paymentMethod]">
                {{ paymentMethod }}
              </option>
            </select>
          </act-col>

          <act-col :w="6">
            <action-input
            label="Data do pagamento"
            type="date"
            v-model="addCreditForm.payment_date"/>
          </act-col>

          <act-col :w="12">
            <action-input
            label="Descrição"
            type="text"
            placeholder="Informações adicionais, etc"
            v-model="addCreditForm.description"/>
          </act-col>
        </act-row>

        <act-row style="justify-content: flex-end; margin-top: 1em;">
          <action-button
          @click.native.prevent="addCredit($event)">
            <template v-slot:content>
              Adicionar
            </template>
          </action-button>
        </act-row>

      </act-form>
    </action-modal>

    <operation-header>
      Controle de Crédito
    </operation-header>

    <act-search-bar style="color: white;" v-model="filterString"/>

    <br>

    <act-row>
      <act-col :w="12">
        <h6 style="color: #2395EB;">Clientes</h6>
      </act-col>
      <table class="client-balance__table">
        <tr>
          <th><small>Nome</small></th>
          <th style="display: flex; text-align: end; justify-content: space-between; padding: .5em">
            <small style="padding-inline: 2em">Saldo atual</small>
            <small>Opções</small>
          </th>
        </tr>
        <tr class="client-balance__table__date-row" v-for="client, clientIndex in filteredClientsBalanceList" :key="clientIndex">
          <td>
            <p>{{ client.name }}</p>
          </td>

          <td style="display: flex; text-align: end; align-items: center; padding: .5em">
            <p style="padding-inline: 2em;">R$ {{ parseClientBalance(client.current_balance) }}</p>
            <act-dropdown-button :bind="client" :label="'...'" :items="clientListDropdownOptions"/>
          </td>

        </tr>
      </table>
    </act-row>

  </act-container>
</template>

<script>
import ActCol from '@/components/layout/ActCol.vue'
import ActRow from '@/components/layout/ActRow.vue'
import ActSearchBar from '@/components/ActSearchBar.vue'
import ActContainer from '@/components/layout/ActContainer.vue'

import dropdownMixin from '@/mixins/dropdownMixin.vue'

import ActDropdownButton from '@/components/ActDropdownButton.vue'
import ActionModal from '@/components/modals/actionModal.vue'
import ActionInput from '@/components/forms/ActionInput.vue'
import ActionButton from '@/components/ActionButton.vue'
import ActForm from '@/components/forms/actForm.vue'
import OperationHeader from './shared/OperationHeader.vue'
import { mapGetters } from 'vuex'

export default {
	name: 'dashboards-operations-credit-management',
	components: { ActContainer, ActRow, ActCol, ActSearchBar, ActDropdownButton, ActionModal, ActionInput, ActionButton, ActForm, OperationHeader },
	extends: dropdownMixin,

	data()
	{
		return {

			filterString: '',
      
			isAddCreditModalOpen: false,

			clientListDropdownOptions: {
				'Adicionar crédito' : this.openAddCreditModal
			},

			paymentMethodsMap: {
				'Boleto': 1,
				'Pix' : 2,
				'Depósito/Transição Bancária': 3,
				'Cartão': 4
			},

			addCreditForm: {
				client_id: null,
				value: null,
				transaction_date: null,
				expiration_date: null,
				payment_date: null,
				payment_method_id: 1,
				description: null
			},

			parseClientBalance(value)
			{
				return parseInt(value).toFixed(2)
			}
		}
	},

	computed: {

		...mapGetters('creditStore', ['computedClientCreditInfoList']),
		...mapGetters('authStore', ['loggedInUser']),

		filteredClientsBalanceList()
		{
			return this.computedClientCreditInfoList.filter(({ name }) => name.toLowerCase().includes(this.filterString))
		}

	},

	async mounted()
	{
		await this.$store.dispatch('creditStore/getClientCreditList', this.loggedInUser.partner_id)
	},

	methods: {

		openAddCreditModal({ client_id })
		{
			this.toggleAddCreditModal()

			this.addCreditForm = { ... this.addCreditForm, client_id: client_id }
		},

		toggleAddCreditModal()
		{
			this.isAddCreditModalOpen = !this.isAddCreditModalOpen

			if(!this.isAddCreditModalOpen)
			{
				this.addCreditForm = {
					client_id: null,
					value: null,
					transaction_date: null,
					expiration_date: null,
					payment_date: null,
					payment_method_id: 1,
					description: null
				}
			}
		},

		async addCredit()
		{
			const action = await this.$store.dispatch('creditStore/addCreditToClient',
				{
					partner_id: this.loggedInUser.partner_id,
					client_id: this.addCreditForm.client_id,
					creditInfoRequest: this.addCreditForm
				})
      
			this.toggleAddCreditModal()

			if('status' in action && action.status === 'error')
			{
				throw 'An error occurred while adding credit to client'
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.inline-list-item
{
  display: inline-flex;
  justify-content: space-between;
}
.client-balance__table
{
  width: 100%;
  display: flex;
  flex-direction: column;
  color: var(--clr-neutral-100);

  tr
  {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    th, td
    {
      text-align: end;
      position: relative;

      &:nth-child(1)
      {
        text-align: start !important;
      }
    }
  }
}
.client-balance__table__date-row
{
  & > td 
  {
    border-spacing: 1em !important;
  }
}
.list-item-dropdown
{
  position: absolute;
  top: 100%;
  margin-top: .25rem;
  min-width: 6rem; width: 100%;
  height: 100%;
  z-index: 10;

  .list-item-dropdown-btn
  {
    text-align: start;
    border-radius: 0rem;
    width: 100%; height: 100%;
  }
}
.credit__time_input
{
  display: flex;
  flex-direction: row;

  label
  {
    padding-inline-end: .5;
  }
}
</style>