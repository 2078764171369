<template>
  <act-container id="dashboard-operations">

    <act-row style="margin-inline: 2em">
      <h6 style="color: #624CAB;">Operações</h6>
    </act-row>

    <act-row>
      <router-view></router-view>
    </act-row>
  </act-container>
</template>

<script>
import ActContainer from '../../../components/layout/ActContainer.vue'
import ActRow from '../../../components/layout/ActRow.vue'


export default {
	components: { ActContainer, ActRow },
	name: 'dashboard-operations'

}
</script>

<style lang="scss" scoped>

</style>