<template>
  <div id="act-dropdown-button">
    <action-button block
    @click.native.prevent="toggleDropdown()">
      <template v-slot:content>{{ label }}</template>
    </action-button>

    <ul :class="dropdownClasses" v-show="isActive">
      <li class="__dropdown_item" v-for="(item, itemIndex) in Object.keys(items)" :key="itemIndex">

        <action-button block
        :disabled="items[item] === 'disabled'"
        @click.native.prevent="callContextFunction(items[item])">
          <template v-slot:content>{{ item }}</template>
        </action-button>

      </li>
    </ul>

  </div>
</template>

<script>
import ActionButton from './ActionButton.vue'
export default {
	components: { ActionButton },
	name: 'act-dropdown-button',

	emits: ['toggle'],

	data()
	{
		return {
			isActive: false
		}
	},

	props: {

		label: {
			type: String,
			required: false,
			default: () => { return 'Dropdown' }
		},

		bind: {
			type: Object,
			required: false,
			default: () => { return null }
		},

		items: {
			type: Array,
			required: false,
			default: () => { return [] }
		},

		direction: {
			type: String,
			required: false,
			default: () => { return 'left' },
			validator(value)
			{
				return ['left', 'right'].includes(value)
			}
		}
	},

	computed: {

		dropdownClasses()
		{
			return {
				'act__dropdown' : true,
				[`__dropdown-dir__${this.direction}`]: true
			}
		}

	},

	methods: {

		toggleDropdown()
		{
			this.isActive = !this.isActive

			if(this.isActive)
				this.$emit('toggle', { 'uid': this.$vnode.componentInstance._uid })
		},

		callContextFunction(fn = () => {})
		{
			/** Fix for not beign able to set `capture` modifier on button */
			this.toggleDropdown()

			return this.bind !== null ? fn.call(this, this.bind) : fn()
		}
	}

}
</script>

<style lang="scss" scoped>
.act__dropdown
{
  position: absolute;
  top: 100%;
  margin-top: .25rem;
  min-width: 6rem; width: 100%;
  height: 100%;
  z-index: 10;

  .__dropdown_item
  {
    position: relative;
    text-align: start;
    width: 100%;
    
    & > * 
    {
      border-radius: 0rem !important;
    }
  }
}

.__dropdown-dir__left
{
  left: 0;
}
  
.__dropdown-dir__right
{
  right: 0;
}

</style>