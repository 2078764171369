<template>
  <act-container class="support-view__container">

    <!-- <h1 style="align-self: center">Suporte</h1> -->
    <router-view></router-view>
    
  </act-container>  
</template>

<script>
import ActContainer from '../../components/layout/ActContainer.vue'
export default {
	name: 'SupportView',
	components: { ActContainer }

}
</script>


<style lang="scss" scoped>
.support-view__container
{
  width: 100%;
  background: var(--clr-neutral-800);
}
</style>