<template>
  <div class="act-modal__container">

    <div class="act-modal" :style="propStyles">
      <slot></slot>
    </div>

    <div @click="$emit('onOverlayClick')" class="act-modal__overlay"/>
  </div>
</template>

<script>
export default {
	name: 'ActionModal',
	emits: ['onoverlayclick'],
	props: {

		'backgroundColor': {
			required: false,
			default: () => 'gainsboro'
		}
	},

	computed: {

		propStyles()
		{
			return {
        
				'background-color': this.backgroundColor
			}
		}
	}
}
</script>

<style lang="scss">

.act-modal__container
{
  display: grid;
  position: fixed;
  place-items: center;
  top: 0; left: 0;
  width: 100%; height: 100%;
  z-index: 1000 !important;

  .act-modal
  {
    position: absolute;
    width: max-content;
    height: max-content;
  
    border-radius: .25rem;
    z-index: 650 !important;
    padding: 1rem;
    cursor: auto;
    box-shadow: 0 .25rem 1rem .125rem rgba(0,0,0, .5);
  }

  .act-modal__overlay{
    width: 100%;
    height: 100%;
    cursor: auto;
    background: rgba(0, 0, 0, .33);
    z-index: -1 !important;
  }
}
</style>