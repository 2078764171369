<template>
  <div id="interaction-drag-n-drop" class="a-interaction">

    <!-- Workspace environment view -->
    <div class="interaction-drag-n-drop">

      <!-- Draggable element -->
      <VueDraggableResizable :id="`element-${element.id}`"
        v-for="element in draggableElements" :key="element.id" 
        :title="element.label"
        :class="
        [
          'a-interaction-element',
          {
            '__draggable' : element.type === 2,
            '__selected_pair_drag': selectedElement.id === element.attributes.pairElementId
          }
        ]"
        :style="`background: url('${$ImgLink}/interaction/${element.properties.style.backgroundImg}');
        ${selectedElement.id === element.id ? 'border: 2px dashed purple;' : ''}
        `"
        :parent="true"
        :resizable="true"
        :draggable="true"
				:lockAspectRatio="true"

        :w="parseInt(element.properties.transform.width)"
        :h="parseInt(element.properties.transform.height)"
        :x="parseInt(element.properties.transform.x)"
        :y="parseInt(element.properties.transform.y)"

      @mouseenter.native="$emit('elementmouseenter', element)"
      @mouseleave.native="$emit('elementmouseleave', element)"

        @click.native.prevent="selectElement(element)"
        @click.right.native.prevent="selectElement(element)"
        @dragging="onDragging(element)"
        @dragstop="onDragStop(element)"
        @resizing="$emit('elementresizing', element)"
				@resizestop="$emit('element-resize-stop', element)">
      </VueDraggableResizable>

      <!-- Dropable element -->
      <VueDraggableResizable :id="`element-${element.id}`"
        v-for="element in dropableElements" :key="element.id" 
        :title="element.label"
        :class="[
        'a-interaction-element',
          {
            '__dropable' : element.type === 3,
            '__selected_pair_drop' : selectedElement.id === element.attributes.pairElementId
          }
        ]"
        :style="`background: url('${$ImgLink}/interaction/${element.properties.style.backgroundImg}');
        ${selectedElement.id === element.id ? 'border: 2px dashed purple;' : ''}`"
        :parent="true"
        :resizable="true"
        :draggable="true"
				:lockAspectRatio="true"

        :w="parseInt(element.properties.transform.width)"
        :h="parseInt(element.properties.transform.height)"
        :x="parseInt(element.properties.transform.x)"
        :y="parseInt(element.properties.transform.y)"

        @mouseenter.native="$emit('elementmouseenter', element)"
        @mouseleave.native="$emit('elementmouseleave', element)"

        @click.native.prevent="selectElement(element)"
        @click.right.native.prevent="selectElement(element)"
        @dragging="$emit('elementdragging', element)"
        @resizing="$emit('elementresizing', element)">
      </VueDraggableResizable>
    </div>

    <!-- Este container e botão de enviar resposta acaba sendo genérico em várias interações,
    possivelmente seria melhor integrá-lo no componente pai. Analisar possíveis fallbacks. -->
  </div>
</template>

<script>
import VueDraggableResizable from 'vue-draggable-resizable'
import ElementMixin from './ElementMixin.vue'

export default {
	name: 'InteractionDragNDrop',
	mixins: [ElementMixin],
	components: { VueDraggableResizable },
	data(){
		return {
			isOverlapping: false,
			overlappingEl: null
		}
	},
  
	computed: {

		draggableElements(){

			return this.elements.filter(element => element.type === 2)
		},

		dropableElements(){

			return this.elements.filter(element => element.type === 3)
		}

	},

	methods: {

		onDragging(element)
		{
			this.$emit('elementdragging', element)
		},

		onDragStop(element)
		{
			this.$emit('element-drag-stop', element)
			this.snapElement(element)
		},

		snapElement(element)
		{
			//1 Check if currently dragged element is hovering another element.

			this.isHovering(element, this.dropableElements)
			//2. If it is and the element is a droppable region, snap to its position

		},

		isHovering(el, elementsArray)
		{
			const selectedDOMEl = document.getElementById(`element-${el.id}`)

			if(selectedDOMEl === undefined)
			{
				return false
			}

			const selectedDOMElRect = selectedDOMEl.getBoundingClientRect()

			elementsArray.forEach(element => {

				if(element.id === el.id) { return }

				const elementDOM = document.getElementById(`element-${element.id}`)

				if(elementDOM === undefined) { return }

				const elementDOMRect = elementDOM.getBoundingClientRect()

				const isOverlapping =
							(selectedDOMElRect.x + (selectedDOMElRect.width / 2)) >= elementDOMRect.x &&
							selectedDOMElRect.x <= (elementDOMRect.x + (elementDOMRect.width) - selectedDOMElRect.width/2)

							&& (selectedDOMElRect.y + (selectedDOMElRect.height /2)) >= elementDOMRect.y &&
							selectedDOMElRect.y <= (elementDOMRect.y + (elementDOMRect.height) - (selectedDOMElRect.height/2))

				if(isOverlapping)
				{
					this.isOverlapping = true
					this.overlappingEl = element

					// Todo: improve element snapping by snapping element to the center of its dropable area.
					/** Emits date of the currently dragged component so parent can handle its snapping */
					this.$emit('snapelement',
						{
							element: el,
							snapToCoordinates: { x: elementDOMRect.x, y: elementDOMRect.y }
						})
				}
			})
		}
	}
}
</script>

<style lang="scss">

.interaction-drag-n-drop{
  display: block;
  position: relative;
  width: 100%;height: 100%;
  z-index: 2000;
  background: transparent;
  // background: rgba(255, 0, 0, .25);
  pointer-events: none;

  .a-interaction-element{
    position: absolute;
    z-index: auto;
    pointer-events: all;
    background-attachment: fixed;
    background-size: contain !important;
		background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;

    &.__dropable{
      z-index: auto;
    }

    &.__draggable{
      z-index: 199 !important; // Has to be higher than their dropable areas
    }

    &.__selected_pair_drop{

      border: 2px dashed rgb(182, 45, 131);
    }

    &.__selected_pair_drag{

      border: 2px dashed rgb(23, 79, 210);
    }

    img {
      width: 100%; height: 100%;
      object-fit: contain !important;
			// object-fit: cover !important;

      &:hover{
        cursor: pointer !important;
      }

      &.interaction-bkg-img{
        z-index: 0 !important;
        pointer-events: all;
      }
      &.interaction-check-icon{
        position: absolute;
        top: 0; left: 0;
        z-index: 2 !important;

        opacity: .90;
      }
    }
  }
}

@media screen and (max-width: 1020px) {
  .element-user-view{
    // background: red;
    height: 37% !important;
  }
}
</style>
