import { AxiosInstance } from 'axios'

import AuthService from './AuthService'

import AnalyticsService from './AnalyticsService'
import CreditService from './CreditService'
import PartnerService from './PartnerService'
import PlaybackService from './PlaybackService'
import ProjectService from './ProjectService'
import SegmentNodeService from './SegmentNodeService'

import { provider, ActionAPIHeaders } from './config'
import SegmentNode from './SegmentNodeService'
import AccountService from './AccountService'

class _ActionAPI {

	provider: AxiosInstance
	SegmentNodeService: SegmentNode;
	Auth: AuthService;
	ProjectService: ProjectService;
	UserService: AccountService;
	CreditService: CreditService;

	PartnerService: PartnerService;
	PlaybackService: PlaybackService;
	AnalyticsService: AnalyticsService;

	constructor() {
		this.provider = provider

		/** Headers can be modified by instantiated services */
		this.Auth = new AuthService(this.provider)

		/** Register other services/resource providers */
		this.ProjectService = new ProjectService(this.provider)
		this.SegmentNodeService = new SegmentNodeService(this.provider)
		this.PartnerService = new PartnerService(this.provider)
		this.UserService = new AccountService(this.provider)

		this.PlaybackService = new PlaybackService(this.provider)

		this.AnalyticsService = new AnalyticsService(this.provider)

		this.CreditService = new CreditService(this.provider)
	}

	headers(): ActionAPIHeaders | undefined {
		return this.provider.defaults.headers
	}
}

export const ActionAPI = new _ActionAPI()