<template>
  <div class="modal-background">
    <div class="modal-container">
      <span class="modal-top-bar">
        <a href="" v-if="close"><span class="modal-close-btn"></span></a>
      </span>
      <div class="modal-content">
        <slot name="modal-body" class="modal-content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
	name: 'ModalBody',
	props: ['close']
}
</script>

<style scoped></style>
