
import { AccountSettings } from '@/store/modules/accountStore'
import BaseService from './BaseService'
import { AxiosInstance } from 'axios'

/**
 * @interface {code: { 200|201|403 }} AccountInvitationCodes
 */

export default class AccountService extends BaseService {

	constructor(provider: AxiosInstance) {
		super(provider)

		this.resourcePrefix = '/users'
	}

	/**
	 * @param {{ account_identifier: number|string }} params
	 * @returns {Promise<{ account_id: number, users: Array<{ name: string, hash: string, id: number }>}>}
	 */
	async getAccountUsers({ account_identifier }) {
		const response = await this.provider.get(`account/${account_identifier}/users`)
			.catch(error => {

				throw Error(error)
			})

		return response?.data
	}

	/**
	 * 
	 * @param {{ name: string, email: string, password: string, confirmation_password: string, role_id?: string}} request
	 * @param {string} invitationToken 
	 * @returns 
	 */
	async registerUser({ name, email, password, confirmation_password, role_id, partner_id }, invitationToken = null) {
		const response = await this.provider.post(`${this.resourcePrefix}`,
			{
				name: name,
				email: email,
				password: password,
				confirmation_password: confirmation_password,
				role_id: role_id,
				partner_id: partner_id
			},
			{ headers: { hasInvite: invitationToken } })
			.catch(error => {

				throw Error(error.response)
			})

		return response.data
	}

	async updateUser({ userId, data }) {
		const response = this.provider.patch(`${this.resourcePrefix}/${userId}`,
			{
				...data
			})

		return response
	}

	/** Due to current implemention, this function actually deletes the specified user
	 *  since theres yet to relation to an account system */
	async removeUserFromAccount({ /* accountId, */ userToRemoveId }: { userToRemoveId: number }) {
		const response = this.provider.delete(`${this.resourcePrefix}/${userToRemoveId}`,
			{
				data: {
					/* accountUserId: userToRemoveId, */
					userToRemoveId: userToRemoveId
				}
			})

		return response
	}

	async sendAccountInvitationEmail(account_id: number|string, email: string, role_id: 2|3|5) {
		const response = await this.provider.post(`account/${account_id}/invite`, { email: email, role_id: role_id })
			.catch(networkError => {

				const { response: { status } } = networkError

				if (status === 404)
				{
					throw ({ 'status': 404, 'message': 'Erro ao encontrar os dados da conta' })
				}
			})

		return response
	}

	/**
	 * @param {string} token uuid token
	 */
	async validateAccountInviteToken(token = '') {
		const response = this.provider.post('account/validate-invitation',
			{
				token: token
			})
			.catch(error => {
				return {
					status: error.response.status,
					data: error.response.data
				}
			})

		return response
	}

	async getAccountSettings(accountId: number | string): Promise<AccountSettings> {

		return this.provider.get(`account/${accountId}/settings`)
			.then(response => response.data)
	}
}