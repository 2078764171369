<template>
  <div class="buffering">
    <div class="buffering-info">

      <img src="../../assets/wallpapers/act_logo.png" alt="action-logo">

      <div data-progress-bar>
        <div class="progress"/>
      </div>

    </div>
  </div>
</template>

<script>
export default {
	name: 'player-buffering'
}
</script>

<style lang="scss" scoped>
.buffering
{
  display: grid;
  place-items: center;
  width: 100%; height: 100%;

  .buffering-info
  {
    width: 100%;
    display: grid;
    place-items: center;
    gap: .5rem;

    & > *
    {
      color: var(--gradient-purple-light)
    }

    img
    {
      width: 15%;
      padding: 2%;
    }
  }

  &.buffering__hidden
  {
    display: none;
  }
}

[data-progress-bar]
{
  --foreground-color: var(--clr-purple-400);
  --background-color: var(--clr-neutral-800);

  overflow: hidden;

  width: 15%; height: .75rem;
  position: relative;
  border-radius: 1rem;
  border: .0025rem solid var(--background-color);

  .progress
  {
    width: 100%; height: 100%;
    border-radius: .5rem; border: .0125rem solid var(--clr-neutral-800);
    float: left;
    line-height: 4rem;
    color: white;
    background: var(--clr-purple-300);

    animation-name: slideInFromLeft;
    animation-duration: 1.5s;
    animation-timing-function: cubic-bezier(0, .9, .9, .999);
    text-align: start;
  }
}
  
@keyframes slideInFromLeft
{
  0% {
    width: 0%;
  }
  
  99% {
    width: 99%;
  }
}

</style>