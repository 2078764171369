
export declare module Interaction {


	export enum Type {

		MultipleChoice = 1,
		DragNDrop = 3,
		Control = 4
	}
}

export declare module InteractionElement {

	export enum Type {

		Draggable = 2,
		DropZone = 3
	}
}

export enum InteractionElementType {
	Draggable = 2,
	DropZone = 3
}