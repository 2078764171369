<script>
export default {
	data()
	{
		return {
			accountRoles: [
				{ name: 'Editor', value: 2 },
				{ name: 'Produtor', value: 3 },
				{ name: 'Parceiro', value: 5 }
			]
		}
	},
	computed: {

		computedAccountRoles()
		{
			let isMaster = this.$store.getters['accountStore/isMaster']

			return this.accountRoles.filter(role => {

				/** If logged is user is not a partner, hide the partner options */
				if(!isMaster && role.name === 'Parceiro')
				{
					return
				}
        
				return role
			})
		},

		_editorRole()
		{
			return this.accountRoles.find(role => role.name.toLocaleLowerCase().includes('editor'))
		}
	}
}
</script>

<style lang="scss">
.flex-vertical
{
  display: flex;
  flex-direction: column;
}
</style>