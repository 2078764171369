import axios from 'axios'
import Router from 'vue-router'
import store from '../store'

import routes from './routes'
import { ActionAPI } from '@/services/ActionAPI'

const router = new Router({
	mode: 'history',
	routes
})

router.beforeEach(async (to, from, next) => {

	/** Guards authenticated routes. */
	if (to.matched.some(record => record.meta.requiresAuth)) {
		await store.dispatch('authStore/isUserLogged').then(isLogged => {

			if (!isLogged) {
				window.localStorage.clear()
				return next({ name: 'Login' })
			}

			/** Compatibility call for existing axios calls.
			 * 
			 *  In future implementations all api call should pass through their responsibile
			 *  services and set their respective request headers */
			if ('user' in store.state.authStore)
				axios.defaults.headers['userId'] = store.state.authStore.user.id

			// check if header is set
			if ('sessionToken' in store.state.authStore && ActionAPI.headers().Authorization === undefined) {
				ActionAPI.Auth.setAuthorizationHeader(store.state.authStore.sessionToken)
			}
		})
	}

	next()
})

export default router
