var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{class:['action-btn', {

  'btn-square' : _vm.square,
  'btn-disabled' : _vm.disabled,
  'btn-block' : _vm.block,
  'btn-outline' : _vm.outline
}],attrs:{"type":_vm.type,"disabled":_vm.disabled}},[_vm._t("icon"),_vm._t("content",function(){return [_vm._v("Button")]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }