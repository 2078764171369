<template>
  <div id="app">

    <!-- Used to display navigation menus on all views except the video player -->
    <template v-if="!isLoginView && isInteralView">
      <a-navbar @togglesidemenu="toggleSideMenu()"/>

      <act-side-menu :w="16" v-show="isSideMenuOpen" :class="'dashboard-side-menu'"
      @click.native.capture="toggleSideMenu()">

        <act-container :class="'side-menu__container'" style="height: max-content;">
          <act-side-menu-header :class="'side-menu-title__header'">
            <span class="material-symbols-outlined side-menu__header-icon">list</span>
            <h6>Menu</h6>
          </act-side-menu-header>
        </act-container>

        <template v-if="loggedInUser">
          <act-container :class="'side-menu__container'">
            <act-list-item v-for="option, optionIndex in Object.keys(sideMenuOptionsMap)" :key="optionIndex">

              <action-button block :class="'side-menu-options__list-btn'"
                @click.native.prevent="sideMenuOptionsMap[option]">
                <template v-slot:content>{{ option }}</template>
              </action-button>
            </act-list-item>
          </act-container>
        </template>

      </act-side-menu>
    </template>

    <router-view></router-view>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import routeHandlerMixin from './mixins/routeHandler.vue'

import ActionButton from './components/ActionButton.vue'
import ActListItem from './components/ActListItem.vue'
import ActSideMenu from './components/actSideMenu/actSideMenu.vue'
import ActSideMenuHeader from './components/actSideMenu/actSideMenuHeader.vue'
import ANavbar from './components/layout-ui/a-navbar.vue'
import ActContainer from './components/layout/ActContainer.vue'

export default {
	name: 'App',
	extends: routeHandlerMixin,
	components: { ActionButton, ActListItem, ActContainer, ActSideMenuHeader, ActSideMenu, ANavbar },

	data()
	{
		return {
			isSideMenuOpen: false
		}
	},

	computed: {

		... mapState(
			{
				loggedInUser: state => state.authStore.user
			}),

		isLoginView()
		{
			let str = this.$route.path.toLowerCase()

			return !(str.indexOf('dashboard') !== -1 || str.indexOf('workspace') !== -1 || str.indexOf('account') !== -1)
		},

		isInteralView()
		{
			return !this.$route.meta.isPlayer
		},

		sideMenuOptionsMap()
		{
			return {

				...({ 'Início' : () => this.goToRoute('/dashboard') }),
				...(!this.$store.getters['accountStore/isClient'] && { 'Projetos' : () => this.goToRoute('/dashboard/projects') }),
				...(this.$store.getters['accountStore/isMaster'] && { 'Parceiros': () => this.goToRoute('/dashboard/partners') } ),
				...(this.$store.getters['accountStore/isPartner'] && { 'Operações': () => this.goToRoute('/account/operations') } ),
				...(this.$store.getters['accountStore/isMaster'] || this.$store.getters['accountStore/isPartner'] || this.$store.getters['accountStore/isProducer']
            && { 'Conta': () => this.goToRoute('/account') } ),

				// ...((this.$store.getters['accountStore/isProducer'] || this.$store.getters['accountStore/isPartner'] || this.$store.getters['accountStore/isEditor'])
				//  && { 'Listas de reprodução' : () => this.goToRoute('/dashboard/playlists') }),

				...(this.$store.getters['accountStore/isClient'] || this.$store.getters['accountStore/isPartner'] || this.$store.getters['accountStore/isProducer']
        && {'Biblioteca' : () => this.goToRoute('/dashboard/library') })
			}
		}
	},

	methods: {

		toggleSideMenu()
		{
			this.isSideMenuOpen = !this.isSideMenuOpen
		}
	}
}
</script>

<style lang="scss">
@import url("./styles/css/reset.css");

/** Google fonts */
@import './styles/scss/types.scss';
@import './styles/scss/index.scss';

#app
{
  // Subtract height from navbar
  @include fit-container(null, calc(100% - 2rem));
}

.dashboard-side-menu
{
  padding: 0.25rem;
  background-color: var(--clr-neutral-800) !important;
}

</style>