import BaseService from './BaseService'

export default class AnalyticsService extends BaseService
{
	/**
   * @param {import("axios").AxiosInstance} provider 
   */
	constructor(provider)
	{
		super(provider)

		this.resourcePrefix = '/analytics'
	}

	async getTotalViewsFromPartnerVideos(from = '', to = '')
	{
		const response = await this.provider.get(`${this.resourcePrefix}/views?from=${from}&to=${to}`,
			{
				data: {
					from: from,
					to: to
				}
			})

		return response
	}

	async getTotalRevenueFromViews()
	{
		const response = await this.provider.get(`${this.resourcePrefix}/revenue`)

		return response
	}

	async getMostViewedClientDomains(limit = 10)
	{
		/** @todo Add validation prevent `limit=0` */
		const response = await this.provider.get(`${this.resourcePrefix}/views-per-client-domain`,
			{
				data: {
					limit: limit
				}
			})

		return response
	}
}