import BaseService from './BaseService'
export default class CreditService extends BaseService
{
	/**
   * @param {import("axios").AxiosInstance} provider 
   */
	constructor(provider)
	{
		super(provider)

		this.resourcePrefix = '/credit'
	}

	async getCreditListPerClient(partnerId)
	{
		const response = await this.provider.get(`${this.resourcePrefix}/clients/${partnerId}`)

		return response
	}

	/**
   * @todo Add options to allow querying history from a given period
   */
	async getCreditHistory(partnerId)
	{
		const response = await this.provider.get(`${this.resourcePrefix}/history/${partnerId}`)

		return response
	}

	async upsertClientCredit(partner_id, client_id, creditInfoRequest)
	{
		const response = await this.provider.post(`${this.resourcePrefix}/upsert`,
			{
				partner_id: partner_id,
				client_id: client_id,
				...creditInfoRequest
			})

		return response
	}

	async updateCreditTransaction(transactionId, transactionFields)
	{
		const response = await this.provider.post(`${this.resourcePrefix}/update/${transactionId}`,
			{
				...transactionFields
			})

		return response
	}
}