<template>
  <vue-draggable-resizable class-name="modal-container" :parent="true" :drag-handle="'.modal-top-bar'" :resizable="false">
    <span class="modal-top-bar">
      <slot name="modal-options"></slot>
    </span>
    <div class="modal-content">
      <slot name="modal-body" class="modal-content"></slot>
    </div>
  </vue-draggable-resizable>
</template>

<script>
import Vue from 'vue'
import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'

Vue.component('vue-draggable-resizable', VueDraggableResizable)

export default {
	name: 'videoPreviewModal'
}
</script>

<style scoped>
.modal-container {
  z-index: 999999 !important;
  position: absolute;
  width: auto;
  height: auto;
  top: 0;
  margin: auto auto;
  border: 2px solid #2a2739;
  border-bottom: none;
}
.modal-top-bar {
  height: 60px;
  width: 100%;
  border: 1px solid #2a2739;
}
.modal-content {
  padding: 0% 0%;
  height: 75%;
  width: 100%;
  height: 100%;
  background: none;
  border: none;
}
</style>
