
import { PropType } from 'vue'
import ActChips from './ActChips.vue'

export default {
	name: 'ProjectTypeChips',
	components: { ActChips },

	props: {
		type: {
			type: [String,Number] as PropType<1|2>,
			required: false
		}
	},

	computed: {
		__type__()
		{
			let typeAsString : string

			switch (this.type) {
				case 1:
					{
						typeAsString = 'Conteúdo'
					} break
			
				case 2: {
					typeAsString = 'Interativo'
				} break
				default:
					typeAsString = 'Indefinido'
					break
			}

			return typeAsString
		}
	}
}

