<template>
    <table class="table most-viwed-partner-domains">
    <tr class="table__header">

      <th><!-- Spacer column --></th>

      <th v-for="columnName in columns" :key="columnName">
        {{ columnName }}
      </th>
    </tr>
    <slot></slot>
    <tr v-for="(record, recordIndex) in records" :key="recordIndex" :class="tableRowClasses"
      class="ranking-table-item">
      <td class="highlighted"> {{ 1 + recordIndex }}º</td>

      <td>{{ record.client_name }}</td>
      <td>{{ record.domain }}</td>
      <td class="highlighted">{{ record.views }}</td>
    </tr>
  </table>
</template>

<script>
export default {

	props:{

		columns: {

			type: Array,
			required: false,
			default: () => { return [] }
		},

		records: {

			type: Array,
			required: true
		}
	},

	computed: {

		tableRowClasses()
		{
			return {
				'table-rows' : true,
				'most-viewed-domains' : true
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.table
{
  --table-border: 0 solid var(--clr-neutral-800);
  --table-background-color: var(--clr-neutral-900);
  --table-border-radius: 0;
  --table-padding: 1rem .5rem;

  display: table;

  border-collapse: collapse;
  // border-collapse: separate;
  // border-spacing: .125rem;
  border: var(--table-border);
}

.most-viwed-partner-domains
{
  width: 100%;
}

.table__header
{
  & > *
  {
    color: var(--clr-neutral-600);
    text-align: start !important;
    padding: var(--table-padding);
    border: var(--table-border);
    border-radius: var(--table-border-radius);
    background-color: var(--table-background-color);
  }
}

.ranking-table-item
{
  td
  {
    padding: var(--table-padding);
    border: var(--table-border);
    border-radius: var(--table-border-radius);
    background-color: var(--table-background-color);
  }
}

.most-viewed-domains
{
  /** add shades highlights to table cells */

  .client-domain
  {
    font-style: oblique;
    color: var(--clr-neutral-600);
  }

  .highlighted
  {
    @for $i from 1 through 4
    {
      @if $i == 1
      {
        color: var(--clr-neutral-200);
      }
  
      color: var(--clr-gold-200);
    }
  }
}
</style>