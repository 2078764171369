<template>
  <div class="content-centered content-cover invite-views invite-confirmation-view">
    <template v-if="isValidated">
      <div class="content-centered" style="text-align: center; width: 100%; gap: 1rem;">
        <!-- @todo Exibir checkmark icon -->
        <img src="../../../public/static/circle-checkmark.svg" class="confirmed-checkmark content-cover"/>
        <div>
          <h6>Parabéns, você foi adicionado à conta de {{ accountInformation.name }} com sucesso!</h6>
          <br>
          <!-- Will redirect to login if not currently authenticated -->
          <action-button @click.native.prevent="goToRoute('/')">
            <template v-slot:content>Voltar à plataforma</template>
          </action-button>
        </div>
      </div>
    </template>
    <template v-else>
      <div style="text-align: center; width:100%;">
        O convite recebido é inválido ou expirou.
    </div>
    </template>
  </div>
</template>

<script>
import { ActionAPI } from '../../services/ActionAPI'
import ActionButton from '../../components/ActionButton.vue'
import routeHandler from '../../mixins/routeHandler.vue'
export default {
	name: 'AccountConfirmationInviteView',
	extends: routeHandler,
	components: { ActionButton },
	data()
	{
		return {
      
			message: null,
			accountInformation: {
				name: null
			},
			isValidated: true
		}
	},

	async mounted()
	{
		/** @todo Fazer request pro back e mostrar spinner/skeleton enquanto valida */
		/** @todo Se validado, verificar se usuário já é registrado.
     *        Se sim, exibe mensagem de sucesso e manda request adicionado o usuário à conta
     *        Se não, redireciona para tela de criação de conta */
		/** @todo Se não validado, exibe mensagem de expiração. */
		if('t' in this.$route.query)
		{
			const invitationToken = this.$route.query.t
			// eslint-disable-next-line no-debugger
			// debugger
			const response = await ActionAPI.UserService.validateAccountInviteToken(invitationToken)

			const { status, data } = response

			if(status === 201)
			{
				this.isValidated = true
			}

			/** @todo redirect to create account  */
			if(status === 200)
			{
				const { invitation } = data

				this.$router.push({
					path: '/invite/registration',
					query: {
						invitation_token: invitationToken,
						pid: data.partner_id,
						mail: invitation.to,
						role_id: invitation.role_id
					}})
			}

			if(status === 422)
			{
				this.isValidated = false
			}
		}
	}
}
</script>

<style lang="scss">
@import url('./shared.scss');

.invite-confirmation-view
{
  font-weight: var(--fw-300);
}
.confirmed-checkmark
{
  max-width: 4rem;
  filter: grayscale(1) brightness(2)
}
</style>