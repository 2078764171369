<template>
  <act-container id="account__domain-settings">

    <action-modal v-if="domainModal.isOpen" backgroundColor="var(--clr-neutral-900)"
    @onOverlayClick="toggleDomainModal()">

      <act-container>
        <act-row>
          <act-col :w="12">
            <action-input label="Novo domínio" type="text" placeholder="Insira um novo domínio"
            v-model="domainModal.name">
            </action-input>
          </act-col>
          
          <act-col :w="12" flex style="justify-content: flex-end; gap: .5rem">
            <br>
            <action-button @click.native.prevent="addDomain">
              <template v-slot:content>
                Adicionar
              </template>
            </action-button>
            <action-button @click.native.prevent="toggleDomainModal">
              <template v-slot:content>
                Cancelar
              </template>
            </action-button>
          </act-col>
        </act-row>
      </act-container>
    </action-modal>
    
    <act-row>
      <act-col :w="12" class="account_domain__header">
        <h6>Domínios</h6>
        <small>Domínios permitidos pelo parceiro para reprodução do conteúdo</small>

        <br>  <!-- <hr> -->
      </act-col>
    </act-row>
    <br>

    <act-row style="margin: 1em 0">
      <act-col :w="3">
        <action-button @click.native.prevent="toggleDomainModal">
          <template v-slot:content>
            Adicionar
          </template>
        </action-button>
      </act-col>
    </act-row>

    <act-row class="domain__list-row">

      <act-col :w="12" class="domain__list-wrapper">
        <act-row v-for="(domain, domainIndex) in applicationDomains" :key="domainIndex">
          <act-list-item class="domain__list-item">
            <small class="domain__list-item__text">
              <span style="color: gray">subdomínio.</span>{{ domain }}
            </small>

            <action-button class="domain__list-item__remove-btn" @click.native.prevent="removeDomain(domain)">
              <template v-slot:content>
                <span class="material-symbols-outlined">delete</span>
              </template>
            </action-button>
          </act-list-item>
        </act-row>
      </act-col>
      <!-- @Todo: Adicionar lista dos domínios do parceiro. -->
      <!-- @Todo: Adicionar botão de adicionar domínio do parceiro. -->
      <!-- @Todo: Adicionar botão de remover domínio do parceiro. -->
    </act-row>
  </act-container>
</template>

<script>
import ActContainer from '@/components/layout/ActContainer.vue'
import ActRow from '@/components/layout/ActRow.vue'
import ActCol from '../../../components/layout/ActCol.vue'
import ActListItem from '../../../components/ActListItem.vue'
import { mapGetters } from 'vuex'
import ActionButton from '../../../components/ActionButton.vue'
import ActionModal from '../../../components/modals/actionModal.vue'
import ActionInput from '../../../components/forms/ActionInput.vue'
export default {
	components: { ActContainer, ActRow, ActCol, ActListItem, ActionButton, ActionModal, ActionInput },
	name: 'account-app-settings',

	data(){

		return {

			domainModal: {

				isOpen: false,
				name: ''
			}
		}
	},

	computed: {

		...mapGetters('accountStore',['applicationDomains']),

		computedPartnerDomains()
		{
			return this.currentPartner?.domains
		}
	},

	async created()
	{
		await this.$store.dispatch('accountStore/getAccountSettings')
	},

	methods: {

		toggleDomainModal()
		{
			this.domainModal.isOpen = !this.domainModal.isOpen
		},

		async addDomain()
		{
			
			const action = await this.$store.dispatch('partnerStore/updatePartnerDomains',
				{
					data: {
						... this.currentPartner,
						domains : [ ...this.currentPartner.domains, this.domainModal.name]
					}
				})

			this.toggleDomainModal()
      
			if('status' in action && action.status === 'error')
			{
				return console.error('An unexpected error ocurred while adding new domain')
			}
		},

		async removeDomain(domainToRemove)
		{
			if(!this.computedPartnerDomains)
			{
				return
			}
      
			const action = await this.$store.dispatch('partnerStore/updatePartnerDomains',
				{
					data: {
						... this.currentPartner,
						domains : [ ...this.currentPartner.domains.filter(domain => ![domainToRemove].includes(domain)) ]
					}
				})

			if('status' in action && action.status === 'error')
			{
				return console.error('An error occured while removing domain')
			}
		}
	}
}
</script>

<style lang="scss">
#account__domain-settings
{
  .add-domain__modal
  {
    .act-modal
    {
      background: red !important;
    }
  }
}
.account_domain__header
{
  color: clr(act_purple);

  small
  {
    font-style: oblique;
    font-weight: 400;
    color: var(--clr-neutral-600) !important;
  }
}
.domain__list-row
{
  background: var(--clr-neutral-900);
  padding: .25em;
  border-radius: .5em;
}

.domain__list-wrapper
{
  padding: .5em 1em;
  background: var(--clr-neutral-900);
  border-radius: .5em;
  height: 42em !important;
  overflow-y: scroll;

  &::-webkit-scrollbar
  {
    width: .5em;
  }
}
.domain__list-item
{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--clr-neutral-800);
  color: var(--clr-neutral-200);
}
.domain__list-item__remove-btn
{
  background: var(--clr-neutral-800) !important;
  color: var(--clr-neutral-200);
}
.domain__list-item__text
{
  font-style: oblique;
  font-weight: 400;
}

</style>