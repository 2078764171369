export default class ObjectUtils {


	constructor()
	{
		//
	}

	/**
   * Polyfill for Node 17 `structuredClone`.
   * 
   * @see https://developer.mozilla.org/en-US/docs/Web/API/structuredClone
   * @see https://stackoverflow.com/questions/73607410/referenceerror-structuredclone-is-not-defined-using-jest-with-nodejs-typesc
   * @param {Object} object 
   * @returns {Object}
   */
	static deepCloneObject(object = {})
	{
		/** @todo Add error and exception handlings for wrong input types, etc */
		const cloned = JSON.parse(JSON.stringify(object))

		return cloned
	}
}