<template>
  <act-container>
    
    <operation-header>
      Relatórios
    </operation-header>
    <!-- Botões de controle do período -->

    <div class="report-revenue__container report__container">
      <available-revenue :value="computedTotalViewRevenue"/>
    </div>

    <act-row nowrap :gap="1">

      <div class="report-grid__container">
        <div class="report-graph__header report__container">
          Visualizações

          <div class="report-graph__btn-controls">
            <action-input :label="'De'" type="date" v-model="graphRange.from"/>
            <action-input :label="'Até'" type="date" v-model="graphRange.to"/>

            <div style="margin: 50% auto;">
              <action-button class="__btn-controls-search-btn" style="padding: .125rem;"
              @click.native="handleGraphFilter">
                <template #content>
                  <span class="material-symbols-outlined"> search </span>
                </template>
              </action-button>
            </div>

          </div>
        </div>

        <view-counter ref="views-graph" :graphData="parsedVideoViewRecords"/>
      </div>

      <ranking-table
        :columns="['Cliente','Domínio','Visualizações']"
        :records="parsedTopByClientDomains"
      />

    </act-row>

  </act-container>
</template>

<script>
import { mapGetters } from 'vuex'
import ActionButton from '../../../components/ActionButton.vue'
import AvailableRevenue from '../../../components/AvailableRevenue.vue'
import ActionInput from '../../../components/forms/ActionInput.vue'
import ActContainer from '../../../components/layout/ActContainer.vue'
import ActRow from '../../../components/layout/ActRow.vue'
import RankingTable from '../../../components/tables/RankingTable.vue'
import ViewCounter from '../../../components/viewCounter.vue'
import OperationHeader from './shared/OperationHeader.vue'

export default {
	components: { ActContainer, OperationHeader, ViewCounter, ActionInput, AvailableRevenue, RankingTable, ActRow, ActionButton },
	name: 'DashboardReports',

	data()
	{
		return {

			/** @todo Adjust dynamic handle for last three months */
			graphRange: {
				from: '',
				to: ''
			}
		}
	},

	computed: {

		...mapGetters('analyticsStore', ['computedTotalViewRevenue', 'parsedVideoViewRecords', 'parsedTopByClientDomains'])
	},

	async beforeMount()
	{
		if(this.$store.getters['accountStore/isPartner'])
		{
			await this._dispatchWithLoading(
				await this.$store.dispatch('analyticsStore/fetchTotalViewsFromVideos', this.graphRange))
		}
	},

	methods:{

		async handleGraphFilter()
		{
			await this._dispatchWithLoading(
				await this.$store.dispatch('analyticsStore/fetchTotalViewsFromVideos', this.graphRange))

			this.$refs['views-graph'].updateGraph(this.parsedVideoViewRecords)
		},

		async _dispatchWithLoading(callback = async () => {})
		{
			this.$refs['views-graph'].toggleLoading()

			await callback()
				
			this.$refs['views-graph'].toggleLoading()
		}
	}
}
</script>

<style lang="scss" scoped>
@import "https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200";
@import '../../../styles/scss/mixins.scss';

.report__container
{
  background: var(--clr-neutral-900);
  border-radius: .5rem;
  padding: 1rem;
}

.report-revenue__container
{
  margin-bottom: 2rem;
  width: 100%;
}

.report-grid__container
{
  position: relative;
  display: grid;
  width: 100%;
  gap: .25rem;
}

.report-graph__header
{
  display: grid;
  gap: 1rem;
  place-items: center;
}

@media screen and (min-width: 640px)
{
  .report-graph__header
  {
    place-items: inherit;
  }
}

.report-graph__btn-controls
{
  display: grid;
  grid-auto-flow: column;
  gap: .5rem;

  div.a-input-component
  {
    width: 100%;
  }

  .__btn-controls-search-btn
  {
    background: var(--clr-neutral-800);
    color: var(--clr-neutral-200)
  }
}

.material-symbols-outlined
{
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}
</style>