<template>
  <!-- Proposed API:

    - Component receives an object to map respective form fields to which it is responsible
      for returning and cleaning up after

      <act-form ref="name-form" :form="{...formFields}"/>

      - The instance can then be called:

      const form = this.$refs['name-form']

      form.clear() or

      form.submit()
   -->
  <form class="act-form">
    <slot></slot>
  </form>
</template>

<script>
export default {
	name: 'ActForm'
}
</script>

<style lang="scss" scoped>
.act-form
{
  //
}
</style>