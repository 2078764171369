<template>
  <table :class="classes">
    <slot></slot>
  </table>
</template>

<script>
export default {
	name: 'act-table',

	computed: {

		classes()
		{
			return {
				'act-table' : true
			}
		}
	}

}
</script>

<style lang="scss" scoped>
.act-table
{
  width: 100%;
  display: flex;
  flex-direction: column;

  tr
  {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &.act-table__date-row
    {
      & > td 
      {
        border-spacing: 1em !important;
      }
    }

    th, td
    {
      text-align: end;
      position: relative;

      &:nth-child(1)
      {
        text-align: start !important;
      }
    }
  }
}
</style>