<template>
  <nav class="a-navbar">
    <action-modal v-if="isProfileModalOpen"
    @onOverlayClick="toggleChangeProfileModal()">
      <act-container>

        <span>Selecione o perfil desejado:</span>
        <br>
        <div v-for="profile, pi in accountProfiles" :key="pi"
        style="width: 100%; display: flex; flex-direction: column; gap: .25rem;"
        >
          <button style="text-align:center;">
            <span>{{ profile.account }}</span>
            <span>{{ profile.role }}</span>
          </button>
        </div>
      </act-container>
    </action-modal>
    <div class="a-nav-item-container">
      <div class="a-nav-item">
        <button class="a-btn a-menu-btn"
        @click.prevent="emitMenuButton()">
          <span id="a-menu-icon" class="material-symbols-outlined side-menu-icon">apps</span>
        </button>
      </div>
      <div class="a-nav-item">
        <button class="a-btn a-home-btn" @click.prevent="navigateToDashboard">
          <svg class="a-icon a-home-icon" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 172 172">
            <g stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10"
            stroke-dasharray=""
            stroke-dashoffset="0"
            font-family="none"
            font-weight="none"
            font-size="none"
            text-anchor="none"
            style="mix-blend-mode: normal">
              <path d="M0,172v-172h172v172z" fill="none"></path>
              <path d="M85.80964,17.2c-1.02584,0.03464 -2.02358,0.34402 -2.88906,0.89583l-68.8,43.78386c-1.72887,1.09991 -2.73946,3.03874 -2.65099,5.08592c0.08848,2.04718 1.26258,3.89159 3.0799,4.83824c1.81732,0.94665 4.00166,0.85166 5.72994,-0.24916l65.72057,-41.82422l65.72058,41.82422c1.72829,1.10095 3.91271,1.19601 5.7301,0.24936c1.8174,-0.94665 2.99154,-2.79113 3.07999,-4.83838c0.08844,-2.04725 -0.92226,-3.98612 -2.65124,-5.08598l-68.8,-43.78386c-0.97472,-0.62137 -2.11448,-0.93364 -3.26979,-0.89583zM86,40.13333l-63.06667,40.13333v57.33333c0,6.33533 5.13133,11.46667 11.46667,11.46667h22.93333v-22.93333c0,-15.82973 12.83693,-28.66667 28.66667,-28.66667c15.82973,0 28.66667,12.83693 28.66667,28.66667v22.93333h22.93333c6.33533,0 11.46667,-5.13133 11.46667,-11.46667v-57.33333z"></path>
            </g>
          </svg>
        </button>
      </div>
    </div>
    <div class="a-nav-item-container">
      <div class="a-nav-item">
        <button class="user-info__overlay-btn user-profile__icon"
        @click="toggleLoggedInUserInfo">
          <span class="user-profile__name">{{ $store.getters['authStore/userNameInitials'] }}</span>
        </button>

        <div class="nav__user-profile-dropdown" v-if="isLoggedInUserInfoOpen">
          <span class="user-profile-dropdown__header">Conta</span>
          <div class="user-profile__short">
            <span class="material-symbols-outlined">
              account_circle
            </span>
            <div class="user-profile__short-info">
              <span class="__short-info-username">{{ $store.getters["authStore/loggedInUser"].name }}</span>
              <span class="__short-info-email">{{ $store.getters["authStore/loggedInUser"].email }}</span>
            </div>
          </div>

          <hr style="margin: .125rem">
          <div class="user-profile-dropdown__actions">
            <button class="a-btn user-profile-dropdown__actions-btn" @click.prevent="logout">
              Logout
            </button>
          </div>
        </div>

      </div>
    </div>
  </nav>
</template>

<script>
import ActContainer from '../layout/ActContainer.vue'
import actionModal from '../modals/actionModal.vue'
// import { mapState } from 'vuex'
export default {
	components: { actionModal, ActContainer },
	name: 'actionNavbar',
	data()
	{
		return {

			accountProfiles: [
				{
					'account': 'Inbraep',
					'role': 'Editor'
				},
				{
					'account': 'Inbraep',
					'role': 'Producer'
				}
			],

			isLoggedInUserInfoOpen: false,
			isProfileModalOpen: false
		}
	},
	emits: ['togglesidemenu'],

	methods: {

		toggleChangeProfileModal()
		{
			this.isLoggedInUserInfoOpen = false
			this.isProfileModalOpen = !this.isProfileModalOpen
		},

		navigateToDashboard(){
			if(this.$route.name === 'Dashboard')
				return

			/** Clear current project workspace data then unsets it */
			this.$store.dispatch('workspaceStore/resetState')
				.then(() => {

					this.$store.dispatch('dashboardStore/clearCurrentProject')
						.then(() => {

							this.$router.push({ path: '/dashboard' })
						})
				})
		},

		emitMenuButton()
		{
			return this.$emit('togglesidemenu')
		},

		toggleLoggedInUserInfo()
		{
			this.isLoggedInUserInfoOpen = !this.isLoggedInUserInfoOpen
		},

		logout(){
			if(confirm('Deseja realmente deslogar?')){
				this.$store.dispatch('authStore/logout')
			}
		}
	}
}
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0");

.user-profile__icon
{
  border: none;
  border-radius: 50rem;
  display: inline-flex;
  padding: .33rem;
  min-width: max-content;
  place-items: center;
  color: var(--clr-neutral-200);
  background-color: var(--clr-purple-400) !important;

  &:hover
  {
    cursor: pointer;
    background-color: var(--clr-purple-200) !important;
  }
}

.nav__user-profile-dropdown
{
  position: absolute;
  min-width: max-content;
  display: flex;
  flex-direction: column;
  width: 15%;
  top: 2.25rem; right: 2.5rem;
  border-radius: .5rem;
  background: var(--clr-neutral-800);

  & > * { width: 100%; text-align: start; }
}
.user-profile-dropdown__header
{
  font-size: var(--fs-200);
  font-weight: var(--fw-600);
}
.user-profile-dropdown__header, .user-profile__short
{
  padding: .5rem .75rem;
}
.user-profile__short
{
  gap: .5rem;
  display: flex;
  align-items: center;

  .user-profile__short-info
  {
    gap: .125rem;
    display: flex;
    flex-direction: column;

    span
    {
      &.__short-info-email { font-size: var(--fs-200); }
    }
  }
}
.user-profile-dropdown__header, span.__short-info-email
{
  color: var(--clr-neutral-400);
}
.user-profile-dropdown__actions
{
  display: flex;
  text-align: start;
}
.user-profile-dropdown__actions-btn
{
  width: 100% !important;
  text-align: start;
  padding: .75rem;
  border-radius: 0 !important;
  color: var(--clr-neutral-400);
  font-weight: var(--fw-600);

  &:hover
  {
    background-color: var(--clr-neutral-700) !important;
  }
}

.user-info__overlay__content
{
  padding-block: .5em .25em;
  padding-inline: 1em 2em;
}

.a-navbar {
  position: relative;
  top: 0;
  width: 100%;
  height: 2rem;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
  z-index: 1000;
  background: darken(#624CAB, 25);

  .a-nav-item-container {
    display: inline-flex;
    height: 100%;
    .a-nav-item {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      margin: 0 0.25rem;
    }
  }
}

// Menu Icon (Google Fonts)
.material-symbols-outlined {
  
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}

.side-menu-icon{
  color: white !important;
}

.a-home-icon{
  padding: .125rem;

  path{
    &:nth-child(2)
    {
      fill: white;
    }
  }
}

.user-profile-options-list {
  width: max-content;
  height: max-content;
  display: flex;
  position: fixed;
  flex-direction: column;
  flex-wrap: nowrap;
  top: 2.5rem;
  right: 0.75rem;
  border-radius: 0.25rem;
  background: var(--light-purple);
  text-align: end;
  z-index: 1000;

  .user-profile-name {
    padding: 0.5rem 0.75rem;
    border-bottom: 0.125rem solid var(--border);
    color: white;
  }
  .user-profile-options-list__item {
    width: 100%;
    padding: 0 0 0 2rem;
    height: 100%;
    &:hover {
      background: var(--border);
    }
    .a-options-list-btn {
      height: 100%;
      width: 100%;
      padding: 0.5rem 1rem;
      display: flex;
      justify-content: flex-end;
      align-content: center;
      background: transparent;
    }
    .a-option-list-icon {
      max-height: 1.25rem;
      margin: 0 0 0 0.75rem;
    }
  }
}
.a-btn
{
  width: max-content; height: 100%;
  display: inline-block;
  align-items: center;
  white-space: nowrap;
  border-radius: 0.25rem;
  background: transparent;

  cursor: pointer;
  border: none;
  transition: ease-in-out 0.15s;

  &:hover {
    background: darken(#624CAB, 15);
  }
}

.a-icon {
  width: 100%;
  height: 100%;
  color: white;

}
</style>
