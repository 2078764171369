var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"workspace",attrs:{"id":"workspace"}},[_c('div',{staticClass:"workspace-panel"},[_c('div',{staticClass:"toolbar__top"},[_c('div',{attrs:{"data-container-pos":"start"}},[_c('router-link',{attrs:{"to":`/workspace/${_vm.$route.params.projectId}`}},[_c('ActionButton',{staticClass:"workspace-btn workspace-tab",style:(_vm.isFluxogramView ? 'background: #292D3E !important;' : ''),attrs:{"title":"Tela de edição da ordem de reprodução","disabled":_vm.$store.getters['accountStore/isMaster']},scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(" Fluxograma ")]},proxy:true}])})],1),(_vm.$store.getters['dashboardStore/currentProjectType'] === 2)?[_c('router-link',{attrs:{"to":_vm.hasInteraction ? `/workspace/${_vm.$route.params.projectId}/interaction` : ''}},[_c('ActionButton',{class:['workspace-btn workspace-tab'],style:(`${
              !_vm.isFluxogramView ? 'background: #292D3E !important' : ''};
              ${!_vm.hasInteraction || !this.$store.getters['globalIsProjectEditable'] ? 'cursor: no-drop !important;' : '' }`),attrs:{"title":_vm.hasInteraction ? 'Tela de edição da interação do vídeo' : 'Necessário criar um componente para configuração interação'},scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(" Interação ")]},proxy:true}],null,false,3662659130)})],1)]:_vm._e()],2),_c('div',{attrs:{"data-container-pos":"center"}}),_c('div',{attrs:{"data-container-pos":"end"}},[_c('button',{staticClass:"workspace-btn",attrs:{"id":"save-data-panel-btn","disabled":_vm.uiState.isLoading || !_vm.uiState.canSaveChanges || _vm.$store.getters['accountStore/isMaster']},on:{"click":function($event){$event.preventDefault();return _vm.saveData()}}},[_vm._v(" "+_vm._s(_vm.uiState.isLoading ? 'Salvando' : 'Salvar')+" ")]),_c('div',{staticClass:"embed-btn-wrapper"},[_c('button',{staticClass:"workspace-btn",attrs:{"disabled":true},on:{"click":function($event){$event.preventDefault();return _vm.displayEmbedCode()}}},[_vm._v(" Incorporar ")]),(_vm.isEmbbedBoxVisible)?_c('div',{staticClass:"embed-code-box",attrs:{"id":"embed-code-box"}},[_c('input',{attrs:{"type":"text","readonly":""},domProps:{"value":_vm.embedCode}})]):_vm._e()]),_c('button',{staticClass:"workspace-btn",attrs:{"disabled":_vm.uiState.isLoading},on:{"click":function($event){$event.preventDefault();return _vm.watchPreview()}}},[_vm._v(" Assistir ")])])])]),_c('div',{ref:"workspaceScreen",class:[
    'workspace-screen',
    {
      'fluxogram-view-cursor' : _vm.isFluxogramView === true,
      'editing__not-allowed' : !this.$store.getters['globalIsProjectEditable']
    }],attrs:{"id":"draggableWorkspaceScreen"},on:{"click":function($event){return _vm.onWorkspaceClick($event)}}},[_c('router-view',{ref:"currentWorkspaceView",staticClass:"workspace-screen__inner"}),_c('div',{staticClass:"workspace-background",attrs:{"id":"workspace-background"},on:{"contextmenu":function($event){$event.preventDefault();}}})],1),_c('div',{staticClass:"workspace-bottom-bar"},[_c('div',{staticClass:"__bottom-bar_item"},[_c('small',[_vm._v("Projeto atual: "+_vm._s(this.currentProject ? this.currentProject.name : 'Error'))])]),_c('span',{staticClass:"__bottom-bar_item"},[(this.$store.getters['accountStore/isMaster'])?[_c('span',{staticClass:"warning__blocked_editing"},[_vm._v(" ⚠ Não é possível editar projetos como usuário ")]),_c('span',{class:`highlighted_status__Master`},[_vm._v(" Master ")])]:_vm._e()],2),_c('div',{staticClass:"__bottom-bar_item"},[(_vm.uiState.canSaveChanges)?_c('small',[_vm._v("⭕ Não salvo")]):(!_vm.uiState.canSaveChanges)?_c('small',[_c('span',{staticStyle:{"color":"green"}},[_vm._v("✔")]),_vm._v("Salvo")]):_vm._e()]),_c('span',{staticClass:"__bottom-bar_item"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }