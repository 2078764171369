//@ts-check

import { AxiosInstance } from 'axios'
import BaseService from './BaseService'
import { Nullable } from '@/types/Utilities'


type StoreNodeRequest = {
	name: string,
	type: string,
	url: string,
	background: Nullable<string>
}

export default class SegmentNode extends BaseService {

	constructor(provider: AxiosInstance) {
		super(provider)

		// Todo: Add requests and responses definition
		this.resourcePrefix = '/segments'
	}

	async getNodesFromProject(projectId: number) {
		const response = await this.provider.get(`${this.resourcePrefix}/${projectId}`,
			{
				headers: { 'Cache-Control': 'no-cache' }
			})
			.catch(error => { throw error })

		return response
	}

	/**
	 * @param {number} projectId 
	 */
	async storeNewNode(projectId: number, storeNewNodeRequest: StoreNodeRequest) {
		const response = await this.provider.post(`${this.resourcePrefix}`,
			storeNewNodeRequest,
			{ params: { projectId: projectId } })

		return response
	}

	/** @todo Specify payload values */
	async updateNode(segmentId: number, payload: any) {
		const response = await this.provider.patch(`${this.resourcePrefix}/${segmentId}`, payload)

		return response
	}

	async updateNodeEdges(segmentId: number, { nodeId }: { nodeId: number }) {
		const response = await this.provider.patch(`${this.resourcePrefix}/${segmentId}/edges`,
			{
				'nodeId': nodeId
			})

		return response
	}

	async unsetNodeEdge(segmentId: number, { nodeId }: { nodeId: number }) {
		const response = await this.provider.delete(`${this.resourcePrefix}/${segmentId}/edges?nodeId=${nodeId}`)

		return response
	}

	async destroyNode(projectId: number, segmentId: number) {
		const response = await this.provider.delete(`${this.resourcePrefix}/${segmentId}`, { params: { projectId: projectId } })

		return response
	}

	async batchUpdateNodes(projectId: number | string, segments: Array<any> = []) {
		const response = await this.provider.post(`${this.resourcePrefix}/batch?projectId=${projectId}`,
			{
				'segments': [...segments]
			})
			.catch(error => { throw error })

		return response
	}

	async storeInteraction(segmentId: number, data: any) {
		const response = await this.provider.post(`${this.resourcePrefix}/${segmentId}/interactions`, data)

		return response
	}

	async updateInteraction(segmentId: number, interactionId: number, data: any) {
		const response = await this.provider.patch(`${this.resourcePrefix}/${segmentId}/interactions/${interactionId}`,
			{
				...data
			})

		return response
	}

	async destroyInteraction(segmentId: number, interactionId: number) {
		const response = await this.provider.delete(`${this.resourcePrefix}/${segmentId}/interactions/${interactionId}`)

		return response
	}

	async storeInteractionElement(segmentId: number, interactionId: number, data: any) {
		const response = await this.provider.post(`${this.resourcePrefix}/${segmentId}/interactions/${interactionId}/elements`,
			{
				...data
			})

		return response
	}

	/** @todo */
	async getElementsFromInteraction() {
		//Get segments/{segment}/interactions/{interaction}/elements
	}

	/** @todo */
	async updateInteractionElements(segmentId: number, interactionId: number, elements: Array<any> = []) {
		const response = await this.provider.post(`${this.resourcePrefix}/${segmentId}/interactions/${interactionId}/elements/batch`,
			{
				elements: elements
			})

		return response
	}

	/** @todo */
	async destroyInteractionElement(segmentId: number, interactionId: number, elementId: number) {
		const response = await this.provider.delete(`${this.resourcePrefix}/${segmentId}/interactions/${interactionId}/elements/${elementId}`)

		return response
	}

	async storeElementImg(segmentId: number, interactionId: number, elementId: number, formData: any) {
		const response = await this.provider.post(`${this.resourcePrefix}/${segmentId}/interactions/${interactionId}/elements/${elementId}/image`,
			formData)

		return response
	}

	// updateNode(){}


	// destroyNode(){}
}