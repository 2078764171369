<template>
  <div class="content-centered content-cover invite-views">
    <div class="content-cover form-with-header__wrapper">
      <h6 style="text-align: center;">Preencha o formulário abaixo para criar sua conta</h6>
      <user-registration-form
				:partnerId="$route.query.pid"
				:mail="$route.query.mail"
				:roleId.sync="$route.query.role_id"
				:authorizationToken="$route.query.invitation_token"
				
				maxWidth="24"/>
    </div>
  </div>
</template>

<script>
import UserRegistrationForm from './UserRegistrationForm.vue'
export default {
	name: 'AccountInviteRegistration',
	components: { UserRegistrationForm }
}
</script>

<style lang="scss">
@import url('./shared.scss');

.form-with-header__wrapper
{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
</style>