<template>
  <div id="dashboard-library" class="dashboard__projects">

    <!-- Todo: Deve listar os projetos disponíveis para seleção do cliente
               com base nos projetos de parceiro -->
    <h1 class="dashboard-library__heading">Biblioteca</h1>

    <br>

    <p class="dashboard-library__heading">Disponíveis para seleção</p>
    <br>
      <div class="act-container dashboard-library__card-row">

      <div class="act-card" v-for="(video, videoIndex) in clientAvailableProjects" :key="videoIndex"
      :id="`act-card__library-card_${videoIndex}`"
      @mouseenter="animateHoverEnter(video.id)"
      @mouseleave="animateHoverExit()"
      >
      <!-- Todo: fix resource return from the back end and return only one url for preview or a fallback image -->
      <!-- Todo: currently return all segments from project or nothing -->
        <div class="act-card__thumb"
        @click.capture.prevent="openWatchWindow(video, true)">
          
          <!-- Todo: Add feature to allow setting project poster and clip preview -->
          <template v-if="video.preview.url !== null">

            <iframe class="act-card__iframe"
              style="pointer-events: none;"
              
              :id="`act_card__iframe_${videoIndex}`"
              :src="`https://player.vimeo.com/video/${video.preview.url}`" cross-origin frameborder="0"/>
          </template>
          <template v-else>
            <img :src="video.preview.poster" alt="act-card__optional-thumbnail">
          </template>

        </div>
        <div class="act-card__content">
          <p>{{ video.name }}</p>
					<div class="__content-sub">
						<small>
							{{ 
								(video.partner !== undefined || video.partner !== null) ?
									video.partner.name : 'Não informado'
							}}
						</small>
						<div class="__content-sub__details">
							<project-type-chips :type="video.type_id"></project-type-chips>
							<small>{{ displayRelativeTimeString(video.display_updated_at) }}</small>
						</div>
					</div>

					<ActionButton
							:class="['act-card__share-btn', {'act-card__share-btn__visible' : isCardScaled && selectedCardId === video.id }]"
							@click.native.prevent="shareVideo(video)">
							<template #content>Compartilhar</template>
					</ActionButton>

        </div>

      </div>
    </div>

    <p class="dashboard-library__heading">Ativos</p>
    <br>
    <div class="act-container dashboard-library__card-row">

      <div class="act-card" v-for="(video, videoIndex) in clientSharedProjects" :key="videoIndex"
      :id="`act-card__library-card_${videoIndex}`"
      @mouseenter="animateHoverEnter(video.id)"
      @mouseleave="animateHoverExit()"
      >
      <!-- Todo: fix resource return from the back end and return only one url for preview or a fallback image -->
      <!-- Todo: currently return all segments from project or nothing -->
        <div class="act-card__thumb"
        @click.capture.prevent="openWatchWindow(video)">
          
          <template v-if="video.preview !== null">

            <iframe class="act-card__iframe"
              style="pointer-events: none;"
              
              :id="`act_card__iframe_${videoIndex}`"
              :src="`https://player.vimeo.com/video/${video.preview.url}`" cross-origin frameborder="0"/>
          </template>
          <template v-else>
            <img src="" alt="act-card__optional-thumbnail">
          </template>

        </div>
        <div class="act-card__content">
          <p>{{ video.name }}</p>

          <template v-if="video.partner !== null">
            <small>{{ video.partner.name }}</small>
          </template>
          <template v-else>
            <small>{{ "Não informado" }}</small>
          </template>
          <small>{{ displayRelativeTimeString(video.display_updated_at)}}</small>

          <ActionButton
            :class="['act-card__share-btn', {'act-card__share-btn__visible' : isCardScaled && selectedCardId === video.id }]"
            @click.native.prevent="unshareVideo(video)">
            <template #content>Remover</template>
          </ActionButton>
        </div>

      </div>
    </div>

    <div :class="['act-dialog-popup', { 'dialog-popup__active' : isPopupActive }]">
      <p>{{ dialogPopUpMessage }}</p>
    </div>

  </div>
</template>

<script>

import { mapState, mapGetters } from 'vuex'
import { ActionButton } from '@/components'
import ProjectTypeChips from '@/components/ProjectTypeChips.vue'
import TimeUtils from '../../classes/TimeUtils'
export default {
	name: 'DashboardLibrary',
	components: { ActionButton, ProjectTypeChips },
	data(){

		return {
      
			isCardScaled: false,
			selectedCardId: 0,

			clientProjects: [],
			isPopupActive: false,
			dialogPopUpMessage: ''
		}
	},
	computed: {

		...mapState({
			projects: state => state.dashboardStore.projects
		}),

		...mapGetters('accountStore', ['currentAccount']),

		clientAvailableProjects()
		{
			return this.clientProjects.filter(({ is_active }) => is_active === false )
		},

		/** Projects currently being shared */
		clientSharedProjects()
		{
			return this.clientProjects.filter(({ is_active }) => is_active === true )
		}
	},

	created(){
		/** @todo Add specific resource for this page visualization */
		this.fetchProjects()
	},

	methods:{

		async fetchProjects()
		{
			const action = await this.$store.dispatch('dashboardStore/fetchClientVideoLibrary',
				{
					clientId: this.currentAccount.id
				})

			if('status' in action && action.status === 'error')
				return console.warn('Something wrong occured while fetching projects. Setting array to empty.')

			this.clientProjects = action
		},

		openWatchWindow(video, isPreview = false)
		{
			const { hash: hash } = video
			
			if(this.isCardScaled && hash !== null || hash !== undefined)
			{
				return isPreview ?
					window.open(`/player/${hash}/preview`) :
					window.open(`/player/${hash}`)
			}
		},

		async shareVideo(video)
		{
			/** @todo Add copy paste of composite hash (hash + partner.hash) when clicks? */
			this.clientProjects = this.clientProjects.map(clientVideo => {

				return clientVideo.id === video.id ? { ...clientVideo, is_active: true } : clientVideo
			})

			const action = await this.$store.dispatch('dashboardStore/generateShareableCode',
				{
					videoId: video.hash
				})

			/** rollback changes */
			if('status' in action && action.status === 'error')
			{
				return console.warn('Erro ao criar link de compartilhamento')
			}

			const { hash: updatedHash } = action

			this.clientProjects = this.clientProjects.map(
				
				clientVideo => {

					return clientVideo.id === video.id ?
						{ ...clientVideo,
							is_active: true,
							hash: updatedHash
						} : clientVideo
				})

			navigator.clipboard.writeText(
				`${process.env.VUE_APP_URL}/player/${updatedHash}`
			)

			/** @todo Change to a non-blocking alert/dialog */
			return this.toggleDialogPop('Link copiado para área de transferência')
		},

		toggleDialogPop(message)
		{
			this.dialogPopUpMessage = message

			this.isPopupActive = true
			setTimeout(() => {
        
				this.isPopupActive = false
			}, 2000)
		},

		async unshareVideo(video)
		{
			/** @todo Add request to remove it from partnerProject table */
			const action = await this.$store.dispatch('dashboardStore/deactiveShareableCode',
				{
					videoId: video.hash
				})

			if(action.status === 'error')
			{
				return this.toggleDialogPop('Um erro ocorreu ao desabilitar o link de compartilhamento')
			}

			const { preview_url: updatedHash } = action

			this.clientProjects = this.clientProjects.map(
				
				clientVideo => {

					return clientVideo.id === video.id ?
						{ ...clientVideo,
							is_active: false,
							hash: updatedHash
						} : clientVideo
				})

			navigator.clipboard.writeText('')
		},

		//#region UI

		/**
     * Returns a user friendly string representation from a specified time and unit.
     * 
     * @param {{t: number, unit: string }}.
     * 
     * @returns string
     */
		displayRelativeTimeString({ t, unit })
		{
			return TimeUtils.toRelativeTimeString(t, unit)
		},

		animateHoverEnter(selectedCardId)
		{
			if(this.isCardScaled)
				return

			setTimeout(() => {

				this.isCardScaled = true
				this.selectedCardId = selectedCardId
			}, 250)
		},

		animateHoverExit()
		{
			this.isCardScaled = false
		}

		// #endregion
	}

}
</script>

<style lang="scss" scoped>

@import '../../styles/scss/mixins.scss';

.dashboard__projects
{
  overflow-y: scroll;
  height: 100%;
}
.dashboard-library__heading
{
  color: #5f237d;
}
.dashboard-library__card-row
{
  @include flex(row);

  overflow-x: scroll;

  box-shadow: inset -10px 0 1.25rem -1.25rem var(--clr-neutral-1000);

  min-height: 18rem;
  & > .act-card
  {
    max-width: 1rem;
    margin-inline: .5rem;

		.__content-sub
		{
			font-size: var(--fs-150) !important;
			font-weight: var(--fw-600);
			width: 100%;

			.__content-sub__details
			{
				display: flex; flex-direction: row;
				width: inherit;
				justify-content: space-between;
				margin-top: .5rem;
				gap: .5rem;
			}
		}
  }
}
.act-dialog-popup
{
  background: var(--clr-neutral-800);
  color: var(--clr-neutral-100);
  position: absolute;

  min-width: max-content; min-height: max-content;
  max-width: 16rem; max-height: 100%;

  padding: 1rem 1.5rem;

  border-radius: .5rem;
  border: 2px solid var(--clr-neutral-900);

  z-index: 99999;
  bottom: 2rem; right: 2rem !important;

  opacity: 0;

  -webkit-animation: slide-top backwards 0.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: slide-top backwards 0.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.dialog-popup__active
{
  opacity: 1;

  -webkit-animation: slide-top 0.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: slide-top 0.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.act-card
{
  @include fit-container(100%, 100%);
  
  min-width: 16rem !important;
  padding: .25rem;
  
  &, & > *
  {
    @include borderless;

    border-radius: .5rem;
  }

  &:hover
  {
    transform: scale(1.05);
    transition: .125s ease-in;
    transition-delay: .25s;

    /* antialiasing */
    will-change: transform;
    outline: 1px solid transparent;
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000;
  }

  .act-card__iframe
  {
    @include fit-container;
    position: relative;
    overflow: hidden;
    border-radius: inherit;
  }

  .act-card__thumb
  {
    @include fit-container(100%, 60%);
    @include flex(null, center, space-evenly);
    @include hover-pointer;

    margin: auto;
    text-align: center;
    overflow: hidden !important;
    
    background: var(--clr-neutral-200);
  }

  .act-card__content
  {
    @include flex;
    flex-direction: column;

		gap: .75rem;

    padding: .5rem;
    color: #f5f5f1;
  }

  .act-card__share-btn
  {
    width: max-content !important;
    border: none !important;
    margin: .5rem 0;
    font-size: .75rem;
    transition: .125s ease-in;
    color: var(--clr-neutral-100);
    background: var(--clr-neutral-1000);
    opacity: 0;
  }
  .act-card__share-btn__visible
  {
    opacity: 1;
  }

  img
  {
    font-size: var(--fs-200);
  }

  /**
  * ----------------------------------------
  * animation slide-top
  * ----------------------------------------
  */
  @-webkit-keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-100px);
              transform: translateY(-100px);
    }
  }
  @keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-100px);
              transform: translateY(-100px);
    }
  }
  
}
</style>