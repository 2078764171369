<template>
  <act-container class="dashboard-projects">

    <template v-if="!$store.getters['accountStore/isClient']">

      <!-- Create project modal -->
      <ActionModal id="modal-create-component" v-if="addProjectForm.isOpen"
      @onOverlayClick="toggleModal(addProjectForm)" backgroundColor="var(--clr-neutral-900)"
      >
        <h6 class="a-form-title">Novo componente</h6>
        <act-container>
          <form id="form-create-component" class="a-form" @submit.prevent>

            <act-row>
              <action-input
              type="text" name="add-project-form-name" id="add-project-form-name"
              v-model="addProjectForm.name"
              placeholder="Insira um nome"
              label="Nome do projeto"
              />
            </act-row>

            <act-row>
              <act-col :w="12">
                <label>Tipo</label>
              </act-col>

              <act-col :w="6">
                <action-input
                  label="Conteúdo"
                  type="radio"
                  name="project-type"
                  :checked="addProjectForm.type === 'content'"
                  value="content"
                  @input.native="addProjectForm.type = $event.target.value"
                />
              </act-col>
              <act-col :w="6">
                <action-input
                  label="Interativo"
                  type="radio"
                  name="project-type"
                  :checked="addProjectForm.type === 'interactive'"
                  value="interactive"
                  @input.native="addProjectForm.type = $event.target.value"
                />
              </act-col>
            </act-row>

            <act-row style="text-align: center">
              <act-col :w="6">
                <ActionButton id="add-component-form-submit-btn"
                class="modal-form-btn"
                @click.native.prevent="$store.dispatch('submitForm', {
                  formData: addProjectForm,
                  callbackFn: createProject
                })">
                  <template #content>Criar novo</template>
                </ActionButton>
              </act-col>

              <act-col :w="6">
                <ActionButton id="add-component-form-cancel-btn"
                class="modal-form-btn cancel-btn"
                @click.native.prevent="toggleModal(addProjectForm)">
                  <template #content>Cancelar</template>
                </ActionButton>
              </act-col>
            </act-row>
            
          </form>
        </act-container>
      </ActionModal>

      <!-- Edit project modal -->
      <ActionModal id="modal-create-component" v-if="editProjectForm.isOpen" backgroundColor="var(--clr-neutral-900)"
      @onOverlayClick="toggleModal(editProjectForm)"
      >
        <h3 class="a-form-title">Editar projeto</h3>

        <act-container style="align-items: center;">
          <!-- Todo: Reimplements forms using native html ease validations -->
          <form id="form-create-component" class="a-form form-create-component" @submit.prevent="">

            <act-row class="a-form-input-container">
              <action-input id="edit-project-form-name"
              type="text"
              :disabled="editProjectForm.status_id === projectStatuses['Completo']"
              label="Nome do projeto"
              v-model="editProjectForm.name"
              />
              <span v-show="formErrorBag.name"
              class="form_error-bag__caption"
              >
                {{ formErrorBag.name }}
              </span>
            </act-row>

            <act-row>
              <act-col :w="12">
                <label>Tipo</label>
              </act-col>

              <act-col :w="6">
                <action-input
                  label="Conteúdo"
                  type="radio"
                  name="project-type"
                  value="1" :checked="editProjectForm.type_id == 1"
                  @input.native="editProjectForm.type_id = $event.target.value"
                />
              </act-col>
              <act-col :w="6">
                <action-input
                  label="Interativo"
                  type="radio"
                  name="project-type"
                  value="2" :checked="editProjectForm.type_id == 2"
                  @input.native="editProjectForm.type_id = $event.target.value"
                />
              </act-col>
            </act-row>

            <act-row class="a-form-input-container">
              <select
              id="add-project-form-status"
              name="add-project-form-status"
              
              class="element-info-attr" style="width: 100%"
              :disabled="
                (editProjectForm.status_id === null || editProjectForm.status_id === undefined ) ||
                ($store.getters['accountStore/currentAccount'].role_id === 2 && editProjectForm.status_id === 3)
                "
              title="*"
              v-model="editProjectForm.status_id"
              >
                <option v-for="(status, statusIndex) in Object.keys(computedProjectStatuses)" :key="statusIndex"
                :hidden="projectStatuses[status] === 3 && $store.getters['accountStore/currentAccount'].role_id === 2"
                :value="projectStatuses[status]">
                {{ status }}
                </option>
              </select>
            </act-row>

            <act-row style="text-align: center">
              <act-col :w="6">
                <ActionButton id="add-component-form-submit-btn"
                class="modal-form-btn"
                @click.native.prevent="$store.dispatch('submitForm', {
                formData: editProjectForm,
                callbackFn: UpdateProjectThroughForm
                })">
                  <template #content>Atualizar</template>
                </ActionButton>
              </act-col>

              <act-col :w="6">
                <ActionButton id="add-component-form-cancel-btn"
                class="modal-form-btn cancel-btn"
                @click.native.prevent="toggleModal(editProjectForm)">
                >
                  <template #content>Cancelar</template>
                </ActionButton>
              </act-col>
            </act-row>
          </form>

        </act-container>
      </ActionModal>

    </template>

    <act-col :w="12" class="projects__header">
      <h2>{{ $store.getters['accountStore/isClient'] ? 'Meus vídeos' : 'Meus Projetos' }}</h2>
    </act-col>

    <div class="projects__center-container">

      <act-row class="projects__toolbar-container">

        <act-search-bar style="color: var(--clr-neutral-200)" v-model="projectStringFilter"/>

        <!-- @Todo: Reimplement smooth transitions and fixed layout for toolbar -->
        <act-row
          :class="['dashboard-projects__filter-tool']"
          style="align-items:center; justify-content: space-between">

          <act-col v-if="!$store.getters['accountStore/isClient']"
            :w="2" flex justify="center" style="padding-top: 1rem !important">

            <ActionButton class="dashboard-view-btn"
            @click.native="toggleModal(addProjectForm)"
            :disabled="$store.getters['accountStore/isMaster']">
              <template #content>Novo projeto </template>
            </ActionButton>
          </act-col>
          
          <act-col :w="6" id="project-list-toolbar __sub-toolbar">

            <act-row>
              <act-col :w="6" flex style="flex-direction: column; gap: .5rem">
                <label for="list-filter" class="list-filter__item-label">
                  Status
                </label>

                <!-- @todo convert this into a component -->
                <select id="list-filter" name="add-project-form-status"
                
                title="*"
                class="element-info-attr" style="width: 100;"
                v-model="statusIdToFilterBy"
                >
                  <option selected value="0">Todos</option>
                  <option v-for="(status, statusIndex) in Object.keys(computedProjectStatuses)" :key="statusIndex"
                  :value="projectStatuses[status]">
                  {{ status }}
                  </option>
                </select>
              </act-col>

              <act-col :w="6" flex style="flex-direction: column; gap: .5rem">
                <label for="list-filter" class="list-filter__item-label">
                  Tipo
                </label>
                <select id="list-filter" name="add-project-form-status"

                  class="element-info-attr" style="width: 100;"
                  title="*"
                  v-model="typeIdToFilterBy"
                  >
                  <option selected value="0">Todos</option>
                  <option v-for="(type, typeIndex) in Object.keys(projectTypes)" :key="typeIndex"
                  :value="projectTypes[type]">
                  {{ type }}
                  </option>
                </select>
              </act-col>
            </act-row>

          </act-col>
        </act-row>

        <act-row class="toolbar__buttons-container">

      
        </act-row>
      </act-row>

      <act-row style="overflow-y: scroll;">
        <act-col :w="12" flex class="dashboard-project__list" style="min-height: 38rem;">
          <project-list-item v-for="project in computedProjectList" :key="project.id" :project="project"
          @open-project-edit-form="openProjectEditForm($event)"
          @duplicate-project="duplicateProject($event)"
          @remove-project="removeProject($event)"
          />
        </act-col>
      </act-row>
    </div>

  </act-container>
</template>

<script>
import { mapState } from 'vuex'

import { ActionModal, ActionButton } from '../../components'
import ActContainer from '../../components/layout/ActContainer.vue'
import ActRow from '../../components/layout/ActRow.vue'
import ActCol from '../../components/layout/ActCol.vue'
import ActionInput from '../../components/forms/ActionInput.vue'
import toggleMixin from '../../mixins/toggleMixin.vue'
import ProjectListItem from '../../components/ProjectListItem.vue'
import ActSearchBar from '../../components/ActSearchBar.vue'

export default {
	name: 'DashboardProjects',
	extends: toggleMixin,

	components: { ActionModal, ActionButton, ActContainer, ActRow, ActCol, ActionInput, ProjectListItem, ActSearchBar },

	data(){
		return {

			projectStringFilter: '',

			statusIdToFilterBy: 0,
			typeIdToFilterBy: 0,

			projectDropdown: {
				id: null,
				isOpen: false,
				selectOptions: ['Abrir', 'Duplicar', 'Editar', 'Remover']
			},

			addProjectForm: {
				isOpen: false,
				name: null,
				type: 'content'
			},

			editProjectForm:{
				isOpen: false,
				id: null,
				name: null,
				type_id: null,
				status_id: 1 // InProgress
			},

			'formErrorBag': [],

			projectTypes: {
				'Conteúdo' : 1,
				'Interativo' : 2
			},

			projectStatuses: {

				'Em Progresso': 1,
				'Completo' : 2,
				'Aprovado' : 3,
				'Ativo' : 4,
				'Inativo' : 5
			}
		}
	},

	watch:{

		'editProjectForm.status_id': {

			handler: function() {
        
				this.formErrorBag['name'] = ''

				/** The code below is not used anymore, however its a nice example of an error bag system on forms */

				// if(this.editProjectForm.status_id !== this.projectStatuses['Em Progresso'])
				// {
				// 	let suffix = Object.keys(this.projectStatuses).find(k => this.projectStatuses[k] === this.editProjectForm.status_id)

				// 	this.formErrorBag['name'] = `Nome não será alterado se projeto estiver '${suffix}' `
				// }
				// else
				// {
				// 	this.formErrorBag['name'] = ''
				// }
			}
		}
	},

	computed: {
		...mapState({
			projects: state => state.dashboardStore.projects,
			currentProject: state => state.dashboardStore.currentProject,

			authStore: state => state.authStore,
			uiState: state => state.workspaceStore.uiState
		}),

		computedProjectStatuses()
		{
			return Object.keys(this.projectStatuses)
				.filter(key => {

					if(this.$store.getters['accountStore/isClient'])
					{
						if(key.includes('Ativo'))
							return key
            
						return
					}

					return key
				})
				.reduce((obj, key) => {

					return {
						... obj,
						[key] : this.projectStatuses[key]
					}
				}, {})
		},

		computedProjectList()
		{
			let computedList = this.projects

			computedList = computedList
				.filter(({ name }) => name.toLowerCase().includes(this.projectStringFilter.toLowerCase()))

			if(this.statusIdToFilterBy > 0)
			{
				computedList = computedList.filter(p => p.status.id === this.statusIdToFilterBy)
			}

			if(this.typeIdToFilterBy > 0)
			{
				computedList = computedList.filter(p => p.type_id === this.typeIdToFilterBy)
			}

			if(computedList.length > 0)
			{
				computedList.sort((a, b) => a.updated_at - b.updated_at)
			}

			return computedList
		}
	},

	mounted(){

		/** Preemptivelly clears components and its lines from store.
     * Prevents leftover data when navigation outside of the application */
		this.$store.dispatch('workspaceStore/resetState')
	},

	methods: {

		async createProject(formData)
		{
			const { partner_id, id: user_id } = this.$store.getters['authStore/loggedInUser']

			const action = await this.$store.dispatch('dashboardStore/storeNewProject',
				{
					name: formData.name,
					type: formData.type,
					user_id: user_id,
					partner_id: partner_id
				})

			this.toggleModal(this.addProjectForm)

			if(action.status === 'success')
				alert('Criado com sucesso.')

			if(action.status === 'error')
				alert('Algo errado aconteceu. Por favor, tente novamente mais tarde.')
		},

		async updateProject(formData)
		{
			let projectId = formData.id

			/** @todo Add error handling for error "Could not find or mismatched Project." */
			const actionResult = await this.$store.dispatch('dashboardStore/updateProject', { projectId: projectId, data: formData })
				.catch(error => error.message )

			alert(actionResult)
		},

		/**
		 * Unused method. Sets value to edit component modal and toggles it.
		 * @deprecated
		 */
		openProjectEditForm(projectData)
		{
		/** Set form initial values */
			this.editProjectForm = { ... this.editProjectForm, id: projectData.id, name: projectData.name, type_id: projectData.type_id, status_id: projectData.status.id }

			this.toggleModal(this.editProjectForm)
		},

		async UpdateProjectThroughForm(formData)
		{
			await this.updateProject(formData)

			this.toggleModal(this.editProjectForm)
		},

		async duplicateProject(projectData){

			const action = await this.$store.dispatch('dashboardStore/duplicateProject', projectData.id)

			if(action.status === 'success')
			{

				return alert('Duplicado com sucesso')
			}

			return alert('Erro ao duplicar projeto')
		},

		async removeProject(projectData){

			const action = await this.$store.dispatch('dashboardStore/destroyProject', projectData.id)

			if(action.status === 'success' )
			{
				return alert('Deletado com sucesso.')
			}

			return alert('Erro ao deletar projeto. Tente novamente mais tarde.')
		},

		toggleModal(modal)
		{
			function clearForm(formToClear){
				Object.keys(formToClear).forEach(key => {
					if(key !== 'isOpen') formToClear[key] = null
				})
			}

			this.modalToggler(null, modal, () => clearForm(modal))
		}


	}
}
</script>

<style lang="scss">

.act-row.toolbar__buttons-container
{
  & > .act-col
  {
    width: max-content;
  }
}

span[data-relative-time]
{
  font-size: var(--fs-200);
  font-weight: var(--fw-300);
  text-transform: capitalize;
}

.dashboard-projects
{
  padding-block: 1rem;
  display: grid !important;
  place-items: center !important;
  grid-template-columns: repeat(12, 1fr);
}

:scope
{
  --center-col: 2/12;
}

.projects__header
{
  grid-column: var(--center-col);
}

.projects__center-container
{
  display: grid;
  grid-column: var(--center-col);
  height: 100%; width: 100%;
  gap: 2rem;
  overflow: hidden;
}

#modal-create-component
{
  min-width: 30%; min-height: 25%;

  .act-modal
  {
    background: var(--clr-neutral-900);
  }

  .a-form-title
  {
    color: var(--clr-neutral-400);
  }

  .a-form
  {
    color: var(--clr-purple-200) !important;

    .form_error-bag__caption
    {
      user-select: none;
      font-style: italic;
      font-size: var(--fs-200);
      color: var(--clr-gold-300);
    }

    .modal-form-btn
    {
      background: var(--clr-purple-300) !important;
      color: var(--clr-neutral-100) !important;

      &.cancel-btn
      {
        background-color: var(--clr-danger-400) !important;
      }
    }
  }
}

.dashboard-project__list
{
  gap: 2rem;
  flex-wrap: wrap;
  flex: 1 0 4rem;
  flex-direction: row;
  padding-block: 0 !important;
  padding-inline: 0 .5rem !important;
}

.dashboard-projects__filter-tool
{
  border-radius: .5em;
  background-color: var(--clr-neutral-900);

  &.filter-tool__hidden
  {
    opacity: 0;
    animation: slide-bottom 0.5s ease-in both;
    -webkit-animation: slide-bottom 0.5s ease-in both;
  }

  .list-filter__item-label
  {
    font-size: var(--fs-200);
    color: var(--clr-neutral-200);
  }

  select
  {
    // appearance: none;
    padding: .25rem;
    border-radius: .25rem;
    color: var(--clr-neutral-200);
    border: none;
    background-color: var(--clr-neutral-700);
  }
}

*
{
  /* width */
  &::-webkit-scrollbar {
    width: .75rem;
  }

  /* Track */

  /* Handle */
  &::-webkit-scrollbar-thumb
  {
    border-radius: 1rem;
    background: var(--clr-neutral-800);
    border: .125rem solid var(--clr-neutral-900);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover
  {
    background: var(--clr-neutral-700);
  }
}

/**
 * ----------------------------------------
 * animation slide-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-bottom
{
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
  }
}
@keyframes slide-bottom
{
  0%
  {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  100%
  {
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
  }
}  

</style>