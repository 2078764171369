<template>
  <div :class="classes">
    <slot></slot>
  </div>
</template>

<script>
export default {
	name: 'act-col',

	props: {

		w: {
			type: Number,
			default: 12
		},

		flex: {
			type: Boolean,
			default: false
		},

		justify: {
			type: String,
			default: 'flex-start',
			validator: ($value) => ['flex-start', 'center', 'flex-end'].includes($value)
		},

		align: {
			type: String,
			default: 'flex-start',
			validator: ($value) => ['flex-start', 'center', 'flex-end'].includes($value)
		}
	},

	computed: {

		classes()
		{
			return {

				'act-col' : true,
				'act-col__flex': this.flex,
				[`act-col__w-${this.w}`]: true,
				[`act-col__justify-${this.justify}`]: true,
				[`act-col__justify-${this.align}`]: true
			}
		}
	}

}
</script>

<style lang="scss" scoped>
.act-col
{
  width: 100%;
  position: relative;
  padding: .5em;
  // display: inline-flex;
}
.act-col__justify-flex-start
{
  justify-content: flex-start;
}

.act-col__justify-center
{
  justify-content: center;
}
.act-col__justify-flex-end
{
  justify-content: flex-end;
}

.act-col__align-flex-start
{
  align-content: flex-start;
  align-items: flex-start;
}

.act-col__align-center
{
  align-content: center;
  align-items: center;
}

.act-col__flex
{
  display: flex;
}

.act-col__w-12
{
  width: 100%;
}

.act-col__w-9
{
  width: 75%;
}
.act-col__w-8
{
  width: 66.66%;
}
.act-col__w-7
{
  width: 58.33%;
}

.act-col__w-6
{
  width: 50%;
}

.act-col__w-5
{
  width: 41.66%;
}
.act-col__w-4
{
  width: 33.33%;
}
.act-col__w-3
{
  width: 25%;
}

.act-col__w-2
{
  width: 16.66%;
}

.act-col__w-1
{
  width: 8.33%;
}
</style>