import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { RootState } from '../index.d'
import { ActionAPI } from '../../services/ActionAPI'

interface AnalyticsStoreState {
	totalRevenue: string,
	videoViewsFromPeriod: {
		records: Array<any>
	},
	topByClientDomains: Array<any>
}

const namespaced = true
const state: AnalyticsStoreState = {
	totalRevenue: '0',
	videoViewsFromPeriod: {
		records: []
	},
	topByClientDomains: []
}
const getters: GetterTree<AnalyticsStoreState, RootState> = {

	computedTotalViewRevenue: (state) => {
		return parseFloat(state.totalRevenue).toFixed(2)
	},

	parsedVideoViewRecords: (state) => {
		const { videoViewsFromPeriod } = state

		if (!('records' in videoViewsFromPeriod) || videoViewsFromPeriod.records.length < 1) {
			return []
		}

		const chartJsMapping = videoViewsFromPeriod.records.map(({ period, views }) => {

			return { 'label': period, 'value': views }
		})

		return chartJsMapping
	},

	parsedTopByClientDomains: (state) => {
		const { topByClientDomains } = state

		return topByClientDomains
	}
}
const mutations: MutationTree<AnalyticsStoreState> = {

	setTotalRevenue(state, value) {
		state.totalRevenue = value
	},

	setVideoViewsFromPeriod(state, { data }) {
		state.videoViewsFromPeriod = data
	},

	setTopByClientDomains(state, { data }) {
		state.topByClientDomains = data
	}
}
const actions: ActionTree<AnalyticsStoreState, RootState> = {

	async fetchTotalAvailableRevenue({ commit }) {
		const response = await ActionAPI.AnalyticsService.getTotalRevenueFromViews()

		if (!response) {
			return { status: 'error' }
		}

		const { data: { total_revenue } } = response

		commit('setTotalRevenue', total_revenue)
	},

	/**
	 * @todo Add local cache handler for report actions as they are pretty static
	 */
	async fetchTotalViewsFromVideos({ commit }, { from = '', to = '' }) {
		const response = await ActionAPI.AnalyticsService.getTotalViewsFromPartnerVideos(from, to)

		if (!response) {
			return { status: 'error' }
		}

		commit('setVideoViewsFromPeriod',
			{
				data: response.data
			})
	},

	async getTopViewsByClientDomain({ commit }) {
		const topLimit = 10

		const response = await ActionAPI.AnalyticsService.getMostViewedClientDomains(topLimit)

		if (!response) {
			return { status: 'error' }
		}

		commit('setTopByClientDomains',
			{
				data: response.data.records
			})
	}
}

export default {
	state,
	getters,
	mutations,
	actions,
	namespaced
}