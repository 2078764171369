export default class UrlUtils {

	windowLocationObj: Location
	subdomain: string
	domain: string

	constructor(windowLocation: Location) {
		this.windowLocationObj = windowLocation

		this.domain = this.setDomainString()

		this.subdomain = this.setSubdomainString(this.domain)
	}

	/**
	 * Access window calling possible iframe from `ancestorOrigins`
	 * @public
	 * @todo Update regex to match for `https` */
	getDomainString() {
		return this.domain
	}

	/**
	 * @public
	 * @returns {{ origin: string, subdomain: string }}
	 */
	asParsedObject() {
		return Object.freeze({ origin: this.domain, subdomain: this.subdomain })
	}

	setDomainString() {
		/** Hotfix 384 */
		let domain

		if (navigator.userAgent.toLowerCase().match('firefox')) {
			const { referrer } = document
			const { host } = window.location

			domain = referrer.length > 0 ? referrer.replace(/^http:\/\//, '') : host
		}
		else {
			const { ancestorOrigins, host } = this.windowLocationObj

			domain = ancestorOrigins.length > 0 ? ancestorOrigins[0].replace(/^http:\/\//, '') : host
		}

		return domain
	}

	/**
	 * Current implementation only handles one subdomain prefixed with `.`  (dot)
	 * 
	 * @returns {{ origin: string, subdomain: string }}
	 */
	setSubdomainString(domain: string) {
		let subdomain = domain.split('.').slice(0, -2).join('.')

		if (domain.includes('localhost')) {
			/** Uses port as subdomain on local environment for contract testing. */
			subdomain = domain.split(':').slice(1).join() // 8080
		}

		return this.subdomain = subdomain
	}
}