<template>
  <div class="available_revenue">
  Saldo disponível para saque

  <!-- @todo Adicionar indicador de crédito atual, para que o usuário tenha ciência da diferença -->

  <div class="value__flex-box">
    <span id="visiblity-eye-toggle" :class="eyeToggleClasses"
    title="Exibir saldo"
    @click="toggleIsHidden()">{{ isHidden ? 'visibility' : 'visibility_off' }}
    </span>

    <div class="value__flex-box">
      <span :class="valueDisplayClasses">
      R$ {{ moneyParsedValue }}
      </span>
    </div>

    
  </div>
  <hr class="underline">
</div>
</template>

<script>
export default {

	data()
	{
		return {
			isHidden: true
		}
	},

	computed: {

		moneyParsedValue()
		{
			return this.value.replace('.', ',')
		},

		isValueDanger()
		{
			return parseInt(this.value) < 0
		},

		valueDisplayClasses()
		{
			return {
				'available_revenue__value' : true,
				'value-danger': this.isValueDanger,
				'value-hidden': this.isHidden
			}
		},

		eyeToggleClasses()
		{
			return {
				'material-symbols-outlined' : true,
				'value-hidden__eye-toggle' : true,
				'__eye-toggle__is_off' : this.isHidden
			}
		}

	},

	props:{

		value : {
			required: false,
			default: () => 0
		}
	},

	methods: {

		toggleIsHidden()
		{
			this.isHidden = !this.isHidden
		}
	}
}
</script>

<style lang="scss" scoped>
.available_revenue
{
  display: grid;
  place-items: center;
  grid-auto-flow: row;
  gap: 1rem;

  padding: 1rem;

  .value__flex-box
  {
    display: flex;
    gap: 1rem;
  }

  span.available_revenue__value
  {
    padding: .25rem .5rem;

    border-radius: .5rem;
    user-select: none;
    transition: .25s ease-out;

    // color: var(--clr-green-300);
    color: var(--clr-neutral-200);

    &.value-hidden
    {
      background: var(--gradient-neutral-light);
      color: transparent !important;
    } 

    &.value-danger
    {
      color: var(--clr-danger-400);
    }
  }

  & span.value-hidden__eye-toggle
  {
    user-select: none;
    cursor: pointer;
    color: var(--clr-neutral-200);

    &.__eye-toggle__is_off
    {
      color: var(--clr-neutral-100);
    }
  }

  .underline
  {
    width: 100%;
    border-color: var(--clr-neutral-600);
  }
}

@media screen and(min-width: 500px)
{
  .available_revenue
  {
    // grid-template-columns: repeat(2, max-content);
    justify-items: start;
  }
}
</style>