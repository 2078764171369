<template>
  <act-container id="dashboard-account" class="dashboard__account">

    <act-row style="margin-inline: 2em">
      <h6 style="color: #624CAB;">Conta</h6>
    </act-row>

    <br>

    <act-row class="account_index_container">

      <act-col :w="2">

        <act-list-item v-for="tab, tabIndex in Object.keys(tabsMenuOptionsMenu)" :key="tabIndex">
          <ActionButton square block
          :disabled="tabsMenuOptionsMenu[tab] === 'disabled'"
          @click.native="tabsMenuOptionsMenu[tab]">
            <template #content>{{ tab }}</template>
          </ActionButton>
        </act-list-item>
      </act-col>

      <act-col :w="8">
        <router-view></router-view>
      </act-col>
    </act-row>

  </act-container>
</template>

<script>
import { ActionButton } from '@/components'
import ActContainer from '@/components/layout/ActContainer.vue'
import ActRow from '@/components/layout/ActRow.vue'
import ActCol from '@/components/layout/ActCol.vue'
import ActListItem from '../../../components/ActListItem.vue'

export default {
	components: { ActionButton, ActContainer, ActRow, ActCol, ActListItem },

	name: 'DashboardAccount',

	computed:{

		tabsMenuOptionsMenu()
		{
			return {
				'Usuários' : this.goToAccountUserList, 
				...(process.env.DEV_FEATURES?.includes('integration-settings') && { 'Aplicações' : this.goToAccountApplications }),
				'Configurações': 'disabled'
			}
		}

	},

	methods: {

		goToRoute(routePath)
		{
			let { current } =  this.$router.history
  
			if(current.path !== routePath)
			{
				this.$router.push({ path: routePath })
			}
		},

		goToAccountApplications()
		{
			this.goToRoute('/account/applications')
		},
    
		goToAccountUserList()
		{
			this.goToRoute('/account')
		}
	}

}
</script>

<style lang="scss">
.account_index_container
{
  flex-direction: row;
  & > * 
  {
    margin-inline: 1em !important;

  }

  &:nth-child(1)
  {
    margin-inline-start: 0 !important;
  }
}
.grid{

  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 100%;
  gap: 1rem;

  & > *{
    min-height: 86vh;
    height: 100%;
  }

  & > :nth-child(1) {

    grid-column: 2/4;
    
  }

  & > :nth-child(2)
  {
    grid-column: 4/11;
  }
}
</style>