import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { ActionAPI } from '../../services/ActionAPI'
import { RootState } from '../index.d'
import { ShallowNullable } from '@/types/Utilities'

interface Application {
	id?: number | string,
	name: string,
	token: string,
	domains: Array<string>
}

export interface AccountSettings {
	applications: Array<Application>
}

export type Account = {
	id: number
	role_id: AccountRoles,
	account_type_id: AccountTypes
}

export type AccountStoreState = {
	account: ShallowNullable<Account>,
	accountUsers: Array<any>
	settings: AccountSettings
}

export enum AccountRoles { OWNER = 1, EDITOR = 2, PRODUCER = 3 }
export enum AccountTypes { BASIC = 1, PARTNER = 2, MASTER = 3 }

const namespaced = true
const state: Readonly<AccountStoreState> = {
	account: {
		id: null,
		account_type_id: null,
		role_id: null
	},
	accountUsers: [],
	settings: {
		applications: []
	}
}

const getters: GetterTree<AccountStoreState, RootState> = {

	currentAccount: (state) => {
		return state.account
	},

	currentAccountType: (state) => {
		return state.account?.account_type_id
	},


	loggedInUserRoleDisplay: (state) => {

		let roleName = ''

		switch (state.account.role_id) {
			case AccountRoles.OWNER:
				{
					roleName = 'Parceiro'
				} break
			case AccountRoles.EDITOR:
				{
					roleName = 'Editor'
				} break
			case AccountRoles.PRODUCER:
				{
					roleName = 'Produtor'
				} break
		}

		return roleName
	},

	isMaster: ({ account: { role_id, account_type_id } }) => {

		return (role_id === AccountRoles.OWNER && account_type_id === AccountTypes.MASTER)
	},

	isEditor: (state) => {

		return state.account.role_id === 2
	},

	isProducer: (state) => {

		return state.account.role_id === 3
	},

	isPartner: ({ account: { role_id, account_type_id } }) => {

		return (role_id === AccountRoles.OWNER && account_type_id === AccountTypes.PARTNER)
	},

	isClient: ({ account: { role_id, account_type_id } }) => {

		return (role_id === AccountRoles.OWNER && account_type_id === AccountTypes.BASIC)
	},

	computedAccountUsers: state => {
		return state.accountUsers
	},

	applicationDomains: ({ settings: { applications } }) => {
		return applications.filter(apps => apps.domains)
	}
}
const mutations: MutationTree<AccountStoreState> = {

	setAccount(state, account: Account) {
		localStorage.setItem('account', JSON.stringify(account))
		state.account = account
	},

	setAccountUsers(state, { users }) {
		state.accountUsers = [...users]
	},

	setAccountSettings(state, settings: AccountSettings) {
		Object.assign(state.settings, settings)
	}
}
const actions: ActionTree<AccountStoreState, RootState> = {

	async setAccount({ commit }, account: Account) {

		commit('setAccount', account)
	},

	async getAccountUsers({ state, commit }) {
		const { account } = state

		const response = await ActionAPI.UserService.getAccountUsers({ account_identifier: account?.id })

		interface IUser {
			role: string
		}

		if (response.users.length > 0) {
			const accountUsers = (response.users as Array<IUser>).filter(user => !['master', 'dev', 'client'].includes(user.role))

			return commit('setAccountUsers', { users: accountUsers })
		}

		throw Error('Erro ao carregar usuário')
	},

	async updateUserAccount({ state, commit }, { userId, data }) {
		const response = await ActionAPI.UserService.updateUser(
			{
				userId: userId,
				data: data
			})

		if (response) {
			const { data: updatedUser } = response

			let userIndex = state.accountUsers.findIndex(({ id }) => id === updatedUser.id)

			state.accountUsers[userIndex] = updatedUser

			commit('setAccountUsers',
				{
					users: [...state.accountUsers]
				})

			return { status: 'success' }
		}

		return { status: 'error' }
	},

	async removeUserAccount({ state, commit }, userId: number) {

		const response = await ActionAPI.UserService.removeUserFromAccount(
			{
				/* accountId: accountId, */
				userToRemoveId: userId
			}
		)

		if (response) {
			commit('setAccountUsers',
				{
					users: state.accountUsers.filter(users => users.id !== userId)
				})

			return { status: 'success' }
		}

		return { status: 'error' }
	},

	async processAccountInvitation(context, { token }) {
		const response = await ActionAPI.UserService.validateAccountInviteToken(token)

		if (response.status >= 200 && response.status < 300) {
			return {
				message: 'Parabéns, você foi adicionado à conta de {{ accountInformation.name }} com sucesso!'
			}
		}
	},

	async getAccountSettings({ state: { account }, commit }) {
		if (account.id === null) {
			// error
			return
		}

		const settings = await ActionAPI.UserService.getAccountSettings(account.id)

		commit('setAccountSettings', settings)
	}
}

/** Account Management module. */
export default {
	namespaced,
	state,
	getters,
	mutations,
	actions
}