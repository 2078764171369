import BaseService from './BaseService'

export default class PlaybackService extends BaseService{

	/**
   * @param {import("axios").AxiosInstance} provider 
   */
	constructor(provider)
	{
		super(provider)

		// Todo: Add project object definition
		this.resourcePrefix = ''
	}

	async countPlayerVisualization(videoId, { metadata = {}, headers = {} })
	{
		const response = await this.provider.post(`videos/${videoId}/count-view`,
			{
				... metadata
			},
			{
				headers: headers
			})
			.catch(e => console.error(e))

		return response
	}
}