const lang = process.env.VUE_APP_LANG ?? 'PT-BR'

/** @todo Add support for reading translation from [lang].json */

/**
 * Uses configured lang. Default: 'PT-BR'
 * @param {string} statusString 
 * @returns 
 */
export function translateProjectStatus(status = '') {
	if (lang === 'PT-BR') {
		return _projectStatus_ptBr(status)
	}

	/** add support for new languages here */

	console.warn(`No translated status matching input '${status}' was found for lang '${lang}'`)
	return status
}

function _projectStatus_ptBr(statusString = '') {
	let computedName = statusString

	switch (statusString) {
		case 'inProgress':
			computedName = 'Em Progresso'
			break

		case 'complete':
			computedName = 'Completo'
			break

		case 'approved':
			computedName = 'Aprovado'
			break

		case 'active':
			computedName = 'Ativo'
			break

		case 'inactive':
			computedName = 'Inativo'
			break

		default:
			computedName = 'Não definido'
			break
	}

	return computedName
}