<template>
  <div class="spinner">

    <!-- @todo Convert into generic async imports -->
    <template v-if="type === 'blocks'">
      <svg width="12%" height="12%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <rect class="spinner_9y7u" x="1" y="1" rx="1" width="10" height="10"/>
        <rect class="spinner_9y7u spinner_DF2s" x="1" y="1" rx="1" width="10" height="10"/>
        <rect class="spinner_9y7u spinner_q27e" x="1" y="1" rx="1" width="10" height="10"/>
      </svg>
    </template>

    <div class="spinner_bkg"/>
  </div>
</template>

<script>
export default {

	name: 'act-spinner',

	props: {

		type: {
			type: String,
			required: false,
			validator(value)
			{
				return ['blocks'].includes(value)
			},
			default(){ return 'blocks' }
		}
	}

}
</script>

<style lang="scss" scoped>

.spinner
{
  position: absolute;
  display: grid;
  place-items: center;
  width: 100%; height: 100%;

  svg
  {
    z-index: 9900;

    rect
    {
      fill: var(--clr-purple-200);
    }
  }

  .spinner_bkg
  {
    position: absolute;
    width: 100%; height: 100%;
    background: rgba(21,21,21, .7);
    filter: blur(.25rem);

    user-select: none;
  }

  z-index: 9800;
}

.spinner_9y7u
{
  animation:spinner_fUkk 2.4s linear infinite;animation-delay:-2.4s
}
.spinner_DF2s
{
  animation-delay:-1.6s
}
.spinner_q27e
{
  animation-delay:-.8s
}
@keyframes spinner_fUkk
 {
  8.33% { x:13px;y:1px; fill: var(--clr-purple-300); }
  25%{ x:13px;y:1px }
  33.3% { x:13px;y:13px }
  50% { x:13px;y:13px }
  58.33% { x:1px;y:13px }
  75% { x:1px;y:13px; fill: var(--clr-purple-400); }
  83.33% { x:1px;y:1px; }
}
</style>