<template>
  <div id="dashboard-partners" class="dashboard__partners">

    <!-- Modals -->
    <ActionModal id="modal-partner" v-if="partnerForm.isOpen"
    backgroundColor="var(--clr-neutral-900)"
    @onOverlayClick="toggleModal(partnerForm)">

      <h6>Novo parceiro</h6>

      <form class="a-form">

        <!-- Profile information -->
        <div class="form-input-container">
            <ActionInput type="text"
            label="Nome" placeholder="Nome do parceiro"
            v-model="partnerForm.name"
            />
        </div>

        <!-- Contact information -->
        <div class="form-input-container">

          <p>Informações de contato</p>
          <div class="email-input-container">

            <p>Email</p>
            <ActionInput
            type="email"
            labelPlaceholder="Insira um email"
            v-model="partnerForm.email"
            />
          </div>

          <div class="password-input-container">
            <ActionInput type="password" class="form-input"
            label="Senha do parceiro"
            placeholder="********"
            v-model="partnerForm.password"/>
          </div>
        </div>

        <!-- Checkboxes -->

        <div class="form-input-container" v-if="partnerForm.isEditing">

          <p>Opções</p>

          <br>

          <div class="partner-form-checkbox-container">
            <label for="partner-is_active"

            title="Indica o status do parceiro na plataforma">Está ativo?</label>
            <input id="partner-is_active" type="checkbox"
            v-model="partnerForm.is_active">
          </div>

          <div class="partner-form-checkbox-container">
            <!-- Todo: Add hover description for the checkbox input -->
            <label for="partner-include_subdomain"
            title="Permitir hospedagem de conteúdo em qualquer subdomínio dos domínios registrados.">Incluir subdomínio</label>
            <input type="checkbox" name="" id="partner-include_subdomain"
            v-model="partnerForm.include_subdomains">
          </div>

        </div>
        <br>

        <!-- Add input field to insert multiple item/domains -->
        <div class="form-input-container" v-if="partnerForm.isEditing">
          <label for="">Domínios</label>
          <br>
          <small>Lista de domínio permitidos para hospedagem pelo parceiros</small>
          <br>
          <div class="domains-container">

            <div class="domains-wrapper" style="background: #3c3c39; padding: .25rem; height: 100%; min-height: 8rem; max-height: 8rem; overflow-y: auto">

              <div class="partner-domain__item" v-for="(domain, domainIndex) in partnerForm.domains" :key="domainIndex"
              style="display:flex; padding: .5rem .5rem; background: #1F1F1E; color: white; width:100%; text-align: start;
              justify-content: space-between"
              >
                <input type="text" v-model="partnerForm.domains[domainIndex]" style="margin-end: 1rem; color: white; background: none; border: 2px solid black"/>

                <button @click.prevent="removeDomainFromForm(domainIndex)">X</button>
                </div>
            </div>
              <button style="display: block; margin: .5rem auto;"
              
              @click.prevent="addDomainToForm()">Adicionar mais</button>
          </div>
        </div>

        <!-- Bottom button -->
        <template v-if="partnerForm.isEditing">
          <ActionButton
          @click.native.prevent="updatePartner()">
            <template #content>{{ 'Salvar alterações' }}</template>
          </ActionButton> 
        </template>

        <template v-else>
          <ActionButton
          @click.native.prevent="addPartner()">
            <template #content>{{ 'Adicionar' }}</template>
          </ActionButton>
        </template>
        
      </form>


    <!-- Edit Partner Form -->
    </ActionModal>

    <!-- Listing -->

    <h1 style="color: #624CAB;">Parceiros</h1>

    <br>

    <!-- Listing -->
    <div class="act-container">

      <ActionButton
      @click.native="toggleFormModal()">
        <template #content>Novo parceiro</template>
      </ActionButton>

      <ActionButton class="dashboard-view-btn"
      style="margin-start: 1rem"
      @click.native="toggleFilterToolbar">
        <template #content>Filtrar</template>
      </ActionButton>
    </div>

    <div :class="['listing-toolbar', { '__hidden' : !isFilteringList }]">
      <div class="project-list-toolbar __sub-toolbar" style="display: inline-flex; align-items:center; ">

        <small style="color: gainsboro; margin-end: 1rem; font-size: 1rem;" for="list-filter">Filtrar: </small>
        <br>

        <div class="toolbar-col__wrapper">
          <label style="color: gainsboro; margin-end: 1rem; font-size: .75rem;" for="list-filter">Status</label>
          <select
          id="list-filter"
          name="add-project-form-status"
          
          class="element-info-attr" style="width: 100%;"
          title="*"
          v-model="statusIdToFilterBy"
          >
            <option selected value="0">Todos</option>
            <option v-for="(status, statusIndex) in Object.keys(partnerStatuses)" :key="statusIndex"
            :value="partnerStatuses[status]">
            {{ status }}
            </option>
          </select>
        </div>
      </div>
    </div>


    <ul class="partner-listing">
      <li v-for="(partner, partnerIndex) in computedPartnersList" :key="partnerIndex" class="partner-list-item list-item-card">

        <!-- Name | Email? | Other info -->
        <div class="info-container">
          <p>{{ partner.name }}</p>
        </div>

        <!-- Partner active status -->
        <div class="status-chips-container">
          <template v-if="partner.is_active">
            <span class="chips status-chips"
            title="status-name-identifier">
              <small>Ativo</small>
            </span>
          </template>

          <template v-else>
            <span class="chips status-chips"
            style="background: gray"
            title="status-name-identifier">
              <small>Inativo</small>
            </span>
          </template>
        </div>

        <div class="options-container">

          <ActionButton
          @click.native="toggleFormModal(partnerIndex, true)"
          >
            <template #content>Editar</template>
          </ActionButton>

          <ActionButton
          @click.native="removePartner(partnerIndex)">
            <template #content>
              <span class="material-symbols-outlined">delete</span>
            </template>
          </ActionButton>
        </div>

      </li>
    </ul>
  </div>

</template>

<script>
import { ActionButton, ActionInput, ActionModal } from '@/components'
import { mapState } from 'vuex'
export default {

	name: 'DashboardPartners',
	components: { ActionButton, ActionModal, ActionInput },

	computed: {
		...mapState({

			uiState: state => state.workspaceStore.uiState,
			partners: state => state.partnerStore.partners
		}),

		computedPartnersList()
		{
			
			if(this.isFilteringList && this.statusIdToFilterBy > 0)
			{
				/** Check for falsy since `is_active` can be express with integers too */
				return [...this.partners].filter(p => {

					// Todo: Find a better way to map status values while keeping an 'all' value for filtering

					let boolConversion = this.statusIdToFilterBy === 1 ? false : true

					return p.is_active === boolConversion
				})
			}

			return this.partners
		}

	},
	data(){
		return {

			isFilteringList: false,
			statusIdToFilterBy: 0,
			partnerStatuses: {
				/** Todos: 0 */
				'Inativo': 1,
				'Ativo': 2
			},

			selectedPartenerId: null,

			partnerForm:{

				id: null,
				isOpen: false,
				isEditing: false,
				is_active: false,
				name: null,
				email: null,
				password: null,
				token: null, // readonly
				domains: [],
				include_subdomains: false
			}
		}
	},

	created()
	{
		this.fetchPartners()
	},

	methods: {

		toggleFilterToolbar()
		{
			this.isFilteringList = !this.isFilteringList
		},

		/**
     * Toggles an specifcied modal component.
     * 
     * @param {object} modal
     */
		toggleModal(modal){
			modal.isOpen = !modal.isOpen

			/**
     * Clears a form input field values.
     * @param {Object} formToClear - Form to be cleared.
     */
			function clearForm(formToClear){
				Object.keys(formToClear).forEach(key => {

					if(key !== 'isOpen') formToClear[key] = null

					if(key === 'domains') formToClear[key] = []
				})
			}

			// If the toggle action closes it, also clear the form
			if(modal.isOpen === false)
				clearForm((modal))
		},

		async fetchPartners()
		{
			await this.$store.dispatch('partnerStore/fetchPartners')
		},

		async addPartner()
		{
			await this.$store.dispatch('partnerStore/storePartner',
				{
					data: this.partnerForm
				})

			this.toggleModal(this.partnerForm)

			// if(action) {} else {}
		},


		toggleFormModal(partnerIndex = null, isEditing = false)
		{
			this.partnerForm = { ...this.partnerForm, isEditing: isEditing }

			if(isEditing && partnerIndex !== null)
			{
				let selected = this.partners[partnerIndex]

				this.partnerForm = {
					... this.partnerForm,
					id: selected.id,
					is_active: selected.is_active,
					name: selected.name,
					token: selected.token,
					include_subdomains: selected.include_subdomains,
					domains: selected.domains
				}
			}

			this.toggleModal(this.partnerForm)
		},

		async updatePartner()
		{
			let indexOfPartner = this.partners.findIndex(({ id }) => id === this.partnerForm.id)

			await this.$store.dispatch('partnerStore/updatePartner',
				{
					partnerIndex: indexOfPartner,
					data: this.partnerForm
				})

			this.toggleModal(this.partnerForm)
		},

		async removePartner(partnerIndex)
		{
			if(!confirm(`Tem certeza que deseja remover o parceiro ${this.partners[partnerIndex].name} ?`))
				return

			await this.$store.dispatch('partnerStore/destroyPartner',
				{
					partnerIndex: partnerIndex
				})
		},

		addDomainToForm()
		{
			this.partnerForm.domains.push('Novo domínio')
		},

		removeDomainFromForm(domainIndex)
		{
			this.partnerForm.domains.splice(domainIndex, 1)
		}
	}

}
</script>

<style lang="scss" scoped>
@import '../../styles/scss/mixins.scss';

.act-container
{
  margin-bottom: 1rem;
  padding: 1rem .75rem;
  background: var(--clr-neutral-1000);

}

.material-symbols-outlined
{
  color: var(--clr-neutral-1000);
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}

.a-modal
{
  min-width: 35%; min-height: 25%;
  background: var(--clr-neutral-1000) !important;
  color: var(--clr-neutral-100);

  & > *
  {
    width: 100%;
  }

  .a-form-title
  {
    color: var(--clr-purple-400);
  }

  .a-form
  {
    .a-form-input-container
    {

      .modal-form-label
      {
        color: var(--clr-purple-400) !important;
        margin-block: 0 .5rem;
      }
      .project-name-input
      {
        width: 100%;
        border: none;
        border-radius: .25rem;
        margin: .5rem 0;
        padding: .5rem 1rem;
        font-weight: var(--fw-600);

        background: var(--clr-neutral-1000) !important;
        color: var(--clr-neutral-600);

        &:focus
        {
          transition: .125s ease-in;
          outline: none;
          box-shadow: inset -.125rem .125rem .5rem 0 rgba(var(--cle-neutral-900), .25);

          color: var(--clr-neutral-200);
        }
      }

    }
    .form_error-bag__caption
    {
      user-select: none;
      font-style: italic;
      font-size: var(--fs-200);
      color: var(--clr-gold-300);
    }

    .form-btn-container
    {
      @include flex(column);

      .modal-form-btn
      {
        min-width: max-content; width: 100%;
        margin: .25rem auto;
        background: var(--clr-purple-300);
        color: var(--clr-neutral-100);

        &.cancel-btn
        {
          background: var(--clr-purple-400);
        }
      }
    }
  }
}

.partner-listing
{
  width: 100%; height:80vh;
  padding: .25rem .5rem;
  border-radius: .5rem;
  background: var(--clr-neutral-900);

  .options-container{

    display: inline-flex;

    & > button {

      margin: .5rem .25rem;
    }
     
  }
}

.partner-domain__item
{
  @include flex(null, null, space-between);

  padding: .5rem !important;
  width:100%;

  background: var(--clr-neutral-600);
  text-align: start;
}

.listing-toolbar
{
  padding: 1rem;
  border-radius: .5rem;
  background: var(--clr-neutral-900);

  .dashboard-view-btn
  {
    margin-inline-end: 1rem;
  }
  
  display: inherit;
  
}
.__hidden
{
  display: none;
}

.partner-form-checkbox-container
{
  @include fit-container(100%, inherit);
  @include flex(null, null, space-between);
}

.partner-list-item
{
  @include flex(row, center, space-between);

  margin: .25rem 0;
  padding-block: 1rem;
  padding-inline: .5rem 1.25rem;

  border-radius: .25rem;

  width: 100%; min-height: 2rem;

  color: var(--clr-neutral-200);
  background: var(--clr-neutral-1000);

  .info-container
  {
    min-width: 12rem;
  }
  
  .status-chips-container
  {
    @include flex;

    width: 6rem;
    text-align: center;

    .chips
    {
      @include fit-container;
      @include non-selectable;

      margin: auto;

      padding: .0125rem 1.6rem .125rem 1.6rem;
      border-radius: 2rem;

      &.status-chips
      {
        color: var(--clr-neutral-100);
        background: var(--clr-green-600);
      }
    }
  }
}
</style>