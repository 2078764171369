<template>
  <div class="auth-views-bkg-overlay"/>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
  .auth-views-bkg-overlay
  {
    width: 100%; height: 100%;
    position: absolute;
    z-index: 0;

    background-color: var(--clr-neutral-900) !important;
    background: url('../assets/wallpapers/retro-pattern.webp');
    background-repeat: repeat;
    background-blend-mode: hard-light;
    filter: grayscale(1);

    &::after
    {
      content: "";
      width: 100%; height: 100%;
      background: rgba(0, 0, 0, .6);
      position: absolute;
      z-index: 2;
    }
  }
</style>