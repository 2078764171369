import {AxiosInstance, AxiosResponse} from 'axios'
import BaseService from './BaseService'

export default class PartnerService extends BaseService {

	constructor(provider: AxiosInstance) {
		super(provider)

		// Todo: Add project object definition
		this.resourcePrefix = '/partners'
	}

	/**
	 * @throws Request error  
	 */
	async getPartners(): Promise<AxiosResponse<{ data: any }>> {
		const response = await this.provider.get('/partners', {})
			.catch(() => { throw { status: 'error' } })

		return response
	}

	async getPartnerResource({ partnerId }: { partnerId: number }) {
		return await this.provider.get(`/partners/${partnerId}`, {})
			.catch(() => { throw { status: 'error' } })
	}

	async storePartner(data: any) {
		return await this.provider.post('/partners', data)
			.catch(() => { throw { status: 'error' } })
	}

	async updatePartner({ partnerId, data } : { partnerId: number, data: any }) {
		return await this.provider.patch(`/partners/${partnerId}`, data)
			.catch(() => { throw { status: 'error' } })
	}

	async destroyPartner(partnerId: number) {
		return await this.provider.delete(`/partners/${partnerId}`)
			.catch(() => { return { status: 'error' } })
	}
}