<template>
  <button :type="type" :class="['action-btn', {

    'btn-square' : square,
    'btn-disabled' : disabled,
    'btn-block' : block,
    'btn-outline' : outline
  }]"
  :disabled="disabled"
  >
    <slot name="icon" class="btn-icon"></slot>
    <slot name="content" class="btn-content">Button</slot>
  </button>
</template>

<script>
export default {
	name: 'ActionButton',
	props: {

		/** Override html type */
		type: {
			type: String,
			default: null,
			required: false,
			validator(value){
				return ['button'].includes(value)
			}
		},

		outline: {
			type: Boolean,
			default: () => false
		},

		square: {
			type: Boolean,
			default: function () {
				return false        
			}
		},

		disabled: {
			type: Boolean,
			default: function(){
				return false
			}
		},

		block: {
			type: Boolean,
			default: function(){
				return false
			}
		}
	}
}
</script>

<style lang="scss" scoped>
  .action-btn
  {
    padding: .5rem 1rem;
    border: none; border-radius: .5rem;
    text-decoration: none;

    background: clr(neutral, light);
    user-select: none;

    &:hover
    {
      cursor: pointer;
    }
  }

  /** Prop styles */
  .btn-disabled
  {
    pointer-events: none;
    background: var(--clr-neutral-600);
  }

  .btn-square
  {
    border-radius: 0px !important
  }

  .btn-block
  {
    border-radius: .25rem;
    width: 100%;
  }

  .btn-outline
  {
    background: transparent !important;
    border: 2px solid var(--clr-purple-600) !important;
  }
</style>