<template>
  <act-container id="view-counter">

    <!-- Ensures graph will render properly. Fix for chart.update() -->
      <template v-if="isRenderable">

        <div class="view-counter__container">

          <act-row data-canvas-container class="view-counter__box">
            <slot></slot>

            <canvas id="view-counter__canvas"/>
          </act-row>

          <act-row class="view-counter__box">
            <act-col :w="12">

              <div class="view-counter">
                Total: <span>{{ numOfTotalViews }}</span>
              </div>

            </act-col>
          </act-row>
        </div>
      </template>
      
      <template v-else>
        <act-row :class="'no-data__container'">
          <act-col :w="12" class="no-data__info">
            <span>Não há registro o suficiente para exibir no momento</span>
          </act-col>
        </act-row>
      </template>

      <transition name="spinner">
        <act-spinner v-if="isLoading" type="blocks"/>
      </transition>
  </act-container>
</template>

<script>
import ActContainer from './layout/ActContainer.vue'
import Chart from 'chart.js'
import ActRow from './layout/ActRow.vue'
import ActCol from './layout/ActCol.vue'
import ActSpinner from './ActSpinner.vue'
// import ActSpinner from './ActSpinner.vue'

/** @see https://stackoverflow.com/questions/42870869/chartjs-top-and-bottom-padding-of-a-chart-area */
export default {
	name: 'view-container',
	components: { ActContainer, ActRow, ActCol, ActSpinner },
	data()
	{
		return {
			isLoading: true,
			viewCounterChart: null,

			datasetSettings: {
				barPercentage: 0.25,
				tension: 0,
				minBarLength: 30,

				borderColor: 'rgb(153, 102, 255)',
				backgroundColor: 'rgba(153, 102, 255, 0.3)',
				hoverBackgroundColor: 'rgba(153, 102, 255, 0.5)'
			},

			chartOptions: {
				scales: {
					yAxes: [
						{
							ticks: { beginAtZero: true }
						}
					]
				}
			}
		}
	},

	computed:{

		isRenderable()
		{
			return this.graphData.length > 0
		},

		numOfTotalViews()
		{
			return this.graphData.reduce((acc, obj) => acc + parseInt(obj.value), 0)
		}
	},

	props: {

		graphData: {

			type: Array,
			required: true,
			default: () => { return [] }
		}
	},

	async mounted()
	{
		if(!this.isRenderable)
		{
			return
		}

		this.mountGraphCanvas(this.datasetSettings, this.chartOptions)

		this.toggleLoading()
	},

	methods:{

		mountGraphCanvas(datasetOptions, chartOptions)
		{
			this.viewCounterChart = new Chart(
				document.getElementById('view-counter__canvas'),
				{
					type: 'bar',
					data: {
						labels: this._mapChartToField(this.graphData, 'label'),
						datasets: [
							{
								label: 'Visualizações',
								data: this._mapChartToField(this.graphData, 'value'),

								... datasetOptions
							}
						]
					},
					options: chartOptions
				})
		},

		async updateGraph(newDataset = [])
		{
			this.viewCounterChart.data.labels = this._mapChartToField(newDataset, 'label'),
			this.viewCounterChart.data.datasets[0].data = this._mapChartToField(newDataset, 'value')

			await this.viewCounterChart.update()
		},

		toggleLoading()
		{
			this.isLoading = !this.isLoading
		},

		_mapChartToField(chart, field)
		{
			if(chart.length > 0)
			{
				return chart.map(row => row[field])
			}

			return chart
		}
	}

}
</script>

<style lang="scss" scoped>

.view-counter__container
{
  display: flex;
  flex-direction: column;
  width: 100%;
 
  .view-counter__box
  {
    padding: .5rem;
    border-radius: .5rem;
    background: var(--clr-neutral-900);
    flex-direction: column;

    &[data-canvas-container]
    {
      margin-block: auto 1rem;
    }
  }
}

.view-counter
{
  color: var(--clr-neutral-200);

  span
  {
    color: var(--clr-purple-100);
  }
}
.no-data__container
{
  min-height: 8em;

  border-radius: .5rem;
  background: var(--clr-neutral-900);

  .no-data__info
  {
    margin: auto;
    text-align: center
  }
}
</style>