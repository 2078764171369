export default class Graph {

	nodes: Array<Node>;
	adjacencyList: Map<number, Array<any>>
	currentNodeIndex: number;

	constructor() {
		this.nodes = []
		this.adjacencyList = new Map()

		this.currentNodeIndex = 0
	}

	getNodes(): Array<Node> {
		return this.nodes
	}

	getCurrentNode(): Node {
		return this.nodes[this.currentNodeIndex]
	}

	getNodeIndex(nodeId: Node['id']) {
		return this.nodes.indexOf(this.nodes.find(n => n.id === nodeId) as Node)
	}

	setCurrentNodeIndex(nodeIndex: Node['id']): void {
		this.currentNodeIndex = nodeIndex
	}

	addNode(key: number, value: any): void {
		let n = new Node(key, value)

		this.nodes.push(n)
		this.adjacencyList.set(key, [])
	}

	/**
	 * @todo This funcion can be improved by simly pass n and m to the adjacencyList without requerying them.
	 * 			 It is safe to do so since nodes can't be removed in this implementation
	 * 			 
	 */
	addEdge(n: number, m: number): void {
		let sourceNode = this.nodes.find(({ id }) => id === n) as Node
		let targetNode = this.nodes.find(({ id }) => id === m) as Node

		this.adjacencyList.get(sourceNode.id)?.push(targetNode.id)
	}

	getGraph(): Map<number, any> {
		return this.adjacencyList
	}

	/** Traverse graph  */
	/** Move current node pointer */
}

export class Node {

	id: number;
	value: any;

	constructor(id: number, value = {}) {
		this.id = id
		this.value = value
	}
}
