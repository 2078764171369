import router from '../../router'
import { ActionAPI } from '../../services/ActionAPI'
import { GetterTree, MutationTree, ActionTree } from 'vuex'
import type { AuthSession, AuthStoreState, AuthUser, RootState } from '../index.d'

import { LoginRequest } from '@/services/ActionAPI/index.d'
import { Nullable } from '@/types/Utilities'
import { Account } from './accountStore'

const namespaced = true
const state: Readonly<AuthStoreState> = {
	user: null,
	sessionToken: null,
	session: {
		token: null,
		expires_at: null
	}
}
const getters: GetterTree<AuthStoreState, RootState> = {

	userNameInitials: (state) => {

		if (state?.user === null) {
			return 'AC' //action
		}

		let splitted = state.user.name.split(' ')

		let splitLen = splitted.length
		let initials = ''

		if (splitLen > 1) {
			for (let i = 0; i < 2; i++) {
				initials += splitted[i].substring(0, 1)
			}

			return initials
		}

		return state.user.name.substring(0, 2)
	},

	loggedInUser: (state) => {

		return state.user
	}

}

const mutations: MutationTree<AuthStoreState> = {

	storeUser(state, { user }: { user: AuthUser }) {
		localStorage.setItem('user', JSON.stringify(user))
		state.user = user
	},

	storeSessionToken(state, { token }: { token: string }) {
		localStorage.setItem('sessionToken', token)
		state.sessionToken = token
	},

	storeSession(state, { session }: { session: AuthSession }) {
		localStorage.setItem('session',
			JSON.stringify({ token: session.token, expires_at: session.expires_at })
		)

		state.session = { ...state.session, ...session }
	},

	clearAuthCredentials(state) {
		localStorage.clear()
		// localStorage.removeItem('sessionToken')
		// localStorage.removeItem('user')
		// localStorage.removeItem('session')

		state.sessionToken = null
		state.user = null
		state.session = null
	}
}

/**
 * @method Login
 */
const actions: ActionTree<AuthStoreState, RootState> = {

	async Login({ dispatch },
		{ email, password }: LoginRequest) {
		const response = await ActionAPI.Auth.login({ email: email, password: password })
			.catch(error => console.log(error))

		if (!response) {
			return { status: 'Error', 'message': 'An error occurred.' }
		}

		const { data } = response

		if (data.session !== undefined && data.user !== undefined) {
			/** @todo */
			ActionAPI.Auth.setAuthorizationHeader(data.sessionToken)

			/** Stores `session` data to validate further requests. */
			await dispatch('storeAuthCredentials', {
				user: data.user,
				token: data.session.token,
				session: data.session,
				account: data.account
			})

			return { status: 'Success', 'message': 'Logged user succesfully' }
		}

		// Todo: logger in case its an request error
	},

	async logout({ commit, dispatch }) {
		/** Catch empty return to allow unlogging if session return forbbiden or invalid */
		await ActionAPI.Auth.logout().catch(() => { return })

		// Todo: create generic clean up method that can be used by all modules.
		await commit('clearAuthCredentials')

		/** Clear `projectStore` */
		await dispatch('dashboardStore/resetState', {}, { root: true })

		router.push({ name: 'Login' })
	},

	async retrivePassword(context, email: Nullable<string> = null) {
		/** @todo Add email validation */
		const isValid = email

		if (!isValid) {
			throw { 'type': 'Validation error', 'message': 'Invalid value for email field provided' }
		}

		const response = await ActionAPI.Auth.sendResetPasswordNotification(email)

		if (response.status === 422) {
			/** @todo Add default portuguese text or translation layer */
			if ('email' in response.errors) {
				return alert('Email inválido ou não encontrado.')
			}
		}

		alert(`O link para recuperação da sua senha foi enviado para ${email}\n`)
	},

	async updateResetPassword(
		context,
		{ token, newPassword, confirmationPassword }: { token: string, newPassword: string, confirmationPassword: string }) {
		const response = await ActionAPI.Auth.resetPassword(
			{
				token: token,
				new_password: newPassword,
				confirmation_password: confirmationPassword
			})

		if (response) {
			console.log(response)
			return { status: 'success' }
		}

		return { status: 'error' }
	},

	/**
 * Returns wheather a user is logged using `vuex.state` and `localStorage`.
 * 
 * @todo Add validations for session token expiration and userId
 * @returns {Boolean}
 */
	isUserLogged({ state /*, dispatch */ }) {
		function storeOrPersistedStorageHaveSession() {
			/** Verify if session object is present in state */
			if ('session' in state && state.session !== null) {
				if ((state.session.token !== null && state.session.expires_at !== null))
					return state.session

				/** Verify if session object is present in browser storage */
				const storedSession = localStorage.getItem('session')
				if (storedSession)
					return JSON.parse(storedSession)

				const storedVuexState = localStorage.getItem('vuex')
				if (storedVuexState)
					return JSON.parse(storedVuexState)
			}

			return false
		}

		let session = storeOrPersistedStorageHaveSession()
		if (!session) return false

		/** Expiration data is within range */
		return (new Date().getTime() < new Date(session.expires_at).getTime())
	},

	//#region Mutation wrappers

	storeUser({ commit }, { user }: { user: AuthUser }) {
		commit('storeUser', { user: user })
	},

	storeSessionToken({ commit }, { token }: { token: string }) {
		commit('storeSessionToken', { token: token })
	},

	storeSession({ commit }, { session }: { session: AuthSession }) {
		// commit<StoreSessionCommit>({ type: 'storeSession', payload })
		commit('storeSession', session)
	},

	/**
	 * @param { {commit: (type: keyof mutations, payload: any) } } context 
	 * @param {any} payload
	 */
	async storeAuthCredentials(
		{ commit, dispatch },
		{ user, token, account, session }: Pick<AuthStoreState, 'user' | 'session'> &
		{ account: Account, token: string }) {
		commit('storeUser', { user: user })
		commit('storeSessionToken', { token: token })
		commit('storeSession', { session })

		dispatch('accountStore/setAccount', account, { root: true })
	}
	//#endregion
}

/** Authentication module. */
export default {
	namespaced,
	state,
	getters,
	mutations,
	actions
}
