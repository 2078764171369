<template>
  <div class="act-list-item">
    <slot></slot>
  </div>
</template>

<script>
export default {
	name: 'act-list-item'

}
</script>

<style lang="scss" scoped>
.act-list-item
{
  list-style: none;
  width: 100%;
  margin-block: .25em;
  padding: .5em 1em;
  border-radius: .5em;
}
</style>