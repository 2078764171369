<template>
  <form @submit.prevent="submitForm(form)"
  class="flex-vertical user-registration-form"
  :style="styles">

    <div class="inline-form-items">
      <action-input label="Nome" type="text"
      required
      placeholder="Insira um nome de usuário" v-model="form.username"/>

      <fieldset class="flex-vertical" style="gap: 1rem; margin-top: .25rem">
        <label for="account-user-role">Função</label>
        <select id="account-user-role" class="readonly-select" :value="roleId" disabled>
          <option v-for="(role, roleIndex) in computedAccountRoles" :key="roleIndex" :value="role.value">
          {{ role.name }}
          </option>
        </select>
      </fieldset>
    </div>

    <fieldset style="border: 1px solid gainsboro; padding: .25rem 1rem 1rem; border-radius: .25rem;">
      <legend style="font-weight: var(--fw-300)">Credenciais</legend>

      <action-input label="Email" type="email"
      required
			disabled
			class="reg-form_mail"
      placeholder="usuário@email.com" v-model="form.email"/>

      <action-input label="Senha" type="password"
      required
      placeholder="*******" v-model="form.password"/>

      <!-- @todo Add validator to confirmation_password field -->
      <action-input label="Confirmação de senha" type="password"
      required
      placeholder="*******" v-model="form.confirmationPassword"/>

    </fieldset>

    <section>
      <action-button style="width: 100%" :disabled="isCreatingUser"
       @click.native.prevent="submitForm({ ...form, role_id: roleId, partner_id: partnerId })">
        <template v-slot:content>Concluir cadastro</template>
      </action-button>
    </section>
  </form>
</template>

<script>
import { ActionAPI } from '../../services/ActionAPI'

import ActionButton from '../../components/ActionButton.vue'
import ActionInput from '../../components/forms/ActionInput.vue'
import AccountRolesMixin from '../dashboard/account/userSettings/AccountRolesMixin.vue'

export default {
	name: 'UserRegistrationForm',
	extends: AccountRolesMixin,
	components: { ActionInput, ActionButton },
	props: {

		partnerId: {
			type: String,
			required: true
		},

		mail: {
			type: String,
			required: true
		},

		roleId: {
			type: String,
			required: true
		},
		maxWidth: {
			type: String,
			required: false,
			default: '24'
		},
		authorizationToken: {
			type: String,
			required: true
		}
	},
	data()
	{
		return {

			isCreatingUser: false,

			form: {
				username: '',
				email: '',
				password: null,
				confirmationPassword: null
			}
		}
	},
	computed:
	{
		styles()
		{
			return {
        
				'gap': '1rem',
				'max-width': `${this.maxWidth}rem` 
			}
		}
	},

	mounted()
	{
		this.form.email = this.mail
	},

	methods:{

		/** @todo Add loading spinner */
		async submitForm({ username, email, password, confirmationPassword, role_id = null, partner_id = null })
		{
			this.isCreatingUser = true

			if((role_id === null || password === null || confirmationPassword === null) || (password !== confirmationPassword))
			{
				// throw exception of validation error on form
			}
      
			const storeUserRequest = { name: username, email: email, password: password, confirmation_password: confirmationPassword, role_id: role_id, partner_id: partner_id }

			if(!this.authorizationToken)
			{
				throw new Error('Missing authorization header key')
			}
			const newUser = await ActionAPI.UserService.registerUser(storeUserRequest, this.authorizationToken)

			this.isCreatingUser = false
  
			if(newUser)
			{
				/** By redirecting back to the invitation page the user will be linked to the invite account */
				return this.$router.push({ path: '/invite/confirmation', query: { t: this.authorizationToken } })
			}

			alert('Sorry, an unexpected error happened.')
		}
	}
}
</script>

<style lang="scss">
.user-registration-form
{
  width: 100%;
}
.inline-form-items
{
  display: inline-flex;
  align-items: center;
  gap: 1rem;
}
.readonly-select
{
  appearance: none;
  user-select: none;
  pointer-events: none  ;

  padding: .25rem .5rem;
  border: none; border-radius: .25rem;
  color: var(--clr-neutral-200);
  background-color: var(--clr-neutral-700);
}

.reg-form_mail
{
	input.a-input
	{
		&:disabled
		{
			user-select: none;
			color: var(--clr-neutral-200) !important;
			background-color: var(--clr-neutral-700) !important;
		}
	}
}
</style>