<template>
  <act-container class="act-playlist-item">
    <!-- Nested view modal: Pick videos -->
    <action-modal id="modal-available-videos" v-if="isInVideoSelectionView"
    @onOverlayClick="toggleVideoSelectionView()">

      <act-container class="act-playlist__available-videos">

        <act-row >
          <p>Videos disponíveis</p>
        </act-row>
        
        <act-search-bar style="margin-block: 1em; color: #7358c9" v-model="availableVideosStringFilter"/>
      
        <act-row>
          <!-- Todo: filtrar videos que já estão na playlist -->
          <template v-if="computedAvailableForSelection.length < 1">
            <act-col :w="12">
              <div style="width:100%; padding: 8rem; user-select: none">
                <small>Não há vídeos disponíveis para seleção</small>
              </div>
            </act-col>
          </template>

          <template v-else>
            <act-col :w="12" class="available_videos__list">
              <compact-video-card v-for="(video, videoIndex) in computedAvailableForSelection" :key="videoIndex" :video="video">

                <!-- <img src="~@/../public/static/img_not_found.webp" alt="video_cover_preview"> -->
                <action-button title="Adicionar à playlist"
                @click.native.prevent="addVideoToPlaylist(video, currentPlaylist)"
                >
                  <template v-slot:content>
                    <span class="material-symbols-outlined">add</span>
                  </template>
                </action-button>
              </compact-video-card>
            </act-col>
          </template>

        </act-row>
      </act-container>
    </action-modal>

    <act-search-bar style="margin-block: 1em; color: white" v-model="videoStringFilter"/>

    <act-row style="margin: 1rem 0; height: max-content;">

      <!-- @todo Should open view to search all available projects -->
      <action-button @click.native.prevent="displayVideoSelectionModal()">
        <template v-slot:content>Adicionar novo</template>
      </action-button>
    </act-row>

    <act-row class="act-card__row">
      <act-col :w="12" style="margin-bottom: 2em">
        <action-button @click.native.prevent="goBackToPlaylistList()">
          <template v-slot:content>Voltar</template>
        </action-button>
      </act-col>

      <template v-if="'videos' in currentPlaylist && currentPlaylist.videos.length > 0">
      
        <!-- @todo Refactor UI component to match the identity of other video streaming services -->
        <act-col :w="6" v-for="(video, videoIndex) in computedSelectedGroupVideos" :key="videoIndex">

          <compact-video-card :video="video">
            <action-button title="Remover da playlist"
            class="__details-remove-btn"
            @click.native.prevent="removeVideoFromPlaylist($event, currentPlaylist)"
            >
              <template v-slot:content>
                <span class="material-symbols-outlined">delete</span>
              </template>
            </action-button>
          </compact-video-card>
        </act-col>

      </template>

      <template v-else>
        <act-container style="min-height: 16em; text-align: center; justify-content: center;">
          <act-row>
            <act-col :w=12>
              <h6 class="act-playlist__info-no-video">Esta playlist ainda não possui nenhum vídeo</h6>
            </act-col>
          </act-row>
        </act-container>
      </template>
    </act-row>    
  </act-container>

</template>

<script>
import ActContainer from '@/components/layout/ActContainer.vue'
import ActRow from '@/components/layout/ActRow.vue'
import ActCol from '@/components/layout/ActCol.vue'
import ActionModal from '@/components/modals/actionModal.vue'
import ActSearchBar from '@/components/ActSearchBar.vue'
import { ActionButton } from '@/components'

import { mapGetters } from 'vuex'
import CompactVideoCard from '../../../components/CompactVideoCard.vue'
export default {
	name: 'dashboard-playlist-view',
	components: { ActSearchBar, ActionButton, ActContainer, ActRow, ActCol, ActionModal, CompactVideoCard },
	props :{

		hash: {
			type: String,
			required: true
		}
	},

	data()
	{
		return {

			videoStringFilter: '',
			availableVideosStringFilter: '',
			isInVideoSelectionView: false
		}
	},

	computed:{

		...mapGetters('dashboardStore',['playlists', 'videoProjects']),

		computedSelectedGroupVideos()
		{
			return this.currentPlaylist.videos.filter(({ name }) => name.toLowerCase().includes(this.videoStringFilter.toLowerCase()))
		},

		currentPlaylist()
		{
			return this.playlists.find(playlist => playlist.hash === this.hash)
		},

		computedAvailableForSelection()
		{
			return this.videoProjects.filter(video =>
			{
				if(!this.computedSelectedGroupVideos.find(({ id }) => id === video.id))
				{
					return video.name.toLowerCase().includes(this.availableVideosStringFilter.toLowerCase())
				}
			}
			)
		}
	},

	methods: {

		goBackToPlaylistList()
		{
			this.$router.push('/dashboard/playlists')
		},

		displayVideoSelectionModal()
		{
			// Mostrar modal exibindo os vídeos que correspodem ao critério de seleção i.e 'Completos'
			this.toggleVideoSelectionView()
		},

		toggleVideoSelectionView()
		{
			this.isInVideoSelectionView = !this.isInVideoSelectionView
		},

		async addVideoToPlaylist(video, playlist)
		{
			const action = this.$store.dispatch('dashboardStore/addVideoToPlaylist',
				{
					video: video,
					playlist: playlist
				})

			this.toggleVideoSelectionView()

			if('status' in action && action.status.includes('error'))
			{
				return console.error('An unexpected error occurred while adding video to playlist')
			}
		},

		async removeVideoFromPlaylist(video, currentPlaylist)
		{
			const action = this.$store.dispatch('dashboardStore/removeVideoFromPlaylist',
				{
					video: video,
					playlist: currentPlaylist
				})
      
			if('status' in action && action.status.includes('error'))
			{
				return console.error('An unexpected error occurred while removing video from playlist')
			}
		}
	}

}
</script>

<style lang="scss">
#modal-available-videos
{
  .act-modal
  {
    background-color: #171717 !important;
    color: white;
  }
}
</style>
<style lang="scss" scoped>

.act-playlist__available-videos
{
  height: 32em !important;
  min-width: 32em !important;
  overflow: scroll !important;
  padding-inline-end: 1em;

  &::-webkit-scrollbar
  {
    width: .5em !important;
  }
}

.act-playlist__info-no-video
{
  color: clr(act_purple);
}

.available_videos__list
{
  & > *
  {
    margin-block-end: 1rem;

    &:nth-last-child()
    {
      margin-block-end: 0;
    }
  }
}
</style>
