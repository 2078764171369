<script>

/** On iOS devices playback does not work beyound a certain version.
 * Current tests show that at least on iOS13 playback is supported with a few drawbacks. */

const events = ['play', 'loadeddata', 'timeupdate', 'ended']
const UNSUPPORTED_PLATFORMS = ['iPhone', 'iPad']

import videojs from 'video.js'

export default {
	methods: {

		_setPlayerAttributes(player, attributesMap)
		{
			attributesMap.forEach((value, key) => player.el().setAttribute(key, value))
		},

		_setBufferColorTheme(player, color)
		{
			/** Set color theme for buffer */
			Object.assign(player.el().firstChild.style, { 'background-color': color })
		},

		/** Displays fullscreen button if plataform is supported. */
		_handleFullscreenSupport(player)
		{
			player.getChild('ControlBar').removeChild('FullscreenToggle')

			if(!UNSUPPORTED_PLATFORMS.find(plataform => navigator.userAgent.includes(plataform)))
			{
				player.getChild('ControlBar').addChild(this.overriddenFullscreenButton())
			}

			/** Handles fullscreen through device orientation */
			else if(UNSUPPORTED_PLATFORMS.find(plataform => navigator.userAgent.includes(plataform)))
			{
				videojs.on(player.el_, 'loadeddata', () => videojs.trigger(player.el_, 'playerresize'))
			}
		},

		_addProxyEventEmitters(player, componentId)
		{
			for(let i = 0; i < events.length; i++)
			{
				let evName = events[i]

				/** Proxies player events */
				videojs.on(player.el_, evName, (event_) =>
				{
					let payload = {}
					Object.assign(payload, { instanceId: componentId })

					this.$emit(evName, { ...event_, ... payload })
				})
			}
		},

		/** Prevents "replay" button from looping the instance video */
		_setReplayButtonToLoopEntirePlaybackSequence(player)
		{
			player.getChild('ControlBar').removeChild('PlayToggle')

			let VolumeControl = player.getChild('ControlBar').getChild('volumePanel').el_
			let replacePlayerToggle = this.overrideMacroPlaybackReplay().el_

			player.getChild('ControlBar').el_
				.insertBefore(replacePlayerToggle, VolumeControl)
		},

		_registerPlugins(player, plugins = [])
		{
			plugins.forEach(({ name: pluginName, options }) => player[pluginName]({ options }))
		},

		/** Add fake fullscreen button */
		overriddenFullscreenButton(){

			const BASE_CLASS = videojs.getComponent('Button')

			const customButton = new BASE_CLASS(this.instance, {

				className: 'vjs-fullscreen-control',

				clickHandler: () => {

					this.handleFullscreen()
				}
			})

			customButton.on('fullscreenchange', () => {})

			return customButton
		},

		/** Handles macro playback replay */
		overrideMacroPlaybackReplay()
		{
			return this.macroPlaybackButtonFactory(['vjs-play-control vjs-control'])
		},

		overrideBigReplayButton()
		{
			return this.macroPlaybackButtonFactory(['vjs-big-play-centered'], true)
		},

		macroPlaybackButtonFactory(classes = [], isBigButton = false)
		{
			/** Playback replay is handled by the same button that handles play/pause actions. */
			const componentName = isBigButton ? 'BigPlayButton' : 'Button'

			const BASE_CLASS = videojs.getComponent(componentName)

			const classString = 'vjs-button ' + classes.join(' ')

			const customButton = new BASE_CLASS(this.instance, {

				className: classString,

				controlText: 'Play',

				clickHandler: () => this._macroPlaybackClickHandler(customButton)
			})

			this.registerProxyHandlers(this.instance, customButton)

			return customButton
		},

		registerProxyHandlers(player , customButton)
		{
			const handlers = new Map()

			handlers.set('play', this._customHandlePlay)
			handlers.set('ended', this._customHandleEnded)
			handlers.set('pause', this._customHandlePause)

			handlers.forEach((handler, event) => player.on(event, () => handler(customButton)))
		},

		_macroPlaybackClickHandler(customButton)
		{
			if(customButton.controlText() === 'Replay')
			{
				return this.dispatchMacroPlayback()
			}

			if(customButton.hasClass('vjs-ended'))
				customButton.removeClass('vjs-ended')

			/** isPlaying */
			if(!this.instance.paused())
			{
				this.instance.pause()

				this._customHandlePause(customButton)

				customButton.controlText('Play')
			}
			else
			{
				this.instance.play()

				this._customHandlePlay(customButton)
			}
		},

		_customHandlePlay(customButton)
		{
			customButton.controlText('Play')

			if(!customButton.hasClass('vjs-playing'))
				customButton.addClass('vjs-playing')				

			if(customButton.hasClass('vjs-ended'))
				customButton.removeClass('vjs-ended')

			if(customButton.hasClass('vjs-paused'))
				customButton.removeClass('vjs-paused')

			// customButton.controlText('Pause') // Add inverse control text
		},

		_customHandlePause(customButton)
		{
			if(customButton.hasClass('vjs-playing'))
				customButton.removeClass('vjs-playing')

			if(!customButton.hasClass('vjs-paused'))
				customButton.addClass('vjs-paused')
		},

		_customHandleEnded(customButton)
		{
			customButton.controlText('Replay')

			if(!customButton.hasClass('vjs-paused'))
				customButton.addClass('vjs-paused')

			if(customButton.hasClass('vjs-playing'))
				customButton.removeClass('vjs-playing')

			customButton.addClass('vjs-ended')
		},

		handleFullscreen()
		{
			const playerWrapper = document.getElementById('vjs-player')

			videojs.trigger(this.instance.el_, 'playerresize')

			const currentlyFS = document.fullscreenElement

			if(!currentlyFS)
			{
				playerWrapper.requestFullscreen()

				if('lock' in screen.orientation)
					screen.orientation.lock('landscape-primary')

				return
			}

			if(currentlyFS.id === playerWrapper.id)
			{
				if('unlock' in screen.orientation)
					screen.orientation.unlock()

				return document.exitFullscreen()
			}
		},

		dispatchMacroPlayback()
		{
			this.$emit('macroplayback', {})
		}
	}
}
</script>