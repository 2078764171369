<template>
<div class="modal-background-2">
  <div class="modal-container-2">
      <slot name="modal-topbar" class="modal-top-bar-2">
      </slot>
      <div class="modal-content-2">
        <slot name="modal-body" class="modal-content-2"></slot>
      </div>
  </div>
</div>
</template>

<script>
export default {
	name: 'ModalBody2'
}
</script>

<style scoped>

</style>