/**
 * Return video active area accouting for letter and pillar boxes.
 * 
 * @see https://nathanielpaulus.wordpress.com/2016/09/04/finding-the-true-dimensions-of-an-html5-videos-active-area/
 * @param {HTMLVideoElement} video
 * @return {object}
 */
export function videoDimensions(video) {
	/**  Ratio of the video's intrisic dimensions */

	let videoRatio = isNaN(video?.videoWidth / video?.videoHeight) ?
		0 : video.videoWidth / video.videoHeight

	/** The width and height of the video element */
	let width = video.offsetWidth, height = video.offsetHeight

	/** The ratio of the element's width to its height */
	let elementRatio = width/height
    
	/** If the video element is short and wide
     *  It must be tall and thin, or exactly equal to the original ratio */
	elementRatio > videoRatio ?
		width = height * videoRatio : height = width / videoRatio

	return { width: parseInt(width), height: parseInt(height) }
}

/**
 * Handles elements rescaling relative to video element active size
 * 
 * @param { object } scale - Scale object with `original` and `dynamic`(current) properties set relative width and height
 * @returns { object } Style object
 */
export function calcRescaling(scale , element){

	const { properties: { transform } } = element

	let { original, dynamic } = scale

	let sX = 0, sY = 0, sW = 0, sH = 0

	if(dynamic)
	{
		/** Scaling */
		sW = ((transform.width * dynamic.width) / original.width)
		sH = ((transform.height * dynamic.height) / original.height)

		sX = ((transform.x * dynamic.width ) / original.width)
		sY = ((transform.y * dynamic.height ) / original.height)

		if((sX + sW) >= dynamic.width)
			sX = (dynamic.width - sW)

		if(sX <= 0)
			sX = 0

		if((sY + sH) >= dynamic.height)
			sY = (dynamic.height - sH)

		if(sY <= 0)
			sY = 0
	}

	return {
		top: `${sY.toFixed(2)}px`,
		left: `${sX.toFixed(2)}px`,
		width: `${sW.toFixed(2)}px`,
		height: `${sH.toFixed(2)}px`
	}
}

/** Util wrapper for parent plugin consumption.  */
export function normalizeMouseCoord_({ player, mouseX, mouseY })
{
	function normalizeMouseCoord({ videoOffsets, videoDimensions, mouseX, mouseY })
	{
		const iHeight = ((videoOffsets.offsetHeight - videoDimensions.height)/2)
		const iWidth = ((videoOffsets.offsetWidth - videoDimensions.width)/2)
		let localY = 0, localX = 0
  
		if(mouseY >= iHeight && mouseY <= window.innerHeight - iHeight)
			localY =  (mouseY - iHeight)
  
		if(mouseX >= iWidth && mouseX <= window.innerWidth - iWidth)
			localX = mouseX - iWidth
  
		return { x: localX, y: localY }
	}

	const { id_, techName_ } = player
	const playerID = `${id_}_${techName_}_api`.toLowerCase()
    
	const video = document.getElementById(playerID)
	const vidD = videoDimensions(video)
    
	return normalizeMouseCoord({ videoOffsets: video, videoDimensions: vidD, mouseX: mouseX, mouseY: mouseY })
}

export function arrayEquals (arrayA, arrayB) {
	// if the other array is a falsy value, return
	if (!arrayB)
		return false
	// if the argument is the same array, we can be sure the contents are same as well
	if(arrayB === arrayA)
		return true
	// compare lengths - can save a lot of time 
	if (arrayA.length != arrayB.length)
		return false

	for (var i = 0, l=arrayA.length; i < l; i++) {
		// Check if we have nested arrays
		if (arrayA[i] instanceof Array && arrayB[i] instanceof Array) {
			// recurse into the nested arrays
			if (!arrayA[i].equals(arrayB[i]))
				return false       
		}           
		else if (arrayA[i] != arrayB[i]) { 
			// Warning - two different object instances will never be equal: {x:20} != {x:20}
			return false   
		}           
	}       
	return true
}