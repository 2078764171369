import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { RootState, CreditStoreState } from '../index.d'

import { ActionAPI } from '../../services/ActionAPI'

const namespaced = true

const state: CreditStoreState = {
  
	clientCreditInfoList: [],
	creditTransactionHistory: []
}

const getters: GetterTree<CreditStoreState, RootState> = {

	computedClientCreditInfoList(state)
	{
		return state.clientCreditInfoList
	},
  
	computedCreditTransactionHistory(state)
	{
		return state.creditTransactionHistory
	}
}

const mutations: MutationTree<CreditStoreState> = {

	setClientCreditInfoList(state, { infoList })
	{
		state.clientCreditInfoList = [...infoList]
	},

	setCreditTransactionHistory(state, { history })
	{
		state.creditTransactionHistory = history
	}

}

const actions: ActionTree<CreditStoreState, RootState> = {

	async getClientCreditList({ commit }, partnerId: number)
	{
		const response = await ActionAPI.CreditService.getCreditListPerClient(partnerId)

		if(response)
		{
			const { data: entries } = response

			commit('setClientCreditInfoList', { infoList: entries })

			return { status: 'success' }
		}

		return { status: 'error' }
	},

	async getCreditTransactionHistory({ commit }, partnerId)
	{
		const response = await ActionAPI.CreditService.getCreditHistory(partnerId)

		if(response)
		{
			const { data: entries } = response

			commit('setCreditTransactionHistory',
				{
					history: entries
				})

			return { status: 'success' }
		}
  
		return { status: 'error' }
	},

	async addCreditToClient({ state, commit }, { partner_id, client_id, creditInfoRequest})
	{
		/** Wrapper object */
		// { partner_id, client_id, value, date, expiration_date, payment_method, payment_date, description }

		/** @todo Add exception handling if client not found */
		const response = await ActionAPI
			.CreditService
			.upsertClientCredit(partner_id, client_id, creditInfoRequest)

		if(response)
		{
			const { data: creditTransaction } = response

			const { payment_date: isPaid } = creditInfoRequest

			/** Locally update client balance if transaction indicates its already paid */
			if(isPaid !== null)
			{
				const updatedClientsInformation = state.clientCreditInfoList.map((clientInfo) => {
          
					if(clientInfo.client_id === client_id)
					{
						/** @todo This operation will probably become common place so it can be usefull to refactor into a math finances class or helper */
						clientInfo.current_balance = parseInt(creditTransaction.current_balance).toFixed(2)
					}
          
					return clientInfo
				})

				commit('setClientCreditInfoList', { infoList: updatedClientsInformation })
			}

			commit('setCreditTransactionHistory',
				{
					history: state.creditTransactionHistory.concat([creditTransaction])
				})

			return { status: 'success' }
		}

		return { status: 'error' }
	},

	async updateCreditTransaction({ state, commit }, { transactionId, transactionRecord })
	{
		const response = await ActionAPI
			.CreditService
			.updateCreditTransaction(transactionId, transactionRecord)

		if(response)
		{
			const { data: updatedTransaction } = response

			commit('setCreditTransactionHistory',
				{
					history: state.creditTransactionHistory.map(
						transaction => transaction.id === updatedTransaction.id ?
							Object.assign(transaction, updatedTransaction) : transaction
					)
				})

			return { status: 'success' }
		}
  
		return { status: 'error' }
	}
}
    
export default {
	namespaced,
	state,
	getters,
	mutations,
	actions
}