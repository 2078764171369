<template>
  <act-container class="support__recovery-password">


    <div class="recover-password__container">
      <h1 data-recover-password-header >Recuperar senha</h1>

      <form @submit.prevent class="recovery-password-form">
        <action-input label="Nova senha" placeholder="Mínimo de 4 carácteres" 
        v-model="recoveryPasswordForm.newPassword"
        />

        <action-input label="Confirme a senha" placeholder="Mínimo de 4 carácteres"
        v-model="recoveryPasswordForm.confirmationPassword"
        />

        <div class="recovery-password-form__btn" >
          <action-button @click.native.prevent="updatedPassword(recoveryPasswordForm)">
            <template #content> Alterar senha </template>
          </action-button>
        </div>
      </form>

    </div>
    
    <auth-background/>

  </act-container>
</template>

<script>
import ActionButton from '../../components/ActionButton.vue'
import AuthBackground from '../../components/AuthBackground.vue'
import ActionInput from '../../components/forms/ActionInput.vue'
import ActContainer from '../../components/layout/ActContainer.vue'

export default {
	name: 'SupportRecoveryPassword',
	components: { ActContainer, ActionInput, ActionButton, AuthBackground },
	data()
	{
		return {

			recoveryPasswordForm: {

				newPassword: '',
				confirmationPassword: ''
			}
		}
	},

	mounted()
	{
		/** @todo Move this conditional into a route guard clause */
		if('token' in this.$route.query)
		{
			this.recoveryPasswordForm.token = this.$route.query.token
		}

		// alert('Token de recuperação de senha expirado ou inválido.')

		// this.$router.push({ path: '/' }) // Login
	},

	methods: {

		async updatedPassword(passwordResetForm)
		{
			const action = await this.$store.dispatch('authStore/updateResetPassword', passwordResetForm)

			if(action.status === 'error')
			{
				return
			}

			alert('Senha alterada com sucesso. Retornando para a tela de login')

			this.$router.push({ path: '/' }) // Login
		}
	}

}
</script>

<style lang="scss" scoped>
.support__recovery-password
{
  place-items: center;
  justify-content: center;
  gap: 2rem;
}
.recover-password__container
{
  z-index: 9999;

  h1[data-recover-password-header]
  {
    text-align: center;
    margin-block: 0 1rem;
  }
}

.recovery-password-form
{
  background: var(--clr-neutral-900) !important;
  padding: 1rem;
  border-radius: .5rem;

  .recovery-password-form__btn
  {
    text-align: center;
    padding-block: 1rem .25rem;
  }
}
</style>