<script>
export default {

	methods: {

		/**
     * @param {HTMLEvent} event
     * @param {{ isOpen: bool, id: number }} elementObject
     * @param {{ id: number }} payload
     */
		elementToggler(event, elementObject, { id })
		{
			let { isOpen } = elementObject

			elementObject.isOpen = !isOpen

			if(isOpen)
			{
				elementObject.id = id
			}
		},

		modalToggler(event, elementObject, callback = null)
		{
			let { isOpen } = elementObject

			elementObject.isOpen = !isOpen

			if(isOpen && callback !== null)
			{
				callback(...arguments)
			}
		}
	}
}
</script>