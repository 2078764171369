<template>
  <div class="compact-video-card grid-flow">
    <div class="video-card__thumbnail">

      <!-- @todo Add support for video thumbnail -->
      <template v-if="video.thumbnail">
        <img data-thumbnail :src="video.thumbnail" alt="act-thumbnail">
      </template>
      <template v-else>
        <img data-thumbnail src="~@/../public/static/img_not_found.webp" alt="act-thumbnail">
      </template>
    </div>
    <div class="video-card__details grid-flow">

      <span data-video-name>{{ video.name }}</span>


      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
	name: 'compact-video-card',

	props: {

		video: {
			type: Object,
			required: true
		}
	}
}
</script>

<style lang="scss" scoped>
.grid-flow
{
  display: grid;
  gap: .5rem;
}

.compact-video-card
{
  --img-width: 6rem;
  --overflow: hidden;

  display: grid;
  grid-template-columns: var(--img-width) 1fr;

  .video-card__thumbnail
  {
    img[data-thumbnail]
    {
      width: 100%;
      vertical-align: middle;
      border-radius: .5rem;
    }
  }

  .video-card__details
  {
    display: grid;
    grid-auto-flow: column;

    justify-content: space-between;
    align-items: baseline;

    overflow: var(--overflow);

    span[data-video-name]
    {
      min-width: 8ch;
      overflow: var(--overflow);
      text-overflow: ellipsis;
    }

    .__details-remove-btn
    {
      padding: .25rem;
    }
  }
}
</style>