<template>
  <div id="interaction-multiple-choice" class="a-interaction">

    <!-- Workspace environment view -->
    <div class="interaction-multiple-choice">
      <VueDraggableResizable :id="`element-${element.id}`"
      v-for="element in elements" :key="element.id" 
      :title="element.label"
      :class="['a-interaction-element']"
			:data-selection-type="selectionType"
      :style="`
				${
					selectionType === 'outline ' &&
					selectedElement.id === element.id ? 'border: 2px dashed purple;' : ''}
			`"
      :parent="true"
      :resizable="true"
      :draggable="true"

			:lockAspectRatio="true"

			:w="parseInt(element.properties.transform.width)"
			:h="parseInt(element.properties.transform.height)"
      :x="parseInt(element.properties.transform.x)"
      :y="parseInt(element.properties.transform.y)"

      @mouseenter.native="$emit('elementmouseenter', element)"
      @mouseleave.native="$emit('elementmouseleave', element)"

      @click.native.prevent="selectElement(element)"
      @click.right.native.prevent="selectElement(element)"
      @dragging="$emit('elementdragging', element)"
			@dragstop="$emit('element-drag-stop', element)"
      @resizing="$emit('elementresizing', element)"
			@resizestop="$emit('element-resize-stop', element)"
			>

			<img
				class="interaction-element-icon"
				:src="`${$ImgLink}/interaction/${element.properties.style.backgroundImg}`"
				alt="">

      </VueDraggableResizable>
    </div>
    <!-- Este container e botão de enviar resposta acaba sendo genérico em várias interações,
    possivelmente seria melhor integrá-lo no componente pai. Analisar possíveis fallbacks. -->
    <div class="a-submit-container" v-if="userPlayback && selectedElement">
      <button class="a-submit-btn"
      @click.prevent="submitInteraction(selectedElement)" >
        Confirmar Resposta
      </button>
    </div>
  </div>
</template>

<script>
import VueDraggableResizable from 'vue-draggable-resizable'
import ElementMixin from './ElementMixin.vue'

export default {
	name: 'InteractionMultipleChoice',
	mixins: [ElementMixin],
	components: { VueDraggableResizable },
	props: {
		userPlayback: {
			type: Boolean,
			required: false,
			default: false
		},

		selectionType: {
			type: String,
			required: false,
			default: 'Icon'
		}
	}
}
</script>

<style lang="scss">

.interaction-multiple-choice
{
  @include fit-container;

  position: relative;
  z-index: 2000;
  background: transparent;
  pointer-events: none;

  .a-interaction-element
  {
		@include fit-container;

    position: absolute;
    z-index: auto;
    pointer-events: all;

    &.element-visible
    {
      visibility: visible;
      -webkit-animation: scale-up-center 0.2s cubic-bezier(0.550, 0.055, 0.675, 0.190) both;
      animation: scale-up-center 0.2s cubic-bezier(0.550, 0.055, 0.675, 0.190) both;
    }

		&[data-selection-type="icon"]
		{
			&::before
				{
					content: '';
					display: block;
					position: absolute;
					width: 100px; height: 100px;
					left: -5%; top: 50%;
					transform: translate(-100%, -50%);
					background: url('../../assets/interactions/int-check-icon.png') no-repeat;
					background-size: contain;
					opacity: .90; z-index: 99 !important;
					object-fit: contain;
				}
			}

			.interaction-element-icon
			{
			}

		&[data-selection-type=outline]
		{
			//
		}

    img
    {
      @include fit-container;
      @include hover-pointer;

      // object-fit: contain !important;
			pointer-events: none;
			z-index: 1;
    }
  }
}

@media screen and (max-width: 1020px)
{
  .element-user-view{
    // background: red;
    height: 37% !important;
  }
}
</style>
