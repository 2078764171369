<script>
export default {
	name: 'routeHandlerMixin',
	methods: {

		goToRoute(routePath)
		{
			let { current } =  this.$router.history
  
			if(current.path !== routePath)
			{
				this.$router.push({ path: routePath })
			}
		}
	}

}
</script>