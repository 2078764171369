import videojs, { VideoJsPlayer } from 'video.js'
import './submitButtonComponent.css'
import { InteractionElementComponent } from './InteractionElementComponent'
import { IBaseElementOptions } from '../types'

interface SubmitButtonComponentOptions extends IBaseElementOptions {
	content?: any,
	style: {
		width: number,
		height: number,
		primaryColor?: string,
		secondaryColor?: string,
		borderRadius: number
	}
}

export default class SubmitButtonComponent extends InteractionElementComponent {

	constructor(player: VideoJsPlayer, options: SubmitButtonComponentOptions) {
		super(player, options)

		this.state = Object.assign(
			this.state,
			{ content: options.content, style: options.style }) 

		const el_ = this.el() as HTMLElement

		if (el_) {

			const { style } = options
			/** Set editor defined styles */
			Object.assign(el_.style, {
				'max-height': `${style.height}px`,
				'width': `${style.width}px`,
				'background': style.primaryColor,
				'color': style.secondaryColor,
				'border-radius': style.borderRadius ? `${style.borderRadius}px` : '.33rem' // fixed border radius
			})

			videojs.on(el_, 'click', () => player.el().dispatchEvent(new CustomEvent('submitbtnclick')))
		}
	}

	createEl() {

		const options_ = this.options_ as SubmitButtonComponentOptions

		return videojs.dom.createEl('button', {

			className: 'vjs-act-submit-btn'
		}, {}, options_.content ? options_.content : 'Enviar resposta')
	}
}

videojs.registerComponent('SubmitButtonComponent', SubmitButtonComponent)