
import { AxiosInstance } from 'axios'
import BaseService from './BaseService'
import type { IAuthService, LoginRequest, ResetPasswordRequest } from './index.d'

export default class AuthService extends BaseService implements IAuthService {

	constructor(provider: AxiosInstance) {
		super(provider)
	}

	/**
	 * Performs login request with given credentials.
	 * @todo Add more specific handlers to catched response errors
	 *
	 * @param {{ email: string, password: string }} credentials
	 */
	async login({ email, password }: LoginRequest) {
		return await this.provider.post('/auth/login',
			{
				'email': email,
				'password': password
			})
			.catch(({ response }) => {

				const { status, data: { errors } } = response

				if (status === 422) {
					throw { errors, status }
				}

				throw new Error('Erro inesperado ao realizar o login')
			})
	}

	async logout() {
		if (!this.provider.defaults.headers['Authorization'])
			return console.warn('Session not set on request headers')

		const response = await this.provider.post('/auth/logout')

		return response
	}

	async sendResetPasswordNotification(email: string) {
		const response = await this.provider.post('notification/reset-password', { email: email })
			.catch(error => {

				if (error?.response.status === 422) {
					const { status, data: errorBag } = error.response

					return { status, ...errorBag }
				}
			})

		return response
	}

	async resetPassword(resetPasswordRequest: ResetPasswordRequest) {
		const response = await this.provider.post('auth/reset-password', resetPasswordRequest)
			.catch(error => {

				/** @todo Return error bag as an alert or pop for user display */
				if (error.response.status === 422) {
					const { data: { errors: errorBag } } = error.response

					Object.keys(errorBag).forEach(errorField => console.warn(errorField, errorBag[errorField]))
				}
			})

		return response
	}

	setAuthorizationHeader(value: string) {
		Object.assign(
			this.provider.defaults.headers, { 'Authorization': value })
	}
}