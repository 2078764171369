<template>
  <div id="vjs-player" :class="['vjs-a-player', { 'vjs-a-player__ios' : isIOS }]">
    <buffering v-if="isLoading"/>

    <template v-if="'logo' in $route.query">
      <link rel="prefetch" :href="$route.query.logo">
    </template>

    <template v-if="cannotAccess">
      <div id="cannont-access__overlay">

        <h5>Vídeo não existe ou está indisponível.</h5>
        <br style="margin: 1rem 0;">
        <small>Entre em contato com o dono do conteúdo para mais informações.</small>
      </div>
    </template>
    <BufferHandler @loadeddata.native="isLoading = false" v-if="'segments' in playlist" :meta="bufferHandlerMetadata" :playlist="playlist"/>
  </div>
</template>

<script>
import { BufferHandler } from '../../components'

import { ActionAPI } from '../../services/ActionAPI'
import UrlUtils from '../../classes/UrlUtils'
import Buffering from '../../components/player/Buffering.vue'

export default {
	name: 'ActionPlayer',
	components: { BufferHandler, Buffering },
	data(){
		return {
			isLoading: true,
			cannotAccess: false,
			isIOS: false,
			interactionScale: {
				original: {},
				dynamic: {}
			},
			options: {
				controls: true,
				FALLBACK_QUALITY: '360p'
			},
			playlist: {}
		}
	},

	computed:{

		bufferHandlerMetadata()
		{
			return {

				videoId: this.$route.params.videoId
			}
		}
	},

	async beforeMount(){

		const response = await this.fetchManifest(this.$route.params.videoId, this.$route.path.includes('preview'))

		if(response.status === 'error') return this.cannotAccess = true

		this.playlist = response.data
	},

	mounted()
	{
		if(navigator.userAgent.includes('iPhone'))
		{
			this.isIOS = true

			let view = document.getElementById('vjs-player')

			view.classList.add('ios-padding-offset')

			setTimeout(() => {

				// window.scrollTo(0, 1)
				view.scrollIntoView()
				view.classList.remove('ios-padding-offset')

			}, 0)
		}

		/** Expects the animation to be executed to be the onde
     * from the buffering component */
		window.addEventListener('animationend',
			() => {
				this.isLoading = false
			})
	},

	methods: {

		async fetchManifest(videoId, isPreview)
		{
			const url = new UrlUtils(window.location)
			
			const domain = url.getDomainString()

			/** Currenly only uses `origin` property */
			const requestDomainData = url.asParsedObject(domain)

			return await isPreview ?
				ActionAPI.ProjectService.previewVideo(videoId, requestDomainData) :
				ActionAPI.ProjectService.getPlaybackManifest(videoId, requestDomainData)
		},

		bufferingHandler(_event)
		{
			console.log(_event)
		}
	}
}
</script>

<style lang="scss" scoped>
@import '../../styles/scss/mixins.scss';

.vjs-a-player
{
  @include fit-container;
  position: fixed;

  overflow: visible;
  background: var(--clr-neutral-1000) !important;
}

#cannont-access__overlay
{
  @include fit-container;
  @include flex(column, center, center);

  background: var(--clr-purple-1000);
  background: linear-gradient(90deg, var(--clr-purple-1000) 0%, var(--clr-purple-900) 35%, var(--clr-purple-800) 100%);

  text-align: center;
  color: gainsboro;
}
</style>