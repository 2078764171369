<template>
  <div class="project-card" :title="project.name"
    @click="toggleDropdownMenu($event, project)">
    <img style="font-size: var(--fs-200)" src="../../public/static/img_not_found.webp" alt="thumbnail">

    <div class="project-card__content">
      <div class="project-card__content-title">
        <span class="__content-title">{{ project.name }}</span>
        <button class="project-card__opt-btn">
          <span class="material-symbols-outlined">
          more_vert
          </span>
        </button>
      </div>
      <div class="project-card__chips">
				<project-type-chips :type="project?.type_id"></project-type-chips>
        <act-chips :data-status="project.status.name">
          {{ UiComputedProjectStatus(project.status.name) }}
        </act-chips>
      </div>
      <span data-relative-time>{{ toRelativeTimeString(project?.display_updated_at) }}</span>
    </div>

    <div class="project-card__dropdown-options" v-if="projectDropdown.isOpen && project.id === projectDropdown.id">
      <ul v-for="(option, optionIndex) in computedProjectDropdown" :key="optionIndex">
        <action-button :id="`option-${option}`" :value="option" @click.native="handleDropdownOption($event, project)" class="project-card__dropdown-option">
          <template #content>{{ option }}</template>
        </action-button>
      </ul>
    </div>
</div>
</template>

<script>
import { defineComponent } from 'vue'
import TimeUtils from '../classes/TimeUtils'
import ActChips from './ActChips.vue'
import ActionButton from './ActionButton.vue'
import { translateProjectStatus } from '../classes/Translation'
import ProjectTypeChips from './ProjectTypeChips.vue'

export default defineComponent(
	{
		name: 'ProjectListItem',
		emits: [ 'open-project', 'open-project-edit-form', 'duplicate-project', 'remove-project' ],
		components: { ActChips, ActionButton, ProjectTypeChips },
		props: {
			project: {
				type: Object,
				required: true
			}
		},
		data()
		{
			return {
				projectDropdown: {
					id: null,
					isOpen: false,
					selectOptions: ['Abrir', 'Duplicar', 'Editar', 'Remover']
				}
			}
		},

		computed: {

			computedProjectTypes()
			{
				return 0
			},
			computedProjectDropdown()
			{
				if(this.$store.getters['accountStore/isMaster'])
				{
					return this.projectDropdown.selectOptions.filter(option => option === 'Abrir')
				}

				// Todo: Add filters based on other role restrictions

				return this.projectDropdown.selectOptions
			}
		},
		methods: {

			toggleDropdownMenu(event, project){
			/** Toggle */
				this.projectDropdown.isOpen = !this.projectDropdown.isOpen

				/* Set which context to open. */
				if(this.projectDropdown.isOpen)
					this.projectDropdown.id = project.id
			},


			handleDropdownOption(event, projectData){

				if(event.target.value !== null)
				{
					let selectedOption = event.target.value

					let eventToEmit = null

					switch (selectedOption) {
						case 'Abrir':
							{
								eventToEmit = 'open-project' 

								/** Redirect to workspace view loading specified selected project */
								this.$router.push({ path: `/workspace/${ this.$props.project.code }` })	
							} break
				
						case 'Editar':

							eventToEmit = 'open-project-edit-form' // Unexpected behaviour when updating 'active' project
							break

						case 'Duplicar':

							eventToEmit = 'duplicate-project' 
							break
				
						case 'Remover':
							// Confirms deletion and deletes
					
							if(confirm('Deseja realmente remover o projeto? Ele não estará mais disponível para visualização.'))
							{
								eventToEmit = 'remove-project' 
							}
							break
        
						default:
							break
					}

					if(eventToEmit !== null) { this.$emit(eventToEmit, projectData)}
				}
			},

			toRelativeTimeString({ t, unit })
			{
				return TimeUtils.toRelativeTimeString(t, unit).toLowerCase()
			},

			UiComputedProjectStatus(projectStatus)
			{
				return translateProjectStatus(projectStatus)
			}
		}

	}
)
</script>

<style lang="scss">
.act-chips
{
  color: #C1C1C1;
  font-size: var(--fs-150) !important;
  font-weight: var(--fw-600);

  &[data-status="inProgress"]
  {
    color: #402a18;
    background-color: #e29344;
  }
  &[data-status="complete"]
  {
    color: #C1C1C1;
    background-color: #1e88e5;
  }
  &[data-status="approved"]
  {
    color: #23470a;
    background-color: #71af44;
  }
  &[data-status="active"]
  {
    color: var(--clr-purple-800);
    background-color: var(--clr-purple-100);
  }
  &[data-status="inactive"]
  {
    color: #212121;
    background-color: #C1C1C1;
  }
}

.project-card
{
  --c-width: 13.25rem;
  --c-height: 8rem;
  --title-fs: var(--fs-250);

  display: flex;
  flex: 1 0 var(--c-width);
  position: relative;
  flex-direction: column;
  width: 100%; max-width: var(--c-width);
  height: 100%;
  border-radius: .5rem;
  background: transparent;
  img
  {
    object-fit: cover;
    background-size: cover;
    width: 100%; height: 100%;
    max-height: var(--c-height);
  }
}
.project-card__content, .project-card > img
{
  &:hover
  {
    cursor: pointer;
  }
}

.project-card__content
{
  gap: 1rem;
  display: flex;
  flex-direction: column;
  min-width: 100%;
  padding-block: .25rem 0;
  // padding: .25rem 1rem 0 .5rem;

  .project-card__content-title
  {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;

    .__content-title
    {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: var(--title-fs);
    }
  }
}
.project-card__opt-btn
{
  padding: .25rem 0;
  border: none;
  width: max-content; height: max-content;
  margin-inline-start: .25rem;
  color: var(--clr-neutral-200);
  background-color: transparent;

  &:hover
  {
    background-color: var(--clr-neutral-800);
  }
}
.project-card__chips
{
  gap: .5rem;
  display: inline-flex;
}
.project-card__dropdown-options
{
  z-index: 999999;
  position: absolute;
  top: 65%; right: -60%;
  border-radius: .25rem;
  width: 100%; max-width: 8rem;
  font-size: var(--fs-200) !important;

  .project-card__dropdown-option
  {
    width: 100%;
    text-align: start;
    &:nth-child(1)
    {
      border-radius: 0;
    }
  }
}
</style>