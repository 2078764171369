<template>
  <div id="interaction" class="interaction-view">
    <aside class="left-container" style="max-height: 90vh">
      <div class="a-list-container">

        <h6 class="side-panel__element-category-name">Interações</h6>

        <ul>
          <li>
            <button :id="`template-interaction-${interactionTypeId}`"
            v-for="(interactionTypeId, intrcTypeIndex) in interactionTypeMap" :key="intrcTypeIndex"
            :title="
            interactionTypeId === 1 ? 
              'Interação de items selecionáveis com clique':
            interactionTypeId === 3 ?
              'Interação de elementos arrastáveis com mouse ou toque' :
              'Interação para escolha das próximas ações'"
            class="a-list-item workspace-btn"
            :disabled="computedInteractiveComponents.length === 0"
            @click="setNewInteraction(interactionTypeId)">
            {{ interactionTypeId === 1 ? 'Múltipla Escolha' : interactionTypeId === 3 ? 'Arrastar e Soltar' : 'Controle de fluxograma' }}
            </button>
          </li>
        </ul>
      </div>
    </aside>
    <div class="center-container" style="max-height: 90vh">


      <!-- Central player contianer -->
      <div id="preview-container" v-if="activeSegmentComponent.id"
        class="preview-container">

        <div class="preview-interaction__overlay" v-if="activeSegmentComponent.interaction">
          <p style="text-align: start; padding: 1rem .5rem;">
            Interação atual: {{ activeSegmentComponent.name + ` ( ${activeSegmentComponent.interaction.interaction_type_id === 1 ?
              'Múltipla Escolha' : activeSegmentComponent.interaction.interaction_type_id === 4 ? 'Controle de fluxogram' : 'Arrastar e Soltar'} )` }}
            <!-- Tipo da interação: {{ activeSegmentComponent.interaction.type === 'multipleChoice' ?
              'Múltipla Escolha' : 'Arrastar e Soltar' }} -->
          </p>

          <div id="interaction-container" class="interaction-container">

            <div class="interaction-element-container">
              <!-- Hardcoded for only one interaction -->
              <component :is="interactionComponentType"
              :elements.sync="activeSegmentComponent.interaction.elements"

              :selectionType="
              activeSegmentComponent.interaction.selection_type ? 
              activeSegmentComponent.interaction.selection_type : 'icon'"

              @elementmouseenter="onComponentMouseEnter()"
              @elementmouseleave="onComponentMouseLeave()"

              @elementclick="selectElement($event)"
              @click.right.native.prevent
              
              @elementdragging="elementOnDragging($event)"
              @elementresizing="elementOnResizing($event)"
							@element-drag-stop="elementOnDragStop()"
							@element-resize-stop="elemenetOnResizeStop()"
              @snapelement="snapElement($event)"
              @submitinteraction="onSubmit($event)"

							:key="reloadComponentSwitch"
							/>

            </div>

            <VueDraggableResizable id="subtm-btn__el"
              class="interaction-submit-btn"
              parent draggable resizable
              v-show="interactionSubmitButton"
              
              :style="computedSbmtBtnStyle"

              :w="parseInt(interactionSubmitButton.style.width)"
              :h="parseInt(interactionSubmitButton.style.height)"
              :x="parseInt(interactionSubmitButton.style.x)"
              :y="parseInt(interactionSubmitButton.style.y)"
              @dragging="updateSubmitBtnProperties()"
              @resizing="updateSubmitBtnProperties()"
              
              @mouseenter.native="onComponentMouseEnter()"
              @mouseleave.native="onComponentMouseLeave()"
              >
                {{ interactionSubmitButton.content }}
            </VueDraggableResizable>

            <div class="interaction-bkg-overlay"
            @contextmenu.prevent
            />

          </div>
        </div>

        <iframe id="preview-iframe" :style="previewIframeStyles"
        :src="`https://player.vimeo.com/video/${activeSegmentComponent.source}`" frameborder="0"/>
      </div>

      <div class="fallback-container" v-if="computedInteractiveComponents.length === 0 || !activeSegmentComponent.id">
        <h6 class="fallback-container-txt">Crie ou selecione um vídeo interativo para editá-lo.</h6>
        <div class="fallback-container-background"/>
      </div>

    </div>


    <aside id="interaction-view-properties-panel" class="right-container" style="overflow-y: scroll; max-height: 90vh" >

      <!-- Interaction properties -->
      <div class="component-properties-container">
        <p>Interação</p>

        <hr>
        <br>

        <p>Tipo: {{
            activeSegmentComponent.interaction && activeSegmentComponent.interaction.type_name ?
            activeSegmentComponent.interaction.type_name === 'multipleChoice' ? 'Múltipla Escola' :
            activeSegmentComponent.interaction.type_name === 'dragNDrop' ? 'Arrastar e Soltar' : 'Controle de fluxograma' : '???'}}
        </p>

          <!-- && Object.keys(activeSegmentComponent.interaction).includes('loop') -->
          <template v-if="activeSegmentComponent && activeSegmentComponent.interaction && activeSegmentComponent.interaction.interaction_type_id === 3">
            <ActionInput id="interaction-info-inverse-bool" @click.native="handleToogleInverse($event.target)"
            v-show="activeSegmentComponent.interaction"
            @change.native="$store.dispatch('workspaceStore/togglePendingSaveState', true)"
            style="white-space: nowrap;"
            title="Obrigar usuário à utilizar todos os elementos arrastáveis. Padrão: Obriga que todas as áreas soltáveis sejam preenchidas."
            label="Forçar arrastáveis:  " type="checkbox" :checked="activeSegmentComponent.interaction.isInverse ? true : false"/>
          </template>

          <!-- Temporary fixed disabled to reimplement cuepoint logic. :disabled="!mouseState.elementSelected || !activeInteractionElement.id" -->
          <div class="interaction-info-loop-container"
          v-if="activeSegmentComponent && activeSegmentComponent.interaction && Object.keys(activeSegmentComponent.interaction).includes('loop')">

            <!-- Manually handles `addControlEventListeners` here. Todo: refactor -->

            <!-- Click event handles checkbox and loop property value -->
            <ActionInput id="interaction-info-loop-bool" @click.native="handleToggleLoop($event.target)"
            v-show="activeSegmentComponent.interaction"
            @change.native="$store.dispatch('workspaceStore/togglePendingSaveState', true)"
            style="white-space: nowrap; padding-top: 1rem;"
            title="Habilita configurar segmento do vídeo que irá se repetir."
            label="Intervalo de repetição: " type="checkbox" :checked="activeSegmentComponent.interaction.loop ? true : false"/>

            <div class="interaction-info-loop-time-ms" v-if="activeSegmentComponent.interaction.loop === true">

              <div class="interaction-info-loop-time__wrapper">
                <label for="interaction-info-loop__start-time">De: </label>
                <input id="interaction-info-loop__start-time" type="number"
                class="input_interaction-info-loop_time"
                placeholder="0"
                min="0" :max="videoMaxRange"

                @change="$store.dispatch('workspaceStore/togglePendingSaveState', true)"

                v-model.number.lazy="activeSegmentComponent.interaction.loop_start_ms"/>
              </div>

              <div class="interaction-info-loop-time__wrapper">
                <label for="interaction-info-loop__end-time">Até: </label>
                <input id="interaction-info-loop__end-time" type="number"
                class="input_interaction-info-loop_time"
                :placeholder="videoMaxRange"
                min="0" :max="videoMaxRange"

                @change="$store.dispatch('workspaceStore/togglePendingSaveState', true)"
                v-model.number.lazy="activeSegmentComponent.interaction.loop_end_ms"/>
              </div>
              <small>segundos</small>
            </div>

          </div>

          <div class="interaction-info_select_multiple">
            <template v-if="activeSegmentComponent.interaction && activeSegmentComponent.interaction.interaction_type_id === 1">

              <ActionInput id="interaction-info-select-multiple-bool" @click.native="handleToggleHasMultiple($event.target)"
              v-show="activeSegmentComponent.interaction"
              @change.native="$store.dispatch('workspaceStore/togglePendingSaveState', true)"
              style="white-space: nowrap; padding-top: 1rem;"
              title="Permite selecionar várias alternativas."
              label="Múltiplas alternativas: " type="checkbox" :checked="activeSegmentComponent.interaction.has_multiple ? true : false"/>

            </template>
          </div>

          <div class="interaction-info_selection_display_type">
            <template v-if="activeSegmentComponent.interaction && activeSegmentComponent.interaction.interaction_type_id === 1">

              <label for="selection-type-menu">Tipo de seleção</label>
              <br>
              <select id="selection-type-menu"
              name="selection-type-menu"
              class="element-info-attr" style="width: 100%; margin-top: 1rem;"
              title="Selecionar tipo de seleção visual de alternativa"
              v-model="activeSegmentComponent.interaction.selection_type"
              @change="setElementAttribute($event)"
              >

              <!-- Temporary solution: Uses ternary to translate values into user-friendly form. -->
                <option v-for="(selectionType, selectionTypeIndex) in interacionSelectionTypes" :key="selectionTypeIndex"
                :class="['element-info-attr-option']"
                :value="interacionSelectionTypes[selectionTypeIndex]">
                {{ selectionType === 'icon' ? 'Ícone' : 'Borda' }}
                </option>
              </select>

            </template>
          </div>

          <!-- Interaction output  and visualization -->
          <div v-if="activeSegmentComponent.interaction && activeSegmentComponent.nextSegment && activeSegmentComponent.interaction.interaction_type_id !== 4"
            class="a-form-input-container">

            <!-- Output: correct -->
            <div id="interaction_output__correct" class="interaction_output__prop">

              <span class="container-heading">Certo</span>
            
              <select id="next-segmets-menu" name="next-segmets-menu"
              class="element-info-attr" style="width: 100%"
              :disabled="filteredNextSegment.length === 0"
              title="Selecionar qual vídeo representa a resposta correta à interação"
              v-model="activeSegmentComponent.interaction.output.right"
              @change="setElementAttribute($event)">

                <option v-for="(nextSegment, ns) in filteredNextSegment" :key="ns"
                :class="['element-info-attr-option', { 'select-option-invalid' : activeSegmentComponent.nextSegment[nextSegment] === null }]"
                :value="activeSegmentComponent.nextSegment[nextSegment]">
                {{ nextSegment }}
                </option>
              </select>
            </div>

            <!-- Output: incorrect -->
            <template v-if="activeSegmentComponent && activeSegmentComponent.interaction && activeSegmentComponent.interaction.interaction_type_id === 3">

                <div id="interaction_output__incorrect" class="interaction_output__prop">

                  <p class="container-heading">Errado</p>
                  
                  <select id="next-segmets-menu" name="next-segmets-menu"
                  class="element-info-attr" style="width: 100%"
                  :disabled="filteredNextSegment.length === 0"
                  title="Selecionar qual vídeo representa a resposta errada à interação"
                  v-model="activeSegmentComponent.interaction.output.wrong"
                  @change="setElementAttribute($event)">

                    <option v-for="(nextSegment, ns) in filteredNextSegment" :key="ns"
                    :class="['element-info-attr-option', { 'select-option-invalid' : activeSegmentComponent.nextSegment[nextSegment] === null }]"
                    :value="activeSegmentComponent.nextSegment[nextSegment]">
                    {{ nextSegment }}
                    </option>
                  </select>

                </div>
            </template>

          </div>

      </div>

      <!-- Element properties -->
      <div class="component-properties-container"> 

        <p>Elemento interativo</p>

        <br>

        <!-- Old context menu options now set into properties panel -->

        <!-- Drag N Drop options -->
        <template v-if="activeSegmentComponent.interaction && activeSegmentComponent.interaction.interaction_type_id === 3">
          <button id="context-add-element-btn"
          class="a-context-menu-btn workspace-btn prop_panel_btn"
          @click.prevent="storeNewElement(activeSegmentComponent.interaction.id, 'draggable')">
            Adicionar arrastável
          </button>

          <button id="context-add-element-btn"
          class="a-context-menu-btn workspace-btn prop_panel_btn"
          @click.prevent="storeNewElement(activeSegmentComponent.interaction.id, 'dropable')">
            Adicionar área soltável
          </button>
        </template>

        <!-- Other options -->
        <template v-else>
          <button id="context-add-element-btn"
          class="a-context-menu-btn workspace-btn prop_panel_btn"
          title="Adicionar objeto interativo"
          @click.prevent="storeNewElement(activeSegmentComponent.interaction.id)">
            Adicionar
          </button>
        </template>

        <div class="element-info-misc-container">

          <button id="context-add-element-btn"
          class="a-context-menu-btn workspace-btn prop_panel_btn"
          title="Remove o elemento selecionado"
          :disabled="!mouseState.elementSelected || !activeInteractionElement.id"
          @click.prevent="removeInteractionElement()">
            Remover
          </button>

          <button id="context-add-element-btn"
          class="a-context-menu-btn workspace-btn prop_panel_btn"
					tabindex="-1"
          title="Alterar imagem do elemento selecionado"
          :disabled="!mouseState.elementSelected || !activeInteractionElement.id"
          @click.prevent="$refs.inputFileImgCtx.click()">
            Alterar imagem
          </button>
          <input ref="inputFileImgCtx" id="element-info-style-img" type="file" accept="image/*"
          style="display: contents;"
          :disabled="!mouseState.elementSelected || !activeInteractionElement.id"
          @change="uploadElementImg($event.target, activeInteractionElement.id)">

        </div>


        <p v-if="activeInteractionElement && (activeInteractionElement.type === 2 || activeInteractionElement.type === 3)">
          Tipo: {{ activeInteractionElement.type === 2 ? 'Arrastável' : 'Soltável'}}</p>

        <label for="element-info-label" style="font-size: 1rem; line-height: 1.65rem;">Selecionado: </label>
        <input id="element-info-label" type="text"
        :disabled="!mouseState.elementSelected || !activeInteractionElement.label"
        class="container-heading__input"
        placeholder="Nome do elemento"
        @change="updateElementLabel()"
        v-model="activeInteractionElement.label">

        <hr>

        <form ref="elementInfoForm"
        :class="['form-properties-data', { 'element-no-selected' : !activeInteractionElement.id }]">

          <div class="wrap" v-if="activeInteractionElement.type === 1">
            <p>Atributos</p>

            <hr>
          </div>

          <!-- Multiple Choice attribute -->
          <div v-if="activeInteractionElement.id && activeInteractionElement.type === 1" class="a-form-input-container">

            <p class="container-heading">Próximo segmento</p>
            
            <select id="next-segmets-menu" v-if="Object.keys(activeInteractionElement.attributes).includes('nextSegmentId')"
            title="Segmento ao qual o elemento direcionardo redirecionará"
            name="next-segmets-menu" class="element-info-attr"
            :disabled="!mouseState.elementSelected || !activeInteractionElement.id"
            v-model="activeInteractionElement.attributes.nextSegmentId"
            @change="setElementAttribute($event)">

              <option v-for="(nextSegment, ns) in filteredNextSegment" :key="ns"
               :class="['element-info-attr-option', { 'select-option-invalid' : activeSegmentComponent.nextSegment[nextSegment] === null }]"
               :value="activeSegmentComponent.nextSegment[nextSegment]">
               {{ nextSegment }}
              </option>
            </select>
          </div>

          <!-- ?Todo: Drag N Drop attribute. Enables which element is which pair -->

          <template v-if="activeInteractionElement.id && activeInteractionElement.type === 2">
            <div class="a-form-input-container">

              <p class="container-heading"> Elemento par</p>

              <select id="next-segmets-menu" v-if="Object.keys(activeInteractionElement.attributes).includes('pairElementId')"
              name="next-segmets-menu" class="element-info-attr"
              :disabled="!mouseState.elementSelected || !activeInteractionElement.id"
              v-model="activeInteractionElement.attributes.pairElementId"
              @change="setElementAttribute($event)">

                <option v-for="(dropZones, dzs) in filteredDropableElements" :key="dzs"
                :class="['element-info-attr-option']"
                :value="dropZones.id">
                {{ dropZones.label }}
                </option>
              </select>

            </div>
          </template>

          <p>Propriedades do elemento</p>

          <hr>

          <p
          title="Coordenadas do elemento selecionado no vídeo. Coordendas (0,0): canto superior esquerdo">
            Posição
          </p>
          <div class="a-form-input-container"
          style="margin-top: .5rem;">

            <div class="element-info__sided">

              <div class="element-info-transform__coord"
              title="Posição horizontal">
                <label for="element-info-transform-y">X</label>
                <input id="element-info-transform-y" type="number"
                placeholder="x" :disabled="!mouseState.elementSelected || !activeInteractionElement.id"
                v-model.number.lazy="activeInteractionElement.properties.transform.x">
              </div>
                
              <div class="element-info-transform__coord"
              title="Posição vertical">
                  <label for="element-info-transform-x">Y</label>
                  <input id ="element-info-transform-x" type="number"
                  :disabled="!mouseState.elementSelected || !activeInteractionElement.id"
                  v-model.number.lazy="activeInteractionElement.properties.transform.y">
              </div>
            </div>

            <p>Dimensões</p>
            <div class="element-info__sided"
              title="Dimensões do elemento selecionado">

              <div class="element-info-transform__coord">

                <label for="element-info-transform-width">Largura</label>
                <input id="element-info-transform-width" type="number"
                :disabled="!mouseState.elementSelected || !activeInteractionElement.id"
                :class="[{ 'input-invalid-value' : activeInteractionElement.properties.transform.width < constantMinElSize }]"
                v-model.number="activeInteractionElement.properties.transform.width"
								@change.prevent="onPanelRescaling(activeInteractionElement, 'width')">

              </div>

              <div class="element-info-transform__coord">

                <label for="element-info-transform-height">Altura</label>
                <input id="element-info-transform-height" type="number"
                :disabled="!mouseState.elementSelected || !activeInteractionElement.id"
                :class="[{ 'input-invalid-value' : activeInteractionElement.properties.transform.height < constantMinElSize }]"
                v-model.number="activeInteractionElement.properties.transform.height"
								@change.prevent="onPanelRescaling(activeInteractionElement, 'height')">
              </div>
            </div>
          </div>

          <p>Outros</p>

          <!-- Todo: remember to set existing element where `interaction_element_type_id` are 'NULL' to '1' -->
          <!-- Temporary fixed disabled to reimplement cuepoint logic. :disabled="!mouseState.elementSelected || !activeInteractionElement.id" -->
          <div class="element-info-time-container">
            <ActionInput id="element-info-display-sta rt-time" v-model="activeInteractionElement.properties.displayStartS"
            title="Tempo de vídeo em que o elemento selecionado deverá ficar visível"
            :disabled="!mouseState.elementSelected || !activeInteractionElement.id"
            label="Visível em:" type="range" 
            min="0" :max="videoMaxRange.toString()"
            />
            <output  id="element-info-time-output"
            class="element-info-time-suffix">
            {{ activeInteractionElement.properties.displayStartS ? activeInteractionElement.properties.displayStartS : '0' }} s
            </output>

          </div>

          <!-- Color attributes for draggable and dropable elements -->

        </form>

      </div>

      <!-- Confirmation button settings -->
      <div class="interaction-info-btn-config component-properties-container">

        <h6 class="side-panel__element-category-name">Botão</h6>

        <div class="a-form-input-container side-panel-prop__wrapper" style="display: flex; flex-direction: column;">

          <div class="side-panel__input-wrapper">
            <label for="side-panel-prop__content">Conteúdo</label>
            <input id="side-panel-prop__content" type="text"
            placeholder="Enviar resposta"
            v-model.lazy="interactionSubmitButton.content">
          </div>

          <span class="side-panel__prop-category-name">Transformações</span>

          <div class="grid-2-col">
            <div class="side-panel__input-wrapper">
              <label for="side-panel-prop__x">x</label>
              <input id="side-panel-prop__x" type="number"
              placeholder="x"
              v-model.number.lazy="interactionSubmitButton.style.x">
            </div>

            <div class="side-panel__input-wrapper">
              <label for="side-panel-prop__y">y</label>
              <input id ="side-panel-prop__y" type="number"
              placeholder="y"
              v-model.number.lazy="interactionSubmitButton.style.y">
            </div>
          </div>

          <div class="grid-2-col">
            <div class="side-panel__input-wrapper">
              <label for="side-panel-prop__width">largura</label>
              <input id="side-panel-prop__width" type="number"
              v-model.number="interactionSubmitButton.style.width">
            </div>

            <div class="side-panel__input-wrapper">
              <label for="side-panel-prop__height">Altura</label>
              <input id="side-panel-prop__height" type="number"
              v-model="interactionSubmitButton.style.height">
            </div>
          </div>

          <span class="side-panel__prop-category-name">Estilos</span>

          <div class="side-panel__input-wrapper">
            <action-input label="Raio da borda" type="range" min="0" max="50"
            v-model="interactionSubmitButton.style.borderRadius"
            />

            <div class="grid-2-col" style="justify-items:center">
              <div class="side-panel__input-wrapper">
                <label for="side-panel-prop__p-color">Primária</label>
                <input id="side-panel-prop__p-color" type="color"
                v-model="interactionSubmitButton.style.primaryColor">
              </div>

              <div class="side-panel__input-wrapper">
                <label for="side-panel-prop__s-color">Secundária</label>
                <input id="side-panel-prop__s-color" type="color"
                v-model.number="interactionSubmitButton.style.secondaryColor">
              </div>

            </div>
          </div>

          <div>

          </div>

        </div>

      </div>

      <!-- Component list -->
      <div class="a-list-container" style="min-height: 12rem; height: auto; max-height: 24rem">
        <p class="container-heading">Componentes</p>
        <ul>
          <li>
            <div v-for="component in computedInteractiveComponents" :key="component.id"
            :class="['a-list-item', { 'component-list-item-selected' : activeSegmentComponent.id === component.id } ]"
            @click.prevent="swapActiveComponent(component)">
              <p>{{ component.name }}</p>
          </div>
          </li>
        </ul>
      </div>
    </aside>

  </div>
</template>

<script>
import { ContextMenu, InteractionMultipleChoice, InteractionDragNDrop, ActionInput } from '../../components'
import { mapState } from 'vuex'
import VueDraggableResizable from 'vue-draggable-resizable'

export default {
	name: 'InteractionView',
	components: {
		ContextMenu,
		ActionInput,
		VueDraggableResizable,
		InteractionMultipleChoice,
		InteractionDragNDrop
	},
	data() {
		return {

			isIFrameEventsDisabled: false,
			reloadComponentSwitch: 0,

			constantMinElSize: 50,
			interactionComponentType: null,
			/** 1: Multiple Choice; 3: DragNDrop; 4: GrahpControl */
			interactionTypeMap: [1, 3, 4],
			videoMaxRange: 0,
			activeSegmentComponentId: null,
			interacionSelectionTypes: ['icon', 'outline'],
			activeSegmentComponent: {
				id: null,
				type: null,
				source: null,
				interaction: {
					id: null,
					type_name: null,
					typeId: null,
					has_multiple: false,
					loop: false,
					loop_start_ms: 0, loop_end_ms: 0,
					output: { right: null, wrong: null },
					selection_type: 'icon',
					elements: []
				},
				nextSegment: {}
			},
			activeInteractionElement:{
				id: null,
				type: null,
				properties:{
					transform:{
						x: 0, y: 0,
						width: 0, height: 0
					},
					backgroundImg: ''
				},
				attributes: {
					nextSegmentId: null,
					pairElementId: null
				}
			},

			cache: {
				elementPreviousWidth: 0,
				elementPreviousHeight: 0
			},

			interactionSubmitButton: {
				id: null,
				content: 'Botão',
				style: {
					x: 0, y: 0,
					height: 25, width: 150,
					primaryColor: '#141414', secondaryColor: '#F5F5F1',

					borderRadius: 0
				}
			}
		}
	},


	watch:{

		'mouseState.elementSelected': {

			handler: function(j) {

				if(j === false && this.activeInteractionElement)
				{
					this.resetActiveInteractionElement()
				}
			}
		},

		'interactionSubmitButton.style.primaryColor': {

			handler: function(v) {
				if('components' in this)
				{
					let i = this.components.findIndex(c => c.id === this.activeSegmentComponent.id)
					this.components[i].interaction.submit_button.style.primaryColor = v
				}
			}
		},

		'interactionSubmitButton.style.secondaryColor': {

			handler: function(v) {
				if('components' in this)
				{
					let i = this.components.findIndex(c => c.id === this.activeSegmentComponent.id)
					this.components[i].interaction.submit_button.style.secondaryColor = v
				}
			}
		}
	},

	computed: {

		...mapState({
			components: state => state.workspaceStore.components,

			uiState: state => state.workspaceStore.uiState,
			mouseState: state => state.ioStore.mouseState
		}),

		/** Used for element rendering */
		computedSbmtBtnStyle(){

			const { primaryColor, secondaryColor, borderRadius } = this.interactionSubmitButton.style

			return {
				'position': 'absolute',
				'display': 'flex',
				'justify-content': 'center',
				'align-items': 'center',

				'text-align': 'center',
				'border-radius': `${borderRadius}px`,

				'background': `${primaryColor} !important`,
				'color': `${secondaryColor} !important`
			}
		},

		computedActiveSegmentComponent()
		{
			return this.activeSegmentComponent
		},

		computedInteractiveComponents(){
			return this.components.filter(component => component.type === 'interactive')
		},

		filteredNextSegment(){
			const { nextSegment, interaction } = this.activeSegmentComponent

			return Object.keys(nextSegment).filter(i => {

				if(i == 'default') return

				if(nextSegment[i] !== interaction.output.right || ('wrong' in interaction?.output && nextSegment[i] !== interaction.output.wrong))
					return i
			})
		},

		filteredDragNDropElements()
		{
			const { interaction: { elements } } = this.activeSegmentComponent

			const matchingType = this.activeInteractionElement.type === 2 ? 3 : 2

			return elements.map(el => {

				if(el.type === matchingType)
					return el.id
			})
		},

		filteredDropableElements()
		{
			const { interaction: { elements } } = this.activeSegmentComponent

			return [...elements.filter(el => el.type === 3), { id: null, label: 'Vazio' }]
		},

		previewIframeStyles()
		{
			return {

				'pointer-events' : this.isIFrameEventsDisabled ? 'none' : 'all',
				'z-index': '99 !important'
			}
		}
	},

	mounted(){
		// Se não tiver interação, perguntar se deseja adicionar.

		this.$nextTick(() => {

			setTimeout(() => {

				this.addControlEventListenters()

				if(this.computedInteractiveComponents.length > 0)
					this.setActiveSegmentComponent(this.computedInteractiveComponents.at(0))

			}, 1000)
		})
	},

	methods: {

		onPanelRescaling(element, field = 'width')
		{
			const { width, height } = element.properties.transform

			const { elementPreviousWidth, elementPreviousHeight } = this.cache

			if(field === 'height')
			{
				element.properties.transform.width = height * elementPreviousWidth / elementPreviousHeight
			}

			if(field === 'width')
			{
				element.properties.transform.height = width * elementPreviousHeight / elementPreviousWidth
			}

			this.cache.selementPreviousWidth = element.properties.transform.width
			this.cache.elementPreviousHeight = element.properties.transform.height
		},

		elementOnResizing(element)
		{
			this.isIFrameEventsDisabled = true
			this.updateElementProperties(element)
		},

		elemenetOnResizeStop()
		{
			this.isIFrameEventsDisabled = false
		},

		elementOnDragging(element)
		{
			this.updateElementProperties(element)
			this.isIFrameEventsDisabled = true
		},

		elementOnDragStop()
		{
			this.isIFrameEventsDisabled = false
		},

		/** @todo Only used to handle legacy event system. Can be refactored if
     *  you're certain it'll still allow the user to interact with the video buffer layer
     *  without conflicting the events */
		onComponentMouseEnter()
		{
			this.isIFrameEventsDisabled = true
		},

		onComponentMouseLeave()
		{
			this.isIFrameEventsDisabled = false
		},

		setActiveSegmentComponent(segmentComponent)
		{
			this.activeSegmentComponent = {... this.activeSegmentComponent, ...segmentComponent }

			const { interaction } = this.activeSegmentComponent

			const hasInteraction = interaction !== null

			/** Add default new element */
			if(!hasInteraction)
			{
				if(confirm('Este componente ainda não possui uma interação. Deseja adicioná-la agora?'))
				{
					this.setNewInteraction(this.interactionTypeMap[0]) // [0] = multipleChoice, [1] = dragNDrop, [3] = graphControl
				}
				else return
			}
			else
			{
				this.setInteractionComponent(interaction.interaction_type_id)

				this.setVideoMaxRange()

				this.interactionSubmitButton  = interaction.submit_button
			}
		},

		/** @todo Extract call to an external api service class */
		/** Fetchs oembed information @deprecated: use vimeoAPI */
		setVideoMaxRange(){
			this.$store.dispatch('workspaceStore/requestOEmbedMetadata',
				{
					url: 'http://vimeo.com/' + this.activeSegmentComponent.source
				}
			)
				.then(({ data: { duration }}) => {

					this.videoMaxRange = duration
				})
				.catch(error => console.error(error))
		},

		setInteractionComponent(interactionTypeId = null)
		{
			switch (interactionTypeId) {
				case 1:
					this.interactionComponentType = 'InteractionMultipleChoice'

					break
      
				case 3:
					this.interactionComponentType = 'InteractionDragNDrop'
					break

					/** GraphControl */
				case 4:
					this.interactionComponentType = 'InteractionMultipleChoice'

					break
        
				default:
					this.interactionComponentType = null
					break
			}
		},

		hasInteraction(segmentComponent)
		{
			return segmentComponent.interaction !== null
		},

		/**
     * Checks whether a `checkbox` is checked and assigns boolean to loop property
     * 
     * @param {HTMLElement} eventTarget
     * @returns {boolean}
     */
		handleToggleLoop(eventTarget){

			this.activeSegmentComponent.interaction.loop = eventTarget.checked

		},

		/**
     * Checks whether a `checkbox` is checked and assigns boolean to has_multiple property
     * 
     * @param {HTMLElement} eventTarget
     * @returns {boolean}
     */
		handleToggleHasMultiple(eventTarget){

			this.activeSegmentComponent.interaction.has_multiple = eventTarget.checked

		},

		handleToogleInverse(eventTarget)
		{
			this.activeSegmentComponent.interaction.isInverse = eventTarget.checked
		},

		/**
     * @version 230424 - Changes implementation to use vanilla for-loop cause,
     * at this time, forEach callbacks were causing small performance overhead
     * that scaled pretty quickly with the events and element calls;
     */
		addControlEventListenters()
		{
			const form = this.$refs.elementInfoForm ? Array.from(this.$refs.elementInfoForm.children) : []

			let inputElements = []

			for (let index = 0, formLength = form.length; index < formLength; index++)
			{
				const child = form.at(index)

				if(child.classList[0] === 'a-form-input-container')
				{
					for (let node = 0, nodesLength = child.childNodes.length; node < nodesLength; node++)
					{
						if(node.nodeName === 'INPUT')
						{
							inputElements.push(child.childNodes.at(node))
						}
					}
				}
			}

			for (let idx = 0, elementsLength = inputElements.length; idx < elementsLength; idx++)
			{
				inputElements.at(idx).addEventListener('change', () => this.handlePendingSave(), true)
			}
		},

		handlePendingSave()
		{
			if(this.uiState.canSaveChanges)
			{
				this.$store.dispatch('workspaceStore/togglePendingSaveState', true)
			} 
		},

		/**
     * @todo add validation for num of uploaded files
     * @todo fix issue where sometimes previous image is still after change loaded
     * @todo fix issue where an element sometime does not load, or loads after long time
     */
		async uploadElementImg(inputEl, elementId)
		{
			let formData = new FormData()

			formData.set('image', inputEl.files[0])

			/** Used to scale down elements to a more user friendly size */
			const dimensionScale = this.$store.getters['workspaceStore/getDimensionScale']

			formData.set('max-width', dimensionScale.width)
			formData.set('max-height', dimensionScale.height)

			const response = await this.$store.dispatch('workspaceStore/storeElementImage',
				{
					segmentId: this.activeSegmentComponent.id,
					interactionId: this.activeSegmentComponent.interaction.id,
					elementId: elementId,
					formData: formData
				})

			inputEl.value = '' // prevents previous state values from being loaded

			if(response)
			{
				const { data: elementData } = response

				const { interaction: { elements } } = this.activeSegmentComponent

				this.activeSegmentComponent.interaction.elements = elements.map(
					element => {

						if(element.id === elementData.id) Object.assign(element, elementData) // Assign new value

						return element
					})
			}

			// Prevents distorted element dimensions due to 'lockAspectRatio' prop
			this.reloadComponentSwitch = !this.reloadComponentSwitch
		},

		/**
     * Wrapper for `setActiveSegmentComponent(component)` with confirmation alert.
     * Used to prevent triggering alert when `setActiveSegmentComponent` is called
     * during `mount` hooks and no real change has been made.
     * 
     * @param {object} component
     */
		swapActiveComponent(component){

			if(component.id === this.activeSegmentComponent.id)
				return

			/* Handles unsaved changes */
			if(this.uiState.canSaveChanges){

				if(!confirm(
					'Há alterações não salvas, deseja prosseguir mesmo assim?'
				))
					return
			}

			// Todo: adjust elements swap and reactivicty

			this.setActiveSegmentComponent(component)
		},

		selectElement(element){

			const isValid = this.activeInteractionElement && Object.keys(this.activeInteractionElement).includes('id')

			if(isValid && this.activeInteractionElement.id === element.id)
			{
				this.updateElementProperties(element)
			}

			this.activeInteractionElement = Object.assign(this.activeInteractionElement, element)

			const { transform: { width, height } } = this.activeInteractionElement.properties

			this.cache.elementPreviousWidth = width
			this.cache.elementPreviousHeight = height
		},

		//#region Form routines

		setElementAttribute(/* event */){

			// console.log(event.target.value)
			this.$store.dispatch('workspaceStore/togglePendingSaveState', true)
		},

		onSubmit(submitData){
			console.log(submitData)
		},

		//#endregion

		/**
     * @param {string} interactionType
     */
		async setNewInteraction(interactionType)
		{

			/** Current scenario only allows a single interaction per segment */
			await this.attemptOverideInteraction()

			if(this.activeSegmentComponent.interaction !== null  && this.activeSegmentComponent.interaction.id )
			{
				if(this.activeSegmentComponent.interaction.id)
					return
			}

			/* Interaction model */
			let storeInteractionRequest = {
				typeId: interactionType,
				type: interactionType === 1 ? 'multipleChoice' : interactionType === 2 ? 'dragNDrop' : 'graphControl',
				startMs: 0, endMs: 0,
				elements: []
			}

			/** Interaction should be responsible for also adding some initial elements? */
			const updatedSegment = await this.$store.dispatch('workspaceStore/storeNewInteraction',
				{
					segmentId: this.activeSegmentComponent.id,
					data: storeInteractionRequest
				})

			/** Updates local data to allow performing following operations.
       *  Can be improved to fetch directly from store through getters. */

			const { interaction: newInteraction } = updatedSegment

			this.activeSegmentComponent = { ... updatedSegment }
            
			/** Stores with default values based on the passed interaction type */
			await this.storeNewElement(newInteraction.id, null, {})

			this.interactionSubmitButton = {
				... this.interactionSubmitButton,
				... newInteraction.submit_button
			}

			alert('Interação adicionada com sucesso')
		},

		async attemptOverideInteraction()
		{
			if(this.activeSegmentComponent.interaction !== null)
			{
				/** Requests user confirmation prompt */
				if(!confirm('Este segmento já possui uma interação. Deseja sobrescrevê-la? \n Todos os elementos desta interação serão removidos permanentemente.'))
					return

				const response =await this.$store.dispatch('workspaceStore/destroyInteraction',
					{
						segmentId: this.activeSegmentComponent.id,
						interactionId: this.activeSegmentComponent.interaction.id
					})

				const { status } = response

				/** Reset state */
				if(status === 200)
				{
					this.activeSegmentComponent = {
						... this.activeSegmentComponent,
						interaction: {
							id: null,
							type: null,
							typeId: null,
							loop: false,
							loop_start_ms: 0, loop_end_ms: 0,
							output: { right: null, wrong: null },
							elements: []
						}
					}
				}
			}

			console.warn('O segmento não possui nenhuma interação')
		},

		//#region Element Crud

		updateElementLabel()
		{
			const { interaction: { elements }} = this.activeSegmentComponent

			let elIndex = [...elements].findIndex(el => el.id === this.activeInteractionElement.id)   // (el => el.id === id)

			if(elIndex !== -1)
				Object.assign(this.activeSegmentComponent.interaction.elements[elIndex], this.activeInteractionElement)
		},

		async storeNewElement(interactionId, requestOperation = null, data = {})
		{
			/** Prepping request object */
			const interactionElementRequest = {
				label: 'Elemento interativo',
				requestOperation: requestOperation,
				... data
			}

			const response = await this.$store.dispatch('workspaceStore/storeInteractionElement',
				{
					segmentId: this.activeSegmentComponent.id,
					interactionId: interactionId,
					data: interactionElementRequest
				})

			const { data: newElement } = response

			let { interaction: selectedInteraction } = this.activeSegmentComponent

			/** Case: Handles dragndrop elements that are created as object pair */
			Array.isArray(newElement) ? 
				newElement.forEach(el => selectedInteraction.elements.push(el)) :
				selectedInteraction.elements.push(newElement)

			/* Pushes new element into array and update interaction object */
			this.activeSegmentComponent = { ... this.activeSegmentComponent, interaction: selectedInteraction }

			/* Selects last/newest element for editing */
			this.selectElement(selectedInteraction.elements.at(-1))

			/* Resets screen data for fallback */
			this.setInteractionComponent(this.activeSegmentComponent.interaction.interaction_type_id) // Reloads interactions
		},

		/**
     * Atualiza um único elemento quando alguma informação no painel é atualizada.
     * @todo Update formData param type to be more explicit.
     * @param {object} formData
     */
		updateInteractionElement(formData){

			this.$store.dispatch('workspaceStore/updateInteractionElement',
				{
					interactionId: this.activeSegmentComponent.interaction.id,
					element: formData
				}).then(({ status, data }) => {

				if(status === 200){

					this.activeInteractionElement = data
				}

				// let { interaction: { elements } } = this.activeSegmentComponent

				// 	this.activeSegmentComponent.interaction.elements = elements.map(element =>
				// 	{
				// 		if(element.id === this.activeInteractionElement.id)
				// 			element = this.activeInteractionElement // Assign new value

				// 		return { ... element }
				// 	})
				// }

			}).catch(error => console.error(error))
				.finally(() => {
					/* Disabled saving on panel */
					this.$store.dispatch('workspaceStore/togglePendingSaveState', false)
				})
		},

		async removeInteractionElement(){

			const { interaction: { elements } } = this.activeSegmentComponent

			if(!confirm('Tem certeza que deseja remover o elemento selecionado?'))
				return

			let indexOfElement = elements.findIndex(el => el.id === this.activeInteractionElement.id)

			/** No return value is expected. Assumes everything worked fine in the backend. */
			await this.$store.dispatch('workspaceStore/destroyInteractionElement',
				{
					segmentId: this.activeSegmentComponent.id,
					interactionId: this.activeSegmentComponent.interaction.id,
					elementId: this.activeInteractionElement.id
				})

			!Array.isArray(indexOfElement) ?
				this.activeSegmentComponent.interaction.elements.splice(indexOfElement, 1) :
				indexOfElement.forEach(index => this.activeSegmentComponent.interaction.elements.splice(index, 1))

			this.resetActiveInteractionElement()
		},

		resetActiveInteractionElement(){
			this.activeInteractionElement ={
				id: null,
				type: null,
				properties:{
					transform:{
						x: 0, y: 0,
						width: 0, height: 0
					},
					backgroundImg: ''
				},
				style: {
					x: 0, y: 0
				},
				attributes: {
					nextSegmentId: null,
					pairElementId: null
				}
			}
		},

		// Todo: update this method to avoid duplicated methods to change similar elements
		updateElementProperties(element){

			const { canSaveChanges } = this.uiState

			if(canSaveChanges === false){
				this.$store.dispatch('workspaceStore/togglePendingSaveState')
			}

			/** Container properties */
			let containerDOMRect = this.getContainerRect()

			/** DOM element properties */
			let elementDOM = document.getElementById(`element-${element.id}`)
			let elementDOMRect = elementDOM.getBoundingClientRect()

			const { interaction } = this.activeSegmentComponent
			if(interaction.elements.find(({ id }) => id === element.id))
			{

				/** Translate to local coordinates */
				element.properties.transform = {
					x : parseInt((elementDOMRect.x - containerDOMRect.x)),
					y :  parseInt((elementDOMRect.y - containerDOMRect.y)),
					width : elementDOMRect.width,
					height : elementDOMRect.height
				}

				element.style = {
					... element.style,
					x: parseInt((elementDOMRect.x - containerDOMRect.x)),
					y: parseInt((elementDOMRect.y - containerDOMRect.y))
				}

				this.$store.dispatch('workspaceStore/mutateDimensionScale',
					{
						dimensionScale: { width: parseInt(containerDOMRect.width), height: parseInt(containerDOMRect.height) }
					})
			}
		},

		getContainerRect()
		{
			let containerDOM = document.getElementById('interaction-container')
			return containerDOM.getBoundingClientRect()
		},

		/**
     * Handles overlapping elements event and coordinate snapping.
     * 
     * @param {{ element: object, snapToCoordinates: { x: number, y: number }}} event_
     */
		snapElement(event_)
		{
			let { element, snapToCoordinates } = event_
			const containerDOMRect = this.getContainerRect()

			let localX = (snapToCoordinates.x - containerDOMRect.x)
			let localY = (snapToCoordinates.y - containerDOMRect.y)

			element.properties.transform = {
				... element.properties.transform,
				x : localX, y : localY
			}
		},

		updateSubmitBtnProperties(){

			const { canSaveChanges } = this.uiState

			if(canSaveChanges === false){
				this.$store.dispatch('workspaceStore/togglePendingSaveState')
			}

			/** Container properties */
			let containerDOM = document.getElementById('interaction-container')
			let containerDOMRect = containerDOM.getBoundingClientRect()

			/** DOM element properties */
			let elementDOM = document.getElementById('subtm-btn__el')
			let elementDOMRect = elementDOM.getBoundingClientRect()

			/** Translate to local coordinates */
			this.interactionSubmitButton.style = {
				... this.interactionSubmitButton.style,
				x : (elementDOMRect.x - containerDOMRect.x),
				y : parseInt((elementDOMRect.y - containerDOMRect.y)),
				width : elementDOMRect.width,
				height : elementDOMRect.height
			}
		}
	
	//#endregion
	}
}
</script>

<style lang="scss">
@import '../../assets/scss/index.scss';

.interaction-view
{
  --panel-container: var(--clr-neutral-900);

  width: 100%; height: 100%;
  z-index: 3;
  padding: .25rem;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(12, 1fr);

  .top-center-container
  {
    grid-column: 1/13;
    display: grid;
    place-items: center;
  }

  .left-container
  {
    grid-column: 1/3;
    background: var(--panel-container);
  }

  .center-container
  {
    width: 100%; height: 100%;
    padding: 1rem;
    display: grid;
    grid-column: 3/11;
    place-items: center;
  }

  .right-container
  {
    grid-column: 11/13;
    background: var(var(--panel-container));

    &, *
    {
      /* width */
      &::-webkit-scrollbar
      {
        width: .75rem;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb
      {
        --scroll-color: var(--clr-neutral-1000);

        background: var(--scroll-color);
        border: .125rem solid var(--clr-neutral-800); border-radius: 1.25rem;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover
      {
        background: var(--clr-neutral-800);
      }
    }
  }
}

.prop_panel_btn
{
  border: none; border-radius: .25rem;
  padding: .25rem 1rem;
  width: 100%;
  margin-block: .25rem 1rem;
  background: var(--clr-neutral-600);
  color: var(--clr-neutral-100);

  &:hover
  {
    cursor: pointer;
    transition: ease-out 250ms;
    background: var(--clr-neutral-600);
  }

  &:disabled
  {
    cursor: no-drop;
    background: var(--clr-neutral-600);
  }
}

.top-toolbar
{
  width: 100%; height:100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: var(--clr-neutral-600);
}

.input_interaction-info-loop_time
{
  &::before
  {
    content: "s";
    top: 0; right: 0;
    position: absolute;
  }
}

input
{
  &::-webkit-outer-spin-button, &::-webkit-inner-spin-button
  {
    display: none;
  }
}
.container-heading__input
{
  padding: .25rem; margin: .5rem 0;
  border: none; border-radius: .25rem;

  background: var(--clr-neutral-600);

  &::placeholder
  {
    color: var(--clr-neutral-600);
  }
}

.preview-container
{
  position: relative;
  width: max-content; height: max-content;
  pointer-events: none;
  margin-bottom:  8rem; // Offsets to center (caused by overlapping layout, should fix later)


	.preview-interaction__overlay
	{
		z-index: 200;
	}

  .interaction-container
  { 
    position: absolute;
    width: calc(100%); height: calc(100% - 4rem);
    top: 50%; left: 50%;
    border: 2px dashed rgb(231, 103, 29);
    transform: translate(-50%, calc(-45%));

    .interaction-element-container
    {
      width: 100%; height: 100%;
      position: absolute;
      top: 0; left: 0;
      z-index: 100 !important;

      .interaction-element
      {
        position: absolute;
        z-index: auto;  
      }
    }

    .interaction-submit-btn
    {
      position: absolute;
      pointer-events: all !important;
      width: 100%; height: 100%;
      z-index: 999 !important;
      pointer-events: none;

      & > *
      {
        pointer-events: all !important;
      }
    }

    .interaction-bkg-overlay
    {
      z-index: 99;
      position: absolute;
      pointer-events: none !important;
      width: 100%; height: 100%;
    }
  }

  #preview-iframe
  {
    width: 100%; height: 100%;
    min-width: 53rem; min-height: 30rem;
    pointer-events: all; // Safe measure
  }
}

.fallback-container
{
  user-select: none;
  position: relative;
  width: 100%; height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
  .fallback-container-txt
  {
    color: var(--clr-neutral-600);
    font-weight: bold;
    position: absolute;
    z-index: 99999 !important;
  }

  .fallback-container-background
  {
    width: 100%; height: 100%;
    background: #1414142d;
    filter: blur(.5rem);
  }
}

.grid-2-col
{
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-auto-flow: row;
  gap: .5rem;
}

.side-panel__element-category-name
{
  font-size: var(--fs-400);
  margin-bottom: 1rem;
}

span
{
  padding: .125rem auto;

  &.side-panel__prop-category-name
  {
    font-size: var(--fs-200);
    color: var(--clr-neutral-200);
  }
}

.side-panel__input-wrapper
{
  display: inline-flex;
  justify-content: space-around;
}

input[type="color"]
{
  margin: 0.25rem auto !important;
}

.side-panel__input-wrapper
{
  display: flex; flex-direction: column;
  padding: .25rem 0;
}

.component-properties-container
{
  width: 100%; height: min-content;
  padding: .5rem; margin: 0 0 .5rem 0;
  overflow-y: scroll;
  background: var(--clr-neutral-900);
  text-align: start;
  border-radius: .25rem;

  .form-properties-data{
    min-height: 20rem;
    padding: 0 0 2.4rem 0;

    &.element-no-selected{
      color: gray;
    }
  }

  .interaction-info-loop-time-ms
  {
    display: flex; align-items: center;

    small
    {
      margin: 0 auto;
    }

    & > div.interaction-info-loop-time__wrapper
    {
      input
      {
        max-width: 10ch;
        background: $gray04;
        border: none; border-radius: .25rem;
        padding: .25rem .5rem .25rem;

        &.input-invalid-value
        {
          color: var(--clr-danger-400) !important;
          font-weight: bold;
        }
      }


      &:nth-child(1)
      {
        margin-right: 1rem ;
      }
    }
  }

  .element-info-time-container{
    display: inline-flex !important;
    align-items: end !important;

    .element-info-time-suffix{
      margin-inline: .5rem 0;
      white-space: nowrap;
    }
  }

  .element-info__sided{

    display: grid;
    flex-direction: row;
    flex-wrap: wrap;
    grid-template-columns: repeat(2, 50%);
    width: 100%;

    .element-info-transform__coord{
      display: flex;  
      flex-direction: column;

      margin-inline: .125rem;
    }

  }
  
  .a-form-input-container
  {
    width: 100%; height: 100%;
    display: flex; flex-direction: column;
    gap: .5rem;

    input
    {
      background: var(--clr-neutral-600);
      border: none; border-radius: .25rem;
      padding: .25rem .5rem;
      margin: .25rem 0;

      &.input-invalid-value
      {
        color: var(--clr-danger-400);
        font-weight: var(--fw-500);
      }
    }

    select
    {
      &.element-info-attr
      {
        margin: .5rem 0; padding: .24rem .5rem;
        border: none; border-radius: .25rem;

        option
        {
          &.element-info-attr-option
          {
            padding: .125rem .25rem;

            &.select-option-invalid
            {
              color: red !important;
              font-weight: bold;
            }
          }

          span.teste
          {
            background: url("https://images.unsplash.com/photo-1596854407944-bf87f6fdd49e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80" );
            position: absolute;
            z-index: 99999;
            width: 100%; height: 100%;
          }
        }
      }
    }

    .a-radio-group
    {
      width: 100%; height: 100%;
      display: flex; flex-direction: row;
      margin-top: 1rem;
    }
  }
}

.a-list-container
{
  width: 100%; height: 100%;
  padding: 0 1rem 0 .5rem;
  background: var(--clr-neutral-900);
  text-align: start;
  overflow: auto;
  border-radius: .25rem;

  .component-list-item-selected
  {
    border: .125rem solid var(--clr-neutral-800);
  }

  .container-heading
  {
    padding: .5rem;
    white-space: nowrap;
  }
}

.a-list-item
{
  display: inline-flex;
  width: 100%; height: 4rem;
  padding: .5rem 1rem; margin: .25rem;
  border: none; border-radius: .25rem;

  background: var(--clr-purple-300);
  color: var(--clr-neutral-100);
  overflow: hidden;

  &:hover
  {
    transition: ease-out 250ms;
    background: var(--clr-purple-400);
  }

  &:nth-child(1)
  {
    margin: 0 .25rem .25rem;
  }

  &:hover
  {
    cursor: pointer;
  }
}
</style>