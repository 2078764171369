import axios, { AxiosResponse } from 'axios'
import { ActionContext, MutationTree, ActionTree, GetterTree } from 'vuex'
import type { RootState, WorkspaceStoreState, IComponent, IComponentInteraction } from '../index.d'

import { ActionAPI } from '../../services/ActionAPI'
import { DashboardStoreState } from './dashboardStore'

type ContextMenuContext = 'workspace' | 'component' | 'workspaceInteraction' | 'workspaceInteractionElement'

const namespaced = true
const state: WorkspaceStoreState = {
	components: [],
	componentLines: [],
	interactionElements: [],
	dimensionScale: {},
	uiState: {
		vecAngle: 0,
		zoomLevel: 1,
		isLoading: false,
		canSaveChanges: false,
		modalIsOpen: false,
		contextMenu: {
			isOpen: false,
			context: 'workspace' || 'component' || 'workspaceInteraction' || 'workspaceInteractionElement',
			x: 0, y: 0
		}
	}
}
const getters: GetterTree<WorkspaceStoreState, RootState> = {

	/**
		* Get existing lines that match a component.
		* @return {Array<Object>}  Array of lines that are connected to a component.
		*/
	getLinesByComponentId: (state: WorkspaceStoreState) => (componentId: number) => {
		return state.componentLines.filter(line => line.from.id === componentId || line.to.id === componentId)
	},
	getDimensionScale: state => {
		return state.dimensionScale
	}
}
const mutations: MutationTree<WorkspaceStoreState> = {
	//#region UI state related
	/**
		* Toggles `isLoading` variable.
		* 
		* @param {state} state 
		* @param {boolean} value - Value to set the variable to. If a value is not provied
		*                          sets the inverse
		*/
	toggleLoadingState(state, value: boolean = !state.uiState.isLoading) {
		state.uiState.isLoading = value
	},

	/**
		* Toggles the state of the `context menu`
		* 
		* @param {state} state 
		* @param {boolean} value - Value to set the variable to. If a value is not provied
		*                          sets the inverse
		*/
	toggleContextMenu(state, value = !state.uiState.contextMenu.isOpen) {
		state.uiState.contextMenu.isOpen = value
	},

	setContextMenuContext(state, { context }: { context: ContextMenuContext }) {

		state.uiState.contextMenu.context = context
	},

	togglePendingSaveState(state, value = !state.uiState.canSaveChanges) {
		state.uiState.canSaveChanges = value
	},

	toggleModalIsOpen(state, value = !state.uiState.modalIsOpen) {
		state.uiState.modalIsOpen = value
	},
	//#endregion



	//#region Component related crud operations

	mutateDimensionScale(state, { dimensionScale }: { dimensionScale: {} }) {
		state.dimensionScale = dimensionScale
	},

	/**
		* Merges components in the `workspaceStore`.
		* 
		* @param {state} state - Vuex state 
		* @param {{components: Array<object>}} payload
		*/
	mergeIntoComponents(state, { components }: { components: Array<IComponent> }) {
		state.components = components
	},

	/**
		* Pushes a component into components array in `workspaceStore`.
		* 
		* @param {state} state - Vuex state.
		* @param {{component: object}} payload
		*/
	pushIntoComponents(state, { component }: { component: any }) {
		state.components.push(component)
	},

	/**
		* @see https://stackoverflow.com/questions/50422357/updating-object-in-array-with-vuex
		* 
		* @param {state} state 
		* @param {{ indexOfComponent: number, segmentComponent: object }} payload 
		*/
	mergeUpdateComponentSegment(state,
		{ indexOfComponent, segmentComponent }: { indexOfComponent: number, segmentComponent: any }) {

		let oldComponent = state.components.at(indexOfComponent)

		if (oldComponent !== undefined) {
			state.components = [
				...state.components.filter(component => component.id !== oldComponent?.id),
				segmentComponent
			]
		}
	},

	mergeUpdateComponentNextSegment(state,
		{ indexOfComponent, nextSegmentComponent }: { indexOfComponent: number, nextSegmentComponent: any }) {
		state.components[indexOfComponent].nextSegment = nextSegmentComponent
	},

	mergeUpdateComponentLine(state,
		{ indexOfLine, line }: { indexOfLine: number, line: any }) {
		state.componentLines[indexOfLine] = line
	},

	spliceComponent(state, { indexOfComponent }: { indexOfComponent: number }) {
		state.components.splice(indexOfComponent, 1)
	},

	spliceComponentLine(state, { indexOfLine }: { indexOfLine: number }) {
		state.componentLines.splice(indexOfLine, 1)
	},

	pushIntoComponentLines(state, { line }: { line: any }) {
		state.componentLines.push(line)
	},

	mergeUpdateInteraction(state, { interactionId, data }: { interactionId: number, data: any }) {
		let interactionIndex = state.components.findIndex(c =>
			'interaction' in c && c.interaction !== null && c.interaction.id === interactionId
		)

		if (interactionIndex && state.components[interactionIndex] !== null) {

			if (state.components[interactionIndex].interaction !== null) {
				Object.assign<IComponentInteraction, any>(state.components[interactionIndex].interaction as IComponentInteraction, { ...data })
			}
		}
	},

	mergeUpdateInteractionElements(state, { interactionId, elements }: { interactionId: number, elements: Array<any> }) {
		let interactionIndex = state.components.findIndex(c =>
			'interaction' in c && c.interaction !== null && c.interaction.id === interactionId
		)

		if (interactionIndex && state.components[interactionIndex] !== undefined) {
			// @ts-ignore
			state.components[interactionIndex].interaction.elements = [...elements]
		}
	},
	//#endregion

	//#region Cleanup routines

	clearComponents(state) {
		state.components = []
	},

	clearComponentLines(state) {
		state.componentLines = []
	},

	clearInteractionElements() {
		state.interactionElements = []
	}
	//#endregion
}
const actions: ActionTree<WorkspaceStoreState, RootState> = {

	async getProjectSegments({ dispatch }, { projectId }: { projectId: number }) {
		const response = await ActionAPI.SegmentNodeService.getNodesFromProject(projectId)
			.catch(requestError => { throw requestError })

		/** Todo: Add caching */
		dispatch('mergeIntoComponents', { components: [...response.data] })
	},

	async storeSegment(
		{ dispatch },
		{ projectId, data, options }: { projectId: number, data: any, options: { dragOffsetX: number, dragOffsetY: number } }) {
		/** Temporary solution to handle vimeo player unsuported url */
		const requestData = { ...data }

		/** @todo Grab projectid from store */
		const response = await ActionAPI.SegmentNodeService.storeNewNode(projectId, requestData)
			.catch(networkError => {

				const { status } = networkError?.response

				switch (status)
				{
					case 422:
						alert('Um ou mais dados inválidos ao criar segmento.\nVerifique se os dados foram preenchidos corretamente.')
						break
					default:
						alert('Erro inesperado ao criar segmento. Entre em contato com o suporte.')
						break
				}

				return false
			})

		if (response)
		{
			const { data: responseData } = response as AxiosResponse<any>

			/** Position element on (0,0) of the current viewport */
			const newComponent = {
				...responseData,
				screenCoordinates: {
					...responseData.screenCoordinates,
					x: options.dragOffsetX,
					y: options.dragOffsetY
				}
			}

			await dispatch('pushIntoComponents', { component: newComponent })

			alert('Segmento criado com sucesso')
		}
	},

	async updateSegment(
		{ state, dispatch },
		{ segmentId, data }: { segmentId: any, data: any }) {
		dispatch('toggleLoadingState', true)

		const segment = state.components.find(({ id }) => id == segmentId)

		if (segment == undefined) {
			throw Error('Unable to get handler to segment')
		}

		const requestData = { ...data, screenCoordinates: segment.screenCoordinates }

		const response = await ActionAPI.SegmentNodeService.updateNode(segmentId, requestData)

		dispatch('toggleLoadingState', false)

		if (response) {
			const { data: updatedSegment } = response
			dispatch('mergeUpdateComponentSegment', {
				indexOfComponent: state.components.indexOf(segment),
				segmentComponent: response.data
			})

			/* Updates other components 'isInitial' property */
			if (updatedSegment.isInitial === true) dispatch('syncInitialSegment', updatedSegment)

			return { status: 'success' }
		}

		return { status: 'error' }
	},

	async updateNextSegmentProp(
		{ state, commit },
		{ segmentId, nodeId }: { segmentId: number, nodeId: number }) {
		const response = await ActionAPI.SegmentNodeService.updateNodeEdges(segmentId, { nodeId: nodeId })

		if (response) {
			/** Prevent misposition by using the component current screen coordinates. */
			const staleComponent = state.components.find(comp => comp.id === segmentId)

			if (staleComponent == undefined) {
				throw Error('Unable to get handler to segment')
			}

			const updatedComponent = {
				...response.data,
				screenCoordinates: staleComponent.screenCoordinates
			}

			commit('mergeUpdateComponentSegment', {
				indexOfComponent: state.components.indexOf(staleComponent),
				segmentComponent: updatedComponent
			})

			return { status: 'success' }
		}

		console.warn('Erro ao conectar componentes')
		return { status: 'error' }
	},

	async unsetNextSegmentProp(
		{ state, dispatch },
		{ lineObj }: { lineObj: any }) {
		/** Displays action result then updates in the background.*/
		/** @todo Reinsert line in the array in case request goes wrong. */
		dispatch('spliceComponentLine', { indexOfLine: state.componentLines.findIndex(line => line === lineObj) })

		const parentNode = state.components.find(({ id }) => id === lineObj.from.id)

		if (parentNode == undefined || parentNode.id == null) {
			throw Error('Unable to get handler to segment')
		}

		const response = await ActionAPI.SegmentNodeService.unsetNodeEdge(parentNode.id, { nodeId: lineObj.to.id })

		if (response) {
			dispatch('mergeUpdateComponentNextSegment', {
				indexOfComponent: state.components.indexOf(parentNode),
				nextSegmentComponent: response.data.nextSegment
			})

			return { status: 'success' }
		}

		console.warn('Um erro ocorreu ao tentar remove o(s) node(s) do segmento')
		//// this.componentLines.push(fallback)
		return { status: 'error' }
	},

	async destroySegment(
		{ state, getters, commit },
		{ projectId, segmentId }: { projectId: number, segmentId: number }) {
		const segment = state.components.find(({ id }) => id === segmentId)

		if (segment == undefined) {
			throw Error('Unable to get handler to segment')
		}

		const response = await ActionAPI.SegmentNodeService.destroyNode(projectId, segmentId)

		if (response) {
			/** Delete component lines. Further analyze this behaviour
				*  to check if its possible for lines be reindexed during the process */
			const lines = [...getters.getLinesByComponentId(segmentId)]

			lines.forEach(line => commit('spliceComponentLine',
				{ indexOfLine: state.componentLines.indexOf(line) }))

			commit('spliceComponent', { indexOfComponent: state.components.indexOf(segment) })

			/** Maps just properties that need cascading to prevent overriding unsaved changed. */
			let updatedStateComponents = state.components.map(component => {

				const responseComponent = response.data.find(({ id }: { id: number }) => id === component.id)

				if (responseComponent) {
					return {
						...component,

						isInitial: responseComponent.isInitial,
						nextSegment: responseComponent.nextSegment
					}
				}
			})

			commit('mergeIntoComponents', { components: updatedStateComponents })

			return { status: 'success' }
		}

		return { status: 'error' }
	},

	/** Updates any component that is not the current target */
	async syncInitialSegment(
		{ state },
		initialSegment: any) {
		let tmpArray = state.components
		tmpArray.forEach(component =>

			component.id !== initialSegment.id && component.isInitial !== false ?
				component.isInitial = false : component.isInitial
		)

		state.components = [...tmpArray]
	},

	//#region API Requests

	/**
	* Sends request to delete a component.
	*
	* @param {*} context
	* @param {{projectId: {number}, componentId: {number}}} payload
	*
	* @returns {Promise}
	*/
	destroyComponent(
		context,
		{ projectId, componentId }: { projectId: number, componentId: number }) {
		return new Promise((resolve, reject) => {
			axios.post('/videos/segments/delete', {
				projectId: projectId,
				segmentId: componentId
			})
				.then(response => { resolve(response) })
				.catch(error => { reject(error) })
		})
	},

	/**
		* Updates the nextSegment property of a segment.
		* @param {*} context 
		* @param {{segmentId: {number}, nextSegmentId: {number}}} payload 
		* 
		* @deprecated
		* @returns {Promise<object>}  
		*/
	storeNewSegment(
		context,
		{ segmentId, nextSegmentId }: { segmentId: number, nextSegmentId: number }) {
		return new Promise((resolve, reject) => {
			axios.post('/videos/segments/next-segment/update', {
				segmentId: segmentId,
				nextSegmentId: nextSegmentId
			})
				.then(response => { resolve(response) })
				.catch(error => { reject(error) })
		})
	},

	async saveChanges(
		{ rootState, state, dispatch },
		{ view, viewData }: { view: string, viewData: any }) {

		const { currentProject } = rootState.dashboardStore as DashboardStoreState

		/** @region serialization validations  */
		// Omit validations and warnings when updating project - allows incomplete project to be saved

		// let nonInitialSegments = 0
		// let hasEmptyInteractions = false
		// state.components.forEach(({ isInitial, type, interaction }) => {

		// 	if (!isInitial) nonInitialSegments++

		// 	if (type == 'interactive' && interaction !== undefined) {

		// 		if (interaction !== null && !(interaction.elements.length > 0)) {
		// 			hasEmptyInteractions = true
		// 		}
		// 	}
		// })

		// // Is missing initial segment
		// if (state.components.length === nonInitialSegments) {
		// 	return { message: 'Projeto não possui segmento inicial.\nPor favor selecione um segmento como inicial.' }
		// }

		// if (hasEmptyInteractions) {
		// 	return { message: 'Projeto possui uma ou mais interações sem elementos.\nPor favor adiciona ao menos um elemento interativo por interação.' }
		// }

		/** @endregion serialization validations  */

		if (currentProject.code === null) {
			throw Error('Erro inesperado em \'Segmentos\':\nProjectId not found')
		}

		await ActionAPI
			.SegmentNodeService
			.batchUpdateNodes(currentProject.code, state.components)
			.catch(error => {

				throw Error(`Erro inesperado em 'Segmentos':\n${error}`)

				// What kinds of error can be returned here?
			})

		if(view === 'interaction')
		{
			await dispatch('batchUpdateInteraction', viewData)
				.catch(error => {

					throw Error(`Erro inesperado em 'Interações':\n${error}`)
					// What kinds of error can be returned here?
				})
		}

		return { message: 'Atualizado com sucesso' }
	},

	async storeBatchFluxogramData(
		{ state }: ActionContext<WorkspaceStoreState, any>,
		{ projectId, components }: { projectId: number, components: Array<any> }) {
		state.uiState.isLoading = true

		await ActionAPI.SegmentNodeService.batchUpdateNodes(projectId, components)
			.catch(error => { return { status: error } })
			.finally(() => state.uiState.isLoading = false)

		return { status: 'success' }
	},

	async storeNewInteraction(
		{ state }: ActionContext<WorkspaceStoreState, any>,
		{ segmentId, data }: { segmentId: number, data: any }) {
		const response = await ActionAPI.SegmentNodeService.storeInteraction(segmentId, data)

		if (response) {
			const indexOfSegment = state.components.findIndex(c => c.id === segmentId)

			const mergeInteractionObj = {
				...state.components[indexOfSegment],
				interaction: { ...response.data }
			}

			if (state.components[indexOfSegment].interaction !== null) {
				state.components[indexOfSegment].interaction = { ...response.data }
			}

			return mergeInteractionObj
		}

		/** @todo return error */
	},

	/**
		* Sends request to remove an interaction from a segment
		* @param {Vue} context 
		* @param {{ segmentId: number, interactionId: number }} payload
		* @returns {Promise<HttpResponse>} Deletion status
		*/
	async destroyInteraction(
		context: ActionContext<WorkspaceStoreState, any>,
		{ segmentId, interactionId }: { segmentId: number, interactionId: number }) {
		return await ActionAPI.SegmentNodeService.destroyInteraction(segmentId, interactionId)
	},

	async storeInteractionElement(
		context: ActionContext<WorkspaceStoreState, any>,
		{ segmentId, interactionId, data }: { segmentId: number, interactionId: number, data: any }) {

		return await ActionAPI.SegmentNodeService.storeInteractionElement(segmentId, interactionId, data)
	},

	async destroyInteractionElement(
		context: ActionContext<WorkspaceStoreState, any>,
		{ segmentId, interactionId, elementId }: { segmentId: number, interactionId: number, elementId: number }) {
		return await ActionAPI.SegmentNodeService.destroyInteractionElement(segmentId, interactionId, elementId)
	},

	async storeElementImage(
		context: ActionContext<WorkspaceStoreState, any>,
		{ segmentId, interactionId, elementId, formData }: {segmentId: number, interactionId: number, elementId: number, formData: any}) {
		return await ActionAPI.SegmentNodeService.storeElementImg(segmentId, interactionId, elementId, formData)
	},

	mutateDimensionScale(
		{ commit }: ActionContext<WorkspaceStoreState, any>,
		{ dimensionScale }: { dimensionScale: any }) {

		commit('mutateDimensionScale', { dimensionScale })
	},

	async batchUpdateInteraction(
		{ commit }: ActionContext<WorkspaceStoreState, any>,
		{ segmentId, interactionId, data }: { segmentId: number, interactionId: number, data: any }) {
		/** https://stackoverflow.com/questions/35612428/call-async-await-functions-in-parallel */
		const response = await Promise.all(
			[
				await ActionAPI.SegmentNodeService.updateInteraction(segmentId, interactionId, data),
				ActionAPI.SegmentNodeService.updateInteractionElements(segmentId, interactionId, data.elements)
			])
			.catch(error => { throw error })

		commit('mergeUpdateInteraction',
			{
				interactionId: interactionId,
				data: response[0].data
			})

		commit('mergeUpdateInteractionElements',
			{
				interactionId: interactionId,
				elements: [...response[1].data]
			})
	},

	/**
		* Fetchs a vimeo video metadata through their oembed api.
		* 
		* @param {state} context 
		* @param {{ url: string }} payload The component video url.
		* @returns {Promise<HttpResponse>} oEmbed metadata
		* 
		* @see https://developer.vimeo.com/api/oembed
		*/
	requestOEmbedMetadata(
		context: ActionContext<WorkspaceStoreState, any>,
		{ url }: { url: string }) {
		/** Does not accept 'video/manage' url pattern */
		return new Promise((resolve, reject) => {

			axios.get('https://vimeo.com/api/oembed.json',
				{
					params: { url: url },

					/** Clears workspace default hears to avoid CORS violation */
					headers: []
				})
				.then(response => { resolve(response) })
				.catch(error => { reject(error) })
		})
	},
	//#endregion

	//#region Mutation wrappers
	clearComponentLines(
		{ commit }: ActionContext<WorkspaceStoreState, any>
	) {
		commit('clearComponentLines')
	},

	resetState(
		{ commit }: ActionContext<WorkspaceStoreState, any>) {
		commit('clearComponents')
		commit('clearComponentLines')
		commit('clearInteractionElements')
	},

	toggleLoadingState(
		{ commit }: ActionContext<WorkspaceStoreState, any>
		, payload: boolean) {
		commit('toggleLoadingState', payload)
	},

	togglePendingSaveState(
		{ commit }: ActionContext<WorkspaceStoreState, any>,
		payload: boolean) {
		commit('togglePendingSaveState', payload)
	},

	toggleModalIsOpen({ commit }: ActionContext<WorkspaceStoreState, any>, payload: boolean) {
		commit('toggleModalIsOpen', payload)
	},

	mergeIntoComponents({ commit }: ActionContext<WorkspaceStoreState, any>, payload: any) {
		commit('mergeIntoComponents', payload)
	},

	mergeUpdateComponentSegment({ commit }: ActionContext<WorkspaceStoreState, any>, payload: any) {
		commit('mergeUpdateComponentSegment', payload)
	},

	mergeUpdateComponentNextSegment({ commit }: ActionContext<WorkspaceStoreState, any>, payload: any) {
		commit('mergeUpdateComponentNextSegment', payload)
	},

	mergeUpdateComponentLine({ commit }: ActionContext<WorkspaceStoreState, any>, { indexOfLine, line }: { indexOfLine: number, line: any }) {
		commit('mergeUpdateComponentLine', {
			indexOfLine: indexOfLine,
			line: line
		})
	},

	pushIntoComponents({ commit }: ActionContext<WorkspaceStoreState, any>, payload: any) {
		commit('pushIntoComponents', payload)
	},

	spliceComponentLine({ commit }: ActionContext<WorkspaceStoreState, any>, payload: any) {
		commit('spliceComponentLine', payload)
	},

	spliceComponent({ commit }: ActionContext<WorkspaceStoreState, any>, payload: any) {
		commit('spliceComponent', payload)
	},

	pushIntoComponentLines({ commit }: ActionContext<WorkspaceStoreState, any>, payload: any) {
		commit('pushIntoComponentLines', payload)
	},

	mergeUpdateInteractionElements(
		{ commit }: ActionContext<WorkspaceStoreState, any>,
		{ interactionId, elements }: { interactionId: number, elements: Array<any> }) {
		commit('mergeUpdateInteractionElements', {
			interactionId: interactionId,
			elements: elements
		})
	},
	//#endregion

	//#region Storage Routines

	storeLsComponents({ state }: ActionContext<WorkspaceStoreState, any>) {
		localStorage.setItem('componentsCoordinates', JSON.stringify(state.components))
	},

	getLsComponents() {
		let storedComponents = localStorage.getItem('componentsCoordinates')
		if (storedComponents) {
			return JSON.parse(storedComponents)
		}
		return []
	},

	/**
	* Regex to remove special characters and spaces from string.
	*
	* @param {string} string String to parse
	* @returns {string} String without special characters and spaces.
	*/
	parseSpecialCharacters(_, string: string) {
		return string.replace(/[\])}[{('",:/.+]/g, '')
	}
	//#endregion
}

export default {
	namespaced,
	state,
	getters,
	mutations,
	actions
}