import axios from 'axios'
// import fetchAdapter from '@vespaiach/axios-fetch-adapter'
//@ts-ignore
import httpAdapter from 'axios/lib/adapters/http'

type SupportedMIMETypes = 'application/json'
type SupportedContentTypes = SupportedMIMETypes

export type ActionAPIHeaders = {
	Accept: SupportedMIMETypes,
	'Content-Type': SupportedContentTypes,
	'Auth-API': string,
	Authorization ?: string
}

export const provider = axios.create({

	adapter: httpAdapter,
	baseURL: `${process.env.VUE_APP_API_BASE_URL}/api`,
	headers: {
		'Accept': 'application/json',
		'Content-type': 'application/json',
		'Access-Control-Allow-Origin': '*',

		'Auth-API': process.env.VUE_APP_ACTION_API_AUTH
	}
})