<template>
  <div class="a-file-info-footer">
    <p>Arquivo Selecionado: {{ currentProject }}</p>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
	name: 'actionFileInfoFooter',
	computed: {
		...mapState({
			currentProject: state => state.projects.currentProject.project_name
		})
	}
}
</script>

<style lang="scss">
.a-file-info-footer {
  position: absolute;
  width: 100%;
  height: auto;
  bottom: 0;
  border-bottom: 1px solid var(--border);
  background: var(--light-purple);
  color: #a1a3a5;
  text-align: start;
  z-index: 1000;
  padding: 0.5rem 1rem;
  transition: all ease-in-out 0.15s;
}
</style>
