<template>
  <act-container id="account-user-list" class="account-user-list">

    <h2 class="account__user-list-header">Usuários</h2>

    <br>

    <action-modal v-if="addUserToAccountModal" backgroundColor="var(--clr-neutral-900)"
    @onOverlayClick="toggleModal('addUserToAccountModal')">
      <add-user-to-account-form @email-sent="toggleModal('addUserToAccountModal')"/>
    </action-modal>

    <action-modal v-if="editAccountUserModal" backgroundColor="var(--clr-neutral-900)"
    @onOverlayClick="toggleModal('editAccountUserModal')">
      <edit-account-user-form :user="editAccountUser"/>
    </action-modal>

    <act-row style="align-items: center; margin-bottom: 1em;">
      <act-col :w="6">
        <ActionButton @click.native.prevent="toggleModal('addUserToAccountModal')">
          <template #content>Adicionar usuário</template>
        </ActionButton>    
      </act-col>
      <act-col :w="3">
        <ActionButton class="dashboard-view-btn" style="margin-start: 1rem"
        disabled
        @click.native="toggleFilterToolbar">
          <template #content>Filtrar</template>
        </ActionButton>
      </act-col>
    </act-row>

    <act-row class="account__user-list">
      <act-list-item class="account__user-list-item" v-for="(accountUser, accUserIndex) in accountUsersList" :key="accUserIndex">
        <act-col :w="6" style="width: 16ch;">
          <p>{{ accountUser.name }}</p>
        </act-col>

        <act-col :w="3">
          <act-chips style="min-width: 16ch; margin: 0 auto">
            {{ accountUser.role }}
          </act-chips>
        </act-col>

        <act-col :w="2" style="text-align: end; width: min-content;">

          <action-button style="width: 100%;"
						@click.native.prevent="toggleEditUserModal(accountUser)">
            <template v-slot:content>Editar</template>
          </action-button>

          <action-button style="width: 100%;"
						@click.native.prevent="$store.dispatch('accountStore/removeUserAccount', accountUser.id)">
            <template v-slot:content>Remover</template>
          </action-button>

        </act-col>
      </act-list-item>
    </act-row>

  </act-container>
</template>

<script>
import { ActionButton, ActionModal } from '@/components'
import { mapState, mapGetters } from 'vuex'

import AccountRolesMixin from './userSettings/AccountRolesMixin.vue'

import ActContainer from '@/components/layout/ActContainer.vue'
import ActRow from '@/components/layout/ActRow.vue'
import ActCol from '@/components/layout/ActCol.vue'
import ActListItem from '@/components/ActListItem.vue'
import ActChips from '@/components/ActChips.vue'
import AddUserToAccountForm from './userSettings/AddUserToAccountForm.vue'
import EditAccountUserForm from './userSettings/EditAccountUserForm.vue'

export default {
	name: 'AccountUserList',
	mixins: [AccountRolesMixin],
	components: { ActionButton, ActionModal, ActListItem, ActContainer, ActRow, ActCol, ActChips, AddUserToAccountForm, EditAccountUserForm },

	data()
	{
		return {

			addUserToAccountModal: false,
			editAccountUserModal: false,
			editAccountUser: null,

			selectedFormRole: 0
		}
	},

	computed:
	{
		...mapState({

			accountUsers: state => state.accountStore.accountUsers,
			uiState: state => state.workspaceStore.uiState
		}),

		...mapGetters('accountStore',['computedAccountUsers']),
		...mapGetters('authStore',['loggedInUser']),

		accountUsersList()
		{
			return this.computedAccountUsers.filter(user => user.id !== this.loggedInUser.id)
		}
	},

	async created()
	{
		await this.requestAccountUsers()
	},

	methods: {

		async requestAccountUsers()
		{
			await this.$store.dispatch('accountStore/getAccountUsers')
		},

		toggleModal(modal)
		{
			return this[modal] = !this[modal]
		},

		toggleEditUserModal(user = null)
		{
			if(user !== null)
			{
				this.editAccountUser = user
				this.toggleModal('editAccountUserModal')
			}
		}
	}

}
</script>

<style lang="scss" scoped>
.account-user_list__form
{
  & > .act-col
  {
    margin: .5em 0 !important;
  }
}
.account__user-list-header
{
  color: var(--clr-purple-300);
}
.account__user-list
{
  padding: 1em .5em;
  margin-top: 1em !important;
  border-radius: .5em;
	overflow-y: scroll;
	max-height: 28rem;
}
.account__user-list-item
{
  background-color: var(--clr-neutral-900);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  color: var(--clr-neutral-100);
  
  padding-block: 1em !important;
  margin: .25em 0 !important;
}
</style>