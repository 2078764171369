import videojs from 'video.js'
const Component = videojs.getComponent('Component')

import './interactionOverlayComponent.css'

export default class InteractionOverlayComponent extends Component {

	constructor(player, options) {
		super(player, options)

		if (this.el()) {
			videojs.on(player.el_, ['loadeddata', 'playerresize'], () => {

				/** Video element is actually wrapped by a videojs container */
				let d = this.videoDimensions(player.el_.firstChild)

				let hOffset = ((player.el_.firstChild.offsetHeight - d.height) / 2)
				let wOffset = ((player.el_.firstChild.offsetWidth - d.width) / 2)

				Object.assign<any, any>((<HTMLElement>this.el()).style, {

					'height': `${d.height}px`,
					'width': `${d.width}px`,
					'top': `${hOffset}px`,
					'left': `${wOffset}px`
				})
			})
		}
	}

	createEl() {
		return videojs.dom.createEl('div',
			{
				id: `vjs-act-intrc-overlay_${this.options_.id}`,
				className: 'vjs-act-intrc-overlay'
			})
	}

	/**
	 * Return video active area accouting for letter and pillar boxes.
	 * 
	 * @see https://nathanielpaulus.wordpress.com/2016/09/04/finding-the-true-dimensions-of-an-html5-videos-active-area/
	 * @param {HTMLVideoElement} video
	 * @return {object}
	 */
	videoDimensions(video) {

		/**  Ratio of the video's intrisic dimensions */
		let videoRatio = isNaN(video.videoWidth / video.videoHeight) ?
			0 : video.videoWidth / video.videoHeight

		/** The width and height of the video element */
		let width = video.offsetWidth, height = video.offsetHeight

		/** The ratio of the element's width to its height */
		let elementRatio = width / height

		/** If the video element is short and wide
		 *  It must be tall and thin, or exactly equal to the original ratio */
		elementRatio > videoRatio ?
			width = height * videoRatio : height = width / videoRatio

		return { width: parseInt(width), height: parseInt(height) }
	}
}

videojs.registerComponent('InteractionOverlayComponent', InteractionOverlayComponent)