<template>
  <form @submit.prevent="submitForm(form)"
  class="flex-vertical"
  style="gap: 1rem;">
    <h6>Adicionar usuário</h6>

    <action-input label="Email" type="email"
    required
    placeholder="usuário@email.com" v-model="form.email"/>

    <fieldset class="flex-vertical" style="gap: .5rem">
      <label for="account-user-role">Função do usuário</label>
      <select id="account-user-role" :selected="form.role_id" v-model="form.role_id">
        <option v-for="(role, roleIndex) in computedAccountRoles" :key="roleIndex"
        :value="role.value">{{ role.name }}
        </option>
      </select>
    </fieldset>

    <section>
      <button style="width: 100%">Enviar convite</button>
    </section>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import { ActionAPI } from '../../../../services/ActionAPI'
import AccountRolesMixin from './AccountRolesMixin.vue'
import ActionInput from '../../../../components/forms/ActionInput.vue'
export default {
	name: 'AddUserToAccountForm',
	mixins: [AccountRolesMixin],
	emits: ['email-sent'],
	components: { ActionInput },
	data()
	{
		return {
			form: {
				email: '',
				role_id: 2 // Editor
			}
		}
	},
	computed: {

		...mapGetters('accountStore', ['currentAccount'])
	},

	methods: {

		async submitForm({ email = '', roleId = null })
		{
			if(roleId == null)
			{
				roleId = this._editorRole.value
			}

			/** @todo Add loading spinner */

			const { id: accountId } = this.currentAccount

			/** @todo Add email sent confirmation or validation error */
			const sent = await ActionAPI.UserService.sendAccountInvitationEmail(accountId, email, roleId)
				.catch(error => {

					if(error.status == 404)
					{
						alert(`Ocorreu um erro ao enviar um convite da sua conta para ${email}`)
					}

					return false
				})

			this.$emit('email-sent', !!sent)
		}
	}
}
</script>

<style lang="scss">

</style>