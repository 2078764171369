<template>
  <act-container>
    <action-modal id="modal-playlist-info" v-if="playlistForm.isOpen"
    @onOverlayClick="toggleModal(playlistForm)">

      <act-form>
        <p>Informações da playlist</p>

        <action-input
        id="playlist-form__name"        
        type="text"
        label="Nome"
        v-model="playlistForm.name"
        style="margin-block: 1em 2em;"
        />

        <action-button v-if="playlistForm.isEditing"
          @click.native.prevent="updatePlaylistDetails()">
          <template #content>Salvar</template>
        </action-button>

        <template v-else>
          <action-button
            @click.native.prevent="storePlaylist()">
            <template #content>Salvar</template>
          </action-button>
        </template>
      </act-form>
    </action-modal>

    <act-search-bar style="margin-block: 1em; color: white" v-model="playlistStringFilter"/>

    <act-row style="margin-block: 1em;">
      <action-button
      @click.native.prevent="openPlaylistCreationForm()">
        <template #content>Nova playlist</template>
      </action-button>
    </act-row>

    <act-row>
      <act-col flex class="act-card__playlist" :w="3" v-for="(playlist, playlistIndex) in computedPlaylists" :key="playlistIndex">

        <act-card>

          <template v-slot:img>
            <div @click.stop.prevent="openPlaylist(playlistIndex)"
              class="act-card__playlist-img">
              <img v-if="'poster' in playlist && playlist.poster !== null" :src="playlist.poster">
              <img v-else src="~@/../public/static/folder_img_placeholder.png">
            </div>
          </template>

          <template v-slot:title>
            <div class="content__top_row">
              <small class="content__top_row--title">{{ playlist.name }}</small>
              <span class="material-symbols-outlined content__options_vert"
              title="Mais opções"
              @click.capture="toggleGroupDropdown(playlist.hash)">
                more_vert
              </span>

              <act-dropdown v-if="isPlaylistDropdownOpen && playlistDropdownId === playlist.hash"
                color="#c88bff"
                style="top: 100%; left: 90%;">
                <ul>
                  <act-dropdown-item
                    v-for="(option, optionIndex) in playlistDropdownOptions"
                    :key="optionIndex"
                    @click.native="handleDropdownOption(option, playlist)"
                    >
                    {{ option }}
                  </act-dropdown-item>
                </ul>
              </act-dropdown>
            </div>
          </template>

          <template v-slot:text>
            <small>{{ playlist.videos.length }} video{{ playlist.videos.length > 1 ? 's' : '' }}</small>
            <br>
            <small class="act-card__relative-time">Atualizado {{ toRelativeTimeString(playlist.display_updated_at) }}</small>                  
          </template>
            
        </act-card>
      </act-col>
    </act-row>
    
  </act-container>
</template>

<script>

import TimeUtils from '@/classes/TimeUtils'

import { ActionButton, ActionInput, ActionModal } from '@/components'
import ActForm from '@/components/forms/actForm.vue'
import ActContainer from '@/components/layout/ActContainer.vue'
import ActRow from '@/components/layout/ActRow.vue'
import ActCol from '@/components/layout/ActCol.vue'
import ActCard from '@/components/actCard/actCard.vue'
import ActDropdown from '@/components/actDropdown/index'
import ActDropdownItem from '@/components/actDropdown/item/actDropdownItem.vue'
import ActSearchBar from '@/components/ActSearchBar.vue'

import { mapGetters, mapState } from 'vuex'

export default {
	name: 'dashboard-playlists-list-view',
	components: { ActionButton, ActContainer, ActRow, ActCol, ActCard, ActionInput, ActForm, ActionModal, ActDropdown, ActDropdownItem, ActSearchBar },

	data()
	{
		return {
			playlistStringFilter: '',

			playlistDropdownId: null,
			isPlaylistDropdownOpen: true,
			playlistDropdownOptions: [ 'Editar', 'Duplicar', 'Remover' ],

			isViewingFolder: false,

			selectedPlaylistIndex: 0,
			playlistForm: {
				isEditing: false,
				isOpen: false,
				id: null,
				hash: null,
				name: null
			}
		}
	},

	computed: {
		...mapState({

			uiState: state => state.workspaceStore.uiState
		}),

		...mapGetters('dashboardStore',['playlists', 'videoProjects']),

		currentNavigationLevel()
		{
			return this.navigationLevels.at(-1)
		},

		computedSelectedGroupVideos()
		{
			return this.playlists.at(this.selectedPlaylistIndex).videos
		},

		currentPlaylist()
		{
			return this.playlists.at(this.selectedPlaylistIndex)
		},

		/** @todo Filter out by other fields
     *  @todo High matching filter: Allow to query for words based on strength or queryied matches */
		computedPlaylists()
		{
			return this.playlists.filter(({ name }) => name.toLowerCase().includes(this.playlistStringFilter.toLowerCase()))
		}
	},
  
	methods: {

		openPlaylist(groupIndex)
		{
			this.selectedPlaylistIndex = groupIndex

			/** Expects to come from false */
			// this.toggleIsViewingFolder()
      
			this.$router.push(`playlists/${this.currentPlaylist.hash}`)
		},

		openPlaylistCreationForm()
		{
			this.playlistForm = Object.assign(this.playlistForm, { isEditing: false })

			this.toggleModal(this.playlistForm)
		},

		/**
     * Toggles an specifcied modal component.
     * 
     * @param {object} modal
     */
		toggleModal(modal)
		{
			modal.isOpen = !modal.isOpen

			/**
     * Clears a form input field values.
     * @param {Object} formToClear - Form to be cleared.
     */
			function clearForm(formToClear){
				Object.keys(formToClear).forEach(key => {

					if(key !== 'isOpen') formToClear[key] = null
				})
			}

			// If the toggle action closes it, also clear the form
			if(modal.isOpen === false)
				clearForm((modal))
		},

		toRelativeTimeString({ t, unit })
		{
			return TimeUtils.toRelativeTimeString(t, unit).toLowerCase()
		},

		async storePlaylist()
		{
			/** @todo Add validations */
			if(this.playlistForm.name === null || this.playlistForm.name === '')
			{
				return confirm('Preencha todos os campos')
			}

			const action = await this.$store.dispatch('dashboardStore/storePlaylist',
				{
					playlistData: this.playlistForm
				})

			this.toggleModal(this.playlistForm)

			if('status' in action && action.status === 'error')
			{
				return console.error('An unexpected error occurred while creating the playlist')
			}
		},

		async updatePlaylistDetails()
		{
			const playlistId = this.playlistForm.hash

			const action = await this.$store.dispatch('dashboardStore/updatePlaylist',
				{
					playlistId: playlistId,
					playlistDetails: this.playlistForm
				})

			this.toggleModal(this.playlistForm)

			if('status' in action && action.status.includes('error'))
			{
				return console.error('An unexpected error occurred while updating the playlist')
			}
		},

		async duplicatePlaylist({ hash })
		{
			const action = this.$store.dispatch('dashboardStore/duplicatePlaylist',
				{
					playlistId: hash
				})

			if('status' in action && action.status === 'error')
			{
				return console.error('Um erro ocorreu ao tentar duplicar a playlist')
			}
		},

		async destroyPlaylist({ hash, name })
		{
			if(!confirm(`Tem certeza que deseja remover a playlist "${name}"?`))
			{
				return
			}

			const action = await this.$store.dispatch('dashboardStore/destroyPlaylist', { playlistId: hash })

			if('status' in action && action.status.includes('error'))
			{
				return console.error('An unexpected error occurred while removing the playlist')
			}
		},

		toggleGroupDropdown(playlistId = null, optionalValue = null)
		{
			if(playlistId !== null)
			{
				if(playlistId !== this.playlistDropdownId)
				{
					optionalValue = true
				}

				this.playlistDropdownId = playlistId
			}

			this.isPlaylistDropdownOpen = optionalValue !== null ? optionalValue : !this.isPlaylistDropdownOpen
		},

		/** @todo Adicionar funções de crud dos vídeos na playlist */

		handleDropdownOption(option, data)
		{
			/** This function should be responsible for executing a callback maped to a string,
       *  then close the dropdown. If the closing of the modal always happens, then it
       *  probably should be integrated into the component behaviour by default. */
			if(option === null)
				return

			switch(option)
			{
				case 'Editar':
					this.openPlaylistEditForm(data)
					break

				case 'Duplicar': this.duplicatePlaylist(data)
					break

				case 'Remover': this.destroyPlaylist(data)
					break
			}

			this.toggleGroupDropdown(null, false)
		},

		openPlaylistEditForm(selectedPlaylistData)
		{
			this.playlistForm = Object.assign(this.playlistForm, selectedPlaylistData)
			this.playlistForm = Object.assign(this.playlistForm, { isEditing: true })

			this.toggleModal(this.playlistForm)
		}
	}
}
</script>

<style lang="scss">
#modal-playlist-info
{
  .act-modal
  {
    background-color: var(--clr-neutral-800) !important;
    color: var(--clr-neutral-200);
  }
}
</style>
<style lang="scss" scoped>

.act-card__playlist
{
  color: var(--clr-neutral-100);
  max-width: 12em;

  &:nth-child(1)
  {
    margin-inline-start: 0;
  }

  &:last-child()
  {
    margin-inline-end: 0;
  }
}
.act-card__playlist-img
{
  padding: 0 1.3em;
  img
  {
    width: 100%; height: 100%;
  }
}

.__horizontal-video-card
{
  margin: 0.25rem !important;
  min-height: 8rem;
  display: flex;
  flex-direction: row;
}

.act-card__thumb
{
  width: 100%; height: 60%;
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: auto;
  overflow: hidden !important;
  cursor: pointer;

  position: relative;
}

.act-card__thumb-poster
{
  width: 100%; height: 100%;
}

.act-card__content
{
  padding: .5rem;
  display: flex;
  flex-direction: column;
  color: var(--clr-neutral-100);
}
.act-card__relative-time
{
  color: var(--clr-neutral-100);
  font-weight: 400;
  font-style: oblique;
}

.content__options_vert
{
  user-select: none;

  color: var(--clr-neutral-100);
  font-size: 1.6rem;
  padding: .125rem;

  &:hover
  {
    background: var(--clr-neutral-900);
  }
}

.content__top_row
{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: .5rem;
}

.content__top_row--title
{
  display: inline-block !important;
  font-size: .85em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.material-symbols-outlined
{
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}

.list-item-dropdown
{
  position: absolute;
  top: 100%; left: 5%;
  margin-top: .25rem;
  min-width: 6rem;
  width: 100%; height: 100%;
  border-radius: .25rem;
  z-index: 12;

  .list-item-li
  {
    &:nth-child(1)
    {
      border-radius: .25rem .25rem 0 0;
    }
  }
}
</style>