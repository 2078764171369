<template>
  <option class="act-dropdown-item"
  :value="value">
    <slot></slot>
  </option>
</template>

<script>
export default {
	name: 'ActDropdownItem',

	props: {

		value: {
			required: false
		}
	}

}
</script>

<style lang="scss" scoped>

.act-dropdown-item
{
}

</style>