<template>
  <div id="a-context-menu" class="a-context-menu" :style="`top: ${this.y}px; left: ${this.x}px;`">
    <slot></slot>
  </div>
</template>

<script>
export default {
	name: 'ContextMenu',
	props:{
		x: {
			type: Number,
			required: true
		},
		y: {
			type: Number,
			required: true
		}
	}
}
</script>

<style lang="scss" scoped>

.a-context-menu{
  position: absolute;
  width: min-content;
  height: min-content;
  z-index: 9000 !important;
  margin: -6rem .25rem; // toolbar height offset
  padding: .125rem;
  box-shadow: 0 .25rem 1rem 0 rgba(29, 32, 44, .5);
  border-radius: .25rem;

  .a-context-menu-btn{
    display: block;
    width: 100% !important;
    text-align: start;
    white-space: nowrap;
    padding: 0 1rem 0 2rem;
    background: transparent !important;
    
    color: white;
    font-family: 'Fira Code', monospace !important;

    border: none !important;
    &:first-child{
      border-top-left-radius: .25rem !important;
      border-top-right-radius: .25rem !important;
    }
    &:last-child
    {
      border-bottom-left-radius: .25rem !important;
      border-bottom-right-radius: .25rem !important;
    }

    &:hover{
      cursor: pointer;
    }
  }
}
</style>