<template>
  <act-container id="dashboard-playlist">

    <act-row>
      <act-col :w="12" class="dash-playlist__header">
        <h6 style="color: w">Listas de reprodução</h6>
      </act-col>
    </act-row>

    <router-view></router-view>

  </act-container>
</template>

<script>
import ActContainer from '@/components/layout/ActContainer.vue'
import ActRow from '@/components/layout/ActRow.vue'
import ActCol from '@/components/layout/ActCol.vue'

import { mapGetters, mapState } from 'vuex'

export default {
	name: 'DashboardPlaylist',
	components: { ActContainer, ActRow, ActCol },


	data() {
		return {

			playlistStringFilter: '',

			playlistDropdownId: null,
			isPlaylistDropdownOpen: true,
			playlistDropdownOptions: [ 'Editar', 'Duplicar', 'Remover' ],

			isViewingFolder: false,
			isInVideoSelectionView: false,

			selectedPlaylistIndex: 0
		}
	},

	computed: {
		...mapState({

			uiState: state => state.workspaceStore.uiState
		}),

		...mapGetters('dashboardStore',['playlists', 'videoProjects']),

		currentNavigationLevel()
		{
			return this.navigationLevels.at(-1)
		},

		computedSelectedGroupVideos()
		{
			return this.playlists.at(this.selectedPlaylistIndex).videos
		},

		/** @todo Filter out by other fields
     *  @todo High matching filter: Allow to query for words based on strength or queryied matches */
		computedPlaylists()
		{
			return this.playlists.filter(({ name }) => name.toLowerCase().includes(this.playlistStringFilter.toLowerCase()))
		}

	},

	async created()
	{
		this.$store.dispatch('dashboardStore/getPlaylists', { clientId: 1 })
	},

	methods: {


		openPlaylistEditForm(selectedPlaylistData)
		{
			this.playlistForm = Object.assign(this.playlistForm, selectedPlaylistData)
			this.playlistForm = Object.assign(this.playlistForm, { isEditing: true })

			this.toggleModal(this.playlistForm)
		},

		//#region UI actions

		displayVideoSelectionModal()
		{
			// Mostrar modal exibindo os vídeos que correspodem ao critério de seleção i.e 'Completos'
			this.toggleVideoSelectionView()
		},

		/** @todo Display depth navigation levels like when opening a folder */
		displayNavigation()
		{

		}

		//#endregion
	}

}
</script>

<style lang="scss" scoped>
.dash-playlist__header
{
  color: clr(act_purple);
}
.act-search-input
{
  color: white;

  input
  {
    border: 2px solid var(--clr-neutral-900) !important;
  }
  
  label
  {
    color: var(--clr-purple-200) !important;
  }
}
</style>