<template>
  <div :class="classes">
    <slot></slot>
  </div>
</template>

<script>
export default {
	name: 'act-container',

	props: {

		fluid: {
			type: Boolean,
			default: false
		}
	},

	computed: {

		classes()
		{
			return {
				'act-container' : true,
				'act-container__fluid' : this.fluid
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.act-container
{
  width: 100%; height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.act-container__fluid
{
  width: 100%;
}

</style>