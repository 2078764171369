<template>
  <div :class="classes" :style="style">
    <slot></slot>
  </div>
</template>

<script>
export default {
	name: 'act-row',

	props: {

		'nowrap': {
			type: Boolean,
			default: false
		},

		gap: {
			type: Number,
			default: 0
		},

		justify: {

			type: String,
			default: 'flex-start',
			validator: (value) => {

				return ['flex-start', 'center', 'flex-end', 'space-between', 'space-around'].includes(value)
			}
		}

	},

	computed: {

		classes()
		{
			return {
				'act-row' : true,
				[`act-row__gap-${this.gap}`]: true,
				'__no-wrap' : this.nowrap
			}
		},

		style()
		{
			return  {
				'justify-content': this.justify
			}
		}
	}
}
</script>

<style lang="scss" scoped>
@import '../../styles/scss/mixins.scss';

.act-row
{
  @include flex;

  width: 100%;
  flex-wrap: wrap;
  position: relative;
}

.__no-wrap
{
  flex-wrap: nowrap !important;
}

@for $i from 0 through 4
{
  .act-row__gap-#{$i}
  {
    & > *
    {
      margin-inline: #{$i * 0.25}em;
    }
  }
}
</style>