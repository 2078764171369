export default class TimeUtils {


	constructor() {
		//
	}


	/**
	 * Returns a user friendly string representation from a specified time and unit.
	 * @returns string
	 */
	static toRelativeTimeString(time: number, unit: string) {
		if (unit === 'dia' && time === 0) {
			return 'Hoje'
		}

		if (unit === 'dia' && time === 1) {
			return 'Ontem'
		}

		if (unit === 'dia' && time >= 2 || unit === 'semana' && time === 0) {
			return 'Essa semana'
		}

		if (unit === 'semana' && time === 1) {
			return 'Semana passada'
		}

		//#endregion

		let timeDisplayString = time
		let unitDisplayString = unit

		/** Pluraliza a unidade antes de retornar. **/
		if (time > 1) {
			unitDisplayString = unit === 'mês' ? 'meses' : `${unitDisplayString}s`
		}

		return `Há ${timeDisplayString} ${unitDisplayString}`
	}
}