<template>
  <div id="login" class="login-view">
    <div class="login-form-wrapper">

      <ActionMultistepForm class="login-form"
      ref="loginMultistepForm"
      :goBackButtonContent.sync="loginForm.email"
      :stepValidation="true"
      :validationRule="() => validateFormFields()"
      @nextStep="updateCurrentFormStep($event)"
      @previousStep="updateCurrentFormStep($event)">

        <template #form-header>
          <div class="login-form-header">
            <div class="action-heading">
              <div class="action-logo-wrapper">
                <img class="action-logo" src="../assets/ui/L02-BW.svg" alt="Action video">
                <span>Action</span>
              </div>
              <h6 style="color: #624CAB; margin-bottom: .5rem;">Fazer Login</h6>
              
              <small style="color: #624CAB;">Insira os dados da sua conta</small>
            </div>

          </div>
        </template>

        <template #step-1>
            <ActionInput id="login-input-email" name="login-input-email"
            title="Email"
            autofocus required
            v-model="loginForm.email"
            type="email"
            labelPlaceholder="Email"
            />

        </template>

        <template #step-2>
          <ActionInput id="login-input-password" name="login-input-password"
          autofocus required
          v-model="loginForm.password"
          type="password"
          labelPlaceholder="Senha"
          />

          <div class="forgot-password login-interaction-container">
            <a @click.prevent="retrivePassword">
              <span> Esqueceu sua senha? </span>
            </a>

            <ActionButton
            block
            class="login-form-btn"
            style="text-align: center;"
            @click.native="$store.dispatch('submitForm', {
              formData: loginForm,
              callbackFn: login
            })">
            <template #content>Entrar</template>
            </ActionButton>

          </div>
        </template>

        <template #nextStepTrigger></template>
        
      </ActionMultistepForm>
    </div>

    <auth-background/>
  </div>
</template>

<script>
import { ActionButton, ActionInput } from '../components'
import ActionMultistepForm from '../components/ActionMultistepForm.vue'
import AuthBackground from '../components/AuthBackground.vue'
export default {
	name: 'LoginView',
	components: { ActionButton, ActionMultistepForm, ActionInput, AuthBackground },
	data(){
		return {
			loginForm: {
				email: '',
				password: ''
			},

			/** Multistep form implementation */
			currentFormStep: 0
		}
	},
	methods: {
    
		/**
     * Calls API method to log user and stores credentials locally.
     * If login is sucessfull, redirects to `Dashboard view`.
     * 
     * @param {{ email: string, password: string}} loginFormData
     * 
     * @todo Data validation
     */
		async login({ email, password })
		{
			const action = await this.$store.dispatch('authStore/Login', { email: email, password: password })

			if(action.status === 'Success')
				return this.$router.push({ path: '/dashboard' })

			alert('Senha ou email incorretos.')
			this.$refs['loginMultistepForm'].$el.dispatchEvent(new CustomEvent('gotoStep', { detail: { stepNum: 1 }}))
		},

		/** Interactis with multistep form implementation through event listerners */
		updateCurrentFormStep(event)
		{
			this.currentFormStep = event.currentStep
		},

		validateFormFields()
		{
			/** Email validations */
			return this.currentFormStep >= 0 && this.loginForm.email.length === 0 ? false : true

			/** Validation not required since form contains only two steps */
			// if(this.currentFormStep >= 1 && this.loginForm.password === '') return false
		},

		async retrivePassword()
		{
			/** @todo Add email validation */
			await this.$store.dispatch('authStore/retrivePassword', this.loginForm?.email)
		}
	}
}
</script>

<style lang="scss">
.login-view
{
  width: 100%; height: 100%;
  display: grid;
  place-items: center;

  .login-bkg-overlay
  {
    width: 100%; height: 100%;
    position: absolute;
    z-index: 0;

    background-color: var(--clr-neutral-900) !important;
    background: url('../assets/wallpapers/retro-pattern.webp');
    background-repeat: repeat;
    background-blend-mode: hard-light;
    filter: grayscale(1);

    &::after
    {
      content: "";
      width: 100%; height: 100%;
      background: rgba(0, 0, 0, .6);
      position: absolute;
      z-index: 2;
    }
  }

  .login-form-wrapper
  {
    z-index: 300;
    width: auto; min-height: 50%;
    min-width: 25%;
    display: flex;
    justify-content: center;

    .login-form-header
    {
      width: 100%; height: 100%;
      text-align: center;

      .action-heading
      {
        position: relative;
        width: 100%; height: 100%;
        color: var(--clr-purple-400);

        .action-logo-wrapper
        {
          display: grid;
          place-items: center;

          .action-logo
          {
            width: 100%; max-width: 4rem;
            filter: hue-rotate(45deg) brightness(3.9);
            margin-block: 2rem .5rem;
          }
        }
      }

      .form-data-preview
      {
        height: 1rem;

        .login-form-email-preview
        {
          margin: 1rem 0;
          
          color: var(--clr-purple-400);

          &:hover
          {
            transition: ease-in .125s;
            color: var(--clr-purple-100);
          }
        }
      }
    }

    .login-form{
      width: 100%;
      display: flex;     
      flex-direction: column;
      padding: 1rem;
      border-radius: .5rem;
      background: var(--gradient-neutral-dark);

      label
      {
        color: var(--clr-purple-100);
      }

      a {
        text-decoration: none;

        &.login-form-link
        {
          &:hover
          {
            transition: .125s ease-in-out;
            color: var(--clr-purple-100);
          }

          &:disabled
          {
            color: var(--clr-neutral-200);
          }
        }
      }

      .login-interaction-container
      {
        display: grid;
        place-items: center;
        width: 100%; height: 100%;

        & > *
        {
          padding: .25rem; margin: .25rem 0;
          
          &:nth-child(1)
          {
            width: 100%;
            text-align: center;
          }
        }
      }

      input
      {
        &.login-form-input
        {
          width: 100%;
          padding: .5rem 1rem; margin: .5rem 0;
          border: none; border-radius: .25rem;
          font-weight: bold;

          background: var(--clr-neutral-900);
          color: var(--clr-neutral-200);

          &:focus
          {
            transition: .125s ease-in;
            outline: none;
            box-shadow: inset -.125rem .125rem .5rem 0 rgba(#100D1C, .25);

            color: var(--clr-neutral-200);
          }
      }
    }

      .login-form-btn
      {
        background: var(--clr-purple-100);
        color: var(--clr-neutral-100);
        max-width: 64ch;
      }
    }
  }

  .forgot-password
  {
    gap: .5rem; // used alongside grid

    a > span
    {
      cursor: pointer;
      color: var(--clr-purple-100);
    }
  }

  .previous-step-btn, .next-step-btn
  {
    color: var(--clr-neutral-100);
  }

  .previous-step-btn
  {
    border: 1px solid var(--clr-purple-200);
    background: rgba(0, 0, 0, .1);
    border-radius: 1rem;
  }

  .next-step-btn
  {
    background: var(--clr-purple-200);
  }
}
</style>
