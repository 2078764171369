import videojs, { VideoJsPlayer } from 'video.js'
import { videoDimensions } from '../../../utils'
import './InteractionElementComponent.css'
import { IBaseElementState, IBaseElementOptions } from '../types'

const Component = videojs.getComponent('Component')

export abstract class InteractionElementComponent extends Component {

	state: IBaseElementState
	videojs: typeof videojs

	setState(_state: Partial<IBaseElementState>): void {
		Object.assign<
			IBaseElementState,
			Partial<IBaseElementState>>(this.state, _state)
	}

	// Todo: add styling options feature, like images, etc
	constructor(player: VideoJsPlayer, options: IBaseElementOptions) {
		super(player, options)

		this.videojs = videojs

		this.state = {
			id: options._id,
			scale: options.scale,
			isVisible: false,
			pointerId: 0,
			isSelected: false,
			isDragging: false,

			elDOMRect: null,
			mousePos: null
		}

		if (this.el()) {
			videojs.on(player.el(), 'playerresize', () => this.handleResize())

			/** Since SubmitButton also inherits from this class,
			 * prevents events listener from being added to it. */
			if ('name' in options && options.name !== 'SubmitButtonComponent') {
				videojs.on(this.el(), 'click', () =>
					player.el().dispatchEvent(new CustomEvent('click',
						{ detail: { el: this.el(), state: this.state } })))

				videojs.on(player.el(), 'timeupdate', () => {

					let ct = this.player_.currentTime()

					if (ct >= options.start_time_s && this.state.isVisible === false) {
						this.toggleVisibility(true)
					}

					/** Fixes undesired visibilty behaviour on loop */
					// @ts-ignore Value changed at runtime
					if (ct < options.start_time_s && this.state.isVisible === true) {
						this.toggleVisibility(false)
					}
				})

				/** Called after interaction has cycled through once.
					* Makes all elements visible but unchecked. */
				videojs.on(player.el(), 'resetstate', () => this.resetState())
			}
		}
	}

	/**
	 * "(...) gives you ability to merge styles, instead of rewriting the CSS style."
	 * 
	 * @see https://stackoverflow.com/a/34490573/18805200
	 */
	handleResize() {
		if (this.el()) {
			const id_ = this.player_.id()

			/** @ts-ignore */
			const techName_ = this.player_.techName_.toLocaleLowerCase() // hotfix for bug on videojs tech attribute

			const playerID = `${id_}_${techName_}_api`.toLowerCase()

			const dimensions = videoDimensions(document.getElementById(playerID))
			let scale = {
				original: (<IBaseElementOptions>this.options_).scale,
				dynamic: dimensions
			}

			const { screen_x, screen_y, width, height } = this.options_ as IBaseElementOptions

			const polyfill = {
				width: width, height: height,
				x: screen_x, y: screen_y
			}

			const el_ = this.el() as HTMLElement

			Object.assign(el_.style, this.rescale(polyfill, scale))
		}
	}

	rescale(props: any, scale: { original: any, dynamic: any }) {
		let { original, dynamic } = scale

		let sX = 0, sY = 0, sW = 0, sH = 0

		if (dynamic) {
			/** Scaling */
			sW = ((props.width * dynamic.width) / original.width)
			sH = ((props.height * dynamic.height) / original.height)

			sX = ((props.x * dynamic.width) / original.width)
			sY = ((props.y * dynamic.height) / original.height)

			if ((sX + sW) >= dynamic.width)
				sX = (dynamic.width - sW)

			if (sY <= 0)
				sX = 0

			if ((sY + sH) >= dynamic.height)
				sY = dynamic.height - sH

			if (sY <= 0)
				sY = 0
		}

		return {
			top: `${sY.toFixed(2)}px`,
			left: `${sX.toFixed(2)}px`,
			width: `${sW.toFixed(2)}px`,
			height: `${sH.toFixed(2)}px`
		}
	}

	toggleVisibility(value = !this.state.isVisible) {
		this.setState({ isVisible: value })

		let hasClass = [... this.el().classList].includes('vjs-act-overlay__el__visible')

		if (value === true && hasClass)
			return

		else if (value === false || !hasClass) {
			value === true &&

				value === true ?
				this.el().classList.add('vjs-act-overlay__el__visible') :
				this.el().classList.remove('vjs-act-overlay__el__visible')
		}
	}

	/** @virtual */
	resetState() {
		this.toggleVisibility(false)

		this.handleResize()
	}
}