<template>
  <act-container style="overflow: scroll">

    <act-row style="margin-bottom: 4em;">
        <welcome :name="loggedInUser?.name" :role="loggedInUserRoleDisplay"/>
    </act-row>

    <router-view></router-view>

  </act-container>
</template>

<script>
import { mapGetters } from 'vuex'
import ActRow from '@/components/layout/ActRow.vue'
import ActContainer from '@/components/layout/ActContainer.vue'

import TimeUtils from '../../../classes/TimeUtils'
import Welcome from './Welcome.vue'

/** Stubs and mocks for development only */
const mockViewsFromClients = require('./mockMostViewedFromClients.json')
const mockEdtirosNotificationsFile = require('./mockEditorNotifications.json')

export default {
	name: 'dashboard-home-view',
	components: { ActContainer, ActRow, Welcome },

	data()
	{
		return {

			mockMostViewedFromClients: mockViewsFromClients,

			mockEditorNotifications: mockEdtirosNotificationsFile
		}
	},

	computed: {

		...mapGetters('authStore', ['loggedInUser']),
		...mapGetters('accountStore', ['loggedInUserRoleDisplay'])
	},

	async beforeMount()
	{
		if(this.$store.getters['accountStore/isPartner'])
		{
			await Promise.all(
				[
					this.$store.dispatch('analyticsStore/fetchTotalAvailableRevenue'),
					this.$store.dispatch('analyticsStore/getTopViewsByClientDomain'),
					this.$store.dispatch('analyticsStore/fetchTotalViewsFromVideos',
						{ from: '', to: '' }) 
				])
		}
	},

	methods: {

		computePartnerValueToReceive(numOfViews)
		{
			const valuePerView = 0.30

			return (numOfViews * valuePerView).toFixed(2)
		},

		toRelativeTimeStringWrapper({ t, unit })
		{
			return TimeUtils.toRelativeTimeString(t, unit)
		},

		parsedNotifcationMessage({ message, vars })
		{
			const variableMappingLength = vars.length

			for (let index = 0; index < variableMappingLength; index++)
			{
				message = message.replace(`vars[${index}]`, vars[index])
			}

			return message
		}
	}
}
</script>

<style lang="scss" scoped>
.home__card-container
{
  padding-block: 1em;
}

.home-views-graph__header
{
  display: grid;
  gap: .5rem;
  align-items: center;
  width: 100%;
  padding: .25rem;

  span
  {
    font-size: var(--fs-200);
    color: var(--clr-neutral-200);
  }
}

@media screen and (min-width: 768px)
{
  .home-views-graph__header
  {
    grid-template-columns: 1fr 1fr;

    span
    {
      text-align: end;
    }
  }
}


.notification__list-item
{
  color: var(--clr-neutral-100);
  background: var(--clr-neutral-900);
  padding-block: .5em; margin-block: 1em;

  overflow: hidden;
  text-overflow: ellipsis;

  p
  {
    font-size: var(--fs-200);
  }
}
.notification__list-item__time-display
{
  color: var(--clr-neutral-200);
  font-style: oblique;
}

.available-revenue__container
{
  border-radius: 1rem;
  background: var(--clr-neutral-900);
}
</style>