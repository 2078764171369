<template>
  <div class="act-dropdown">

    <div class="" :style="
    `color: ${color}`">

      <slot ></slot>
    </div>
    
  </div>
</template>

<script>
export default {
	name: 'ActDropdown',

	props: {
		items: {
			type: Array,
			default: () => { return [] }
		},

		color: {

			type: String,
			required: false,
			default: () => { return '#f5f5f1'}
		}
	}

}
</script>

<style lang="scss" scoped>
.act-dropdown
{
  user-select: none;
  position: absolute;
  z-index: 300;
  padding: .25rem;
  width: max-content; height: max-content;

  /** Shareables between other classes */
  border: none;
  border-radius: .5rem;

  background: #151515;

  & > .act-dropdown-child > *
  {
    &:hover
    {
      filter: brightness(115%) !important;
    }
  }
}
</style>