import { FixedLengthArray, Nullable } from '@/types/Utilities'

type RGBAColor = FixedLengthArray<[number, number, number, number]>;
enum ColorChannelMask { Red = 0, Green = 1, Blue = 2, Alpha = 3 }

export default class ImageUtils {
	src: string
	url: Nullable<string>

	_width: number
	_height: number

	constructor(source: string) {
		/** Sample ulr: https%3A%2F%2Fupload.wikimedia.org%2Fwikipedia%2Fcommons%2Fthumb%2F5%2F5b%2FAmbev_logo.svg%2F2560px-Ambev_logo.svg.png */
		this.src = source

		this._width = 900 // maxWidthDim
		this._height = 900 // maxHeigthDim

		this.url = null
	}

	async setBackgroundBasedOnForegroundColor() {
		const image = this._createImageElement()

		const canvas = this._createCanvasElement()

		const context = canvas.getContext('2d', { willReadFrequently: true }) as CanvasRenderingContext2D

		return new Promise<void>((resolve) => {
			image.addEventListener('load', () => {
				this.handleImageLoad(canvas, context, image)
				resolve()
			}, false)
		})
	}

	handleImageLoad(canvas, context, image) {
		this._scaleToFitCanvas(canvas, context, image)

		/** When image gets here it sometime hasnt loaded, causing the scale
			*  variable to return 'Infinity', rendering the canvas useless */

		const updatedImageData = this._updateBackgroundColorBasedOnImageColor(context)

		context.putImageData(updatedImageData, 0, 0)

		this.url = canvas.toDataURL()

		image.remove()
		canvas.remove()

		return this.url
	}

	async get() {
		return this.url
	}

	//#region Private methods

	/**
	* @todo Fix canvas resizing to improve image quality
		*/
	private _createCanvasElement(width = 900, height = 900): HTMLCanvasElement {
		const canvas = document.createElement('canvas')
		canvas.setAttribute('width', width.toString())
		canvas.setAttribute('height', height.toString())

		return canvas
	}

	private _createImageElement() {
		const image = document.createElement('img')

		image.setAttribute('crossorigin', 'anonymous')
		image.setAttribute('src', this.src)

		return image
	}

	private _scaleToFitCanvas(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, img: HTMLImageElement) {
		let factor = Math.min(canvas.width / img.width, canvas.height / img.height)

		ctx.scale(factor, factor)
		ctx.drawImage(img, 0, 0)
		ctx.scale(1 / factor, 1 / factor)
	}

	private _averageImageColorBrightness(imageData: Uint8ClampedArray): number {
		let channels = { r: 0, g: 0, b: 0 }
		const length = imageData.length

		for (let i = 0; i < length; i += 4) {
			channels.r += imageData.at(i) as number
			channels.g += imageData.at(i + 1) as number
			channels.b += imageData.at(i + 2) as number
		}

		/** @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Bitwise_NOT */
		const numOfPixels = length / 4

		const channelSum = ~~((channels.r + channels.g + channels.b) / numOfPixels)

		return channelSum
	}

	private _updateBackgroundColorBasedOnImageColor(canvasContext: CanvasRenderingContext2D): ImageData {
		/** @todo check for null image data */
		const ImageDataContext = canvasContext.getImageData(0, 0, this._width, this._height)

		const { data, data: { length } } = ImageDataContext

		const channelSum = this._averageImageColorBrightness(data)

		const colorMap: { [key: string]: RGBAColor } = { 'black': [0, 0, 0, 255], 'white': [255, 255, 255, 255] }

		const colorToSet = channelSum > 50 ? 'black' : 'white'

		/** If the pixel rgb channels are not set, change its alpha */
		for (let i = 0; i < length; i += 4) {
			/** Alpha channel */
			if (this._pixelIsTransparent(data.slice(i, i + 4), 32)) {
				this._setRGBAColors(data, i, colorMap[colorToSet])
			}
		}

		return ImageDataContext
	}

	private _setRGBAColors(data: Uint8ClampedArray, pixelPointer: number, rgbaColorArray: RGBAColor) {

		/** Sums channel offset */
		data[pixelPointer + ColorChannelMask.Red] = rgbaColorArray.at(ColorChannelMask.Red) as number
		data[pixelPointer + ColorChannelMask.Green] = rgbaColorArray.at(ColorChannelMask.Green) as number
		data[pixelPointer + ColorChannelMask.Blue] = rgbaColorArray.at(ColorChannelMask.Blue) as number
		data[pixelPointer + ColorChannelMask.Alpha] = rgbaColorArray.at(ColorChannelMask.Alpha) as number
	}

	private _pixelIsTransparent(pixel: Uint8ClampedArray, threshold = 8): boolean {
		return (<number>pixel.at(ColorChannelMask.Alpha)) < threshold
	}

	//#endregion
}
