import { ActionAPI } from '../../services/ActionAPI'
import { ActionContext } from 'vuex'

interface Partner {
	id: number
}

interface PartnerStoreState {
	partner: Partial<Partner>,
	partners: Array<Partner>
}

const namespaced = true

const state: Readonly<PartnerStoreState> = {
	partner: {},
	partners: []
}

const getters = {

	currentPartner: (state: PartnerStoreState) => {
		return state.partner
	},

	computedPartners: (state: PartnerStoreState) => {
		return state.partners
	}
}
const mutations = {

	setPartner(state: PartnerStoreState, { partner }: { partner: Partner }) {
		state.partner = partner
	},

	setPartners(state: PartnerStoreState, { partners }: { partners: Array<Partner> }) {
		state.partners = partners
	},

	assingIntoPartners(state: PartnerStoreState, { partners }: { partners: Array<Partner> }) {
		state.partners = [...partners]
	},

	overwritePartner(state: PartnerStoreState, { partnerIndex, data }: { partnerIndex: number, data: any }) {
		state.partners[partnerIndex] = {
			...state.partners[partnerIndex],
			...data
		}
	},

	mergeIntoPartners(state: PartnerStoreState, { newPartner }: { newPartner: Partner }) {
		state.partners.push(newPartner)
	},

	splicePartner(state: PartnerStoreState, { index }: { index: number }) {
		state.partners.splice(index, 1)
	}

}
const actions = {

	async fetchPartners({ commit }: ActionContext<PartnerStoreState, any>) {
		const response = await ActionAPI.PartnerService.getPartners()

		if (response) {
			return commit('assingIntoPartners', { partners: response.data })
		}

		console.error('An error occurred while fetching partners')
	},

	async fetchPartnerResource(
		{ state, commit }: ActionContext<PartnerStoreState, any>,
		{ partnerId }: { partnerId: number }) {
		const response = await ActionAPI.PartnerService.getPartnerResource(
			{
				partnerId: partnerId
			})
			.catch(error => {

				if ('status' in error) {
					return console.error('An error ocurred while fetching partner ' + partnerId + ' details.')
				}
			})

		if (response) {
			const { data: partner } = response

			return commit('setPartner',
				{
					partner: Object.assign(state.partner, partner)
				})
		}
	},


	async storePartner({ commit }: ActionContext<PartnerStoreState, any>, { data }: { data: any }) {
		const response = await ActionAPI.PartnerService.storePartner(data)

		if (response) {
			return commit('mergeIntoPartners', { newPartner: response.data })
		}

		console.warn('Um erro ocorreu ao adicionar o parceiro')
		return { status: 'error' }
	},

	async updatePartner(
		{ state, commit }: ActionContext<PartnerStoreState, any>,
		{ partnerIndex, data }: { partnerIndex: number, data: any }) {
		const partner = state.partners[partnerIndex]

		if (!partner) {
			throw Error('Could not find partner')
		}

		const response = await ActionAPI.PartnerService.updatePartner(
			{
				partnerId: partner.id,
				data: data
			})

		if (response) {
			return commit('overwritePartner',
				{
					partnerIndex: partnerIndex,
					data: response.data
				})
		}

		return { status: 'error' }

	},


	async updatePartnerDomains(
		{ state, commit }: ActionContext<PartnerStoreState, any>,
		{ data }: { data: any }) {

		if (state.partner.id === null || state.partner.id === undefined) {
			throw Error('Missing partnerId')
		}

		const response = await ActionAPI.PartnerService.updatePartner(
			{
				partnerId: state.partner.id,
				data: data
			})

		if (response) {
			commit('setPartner',
				{ partner: response.data })

			return { status: 'success' }
		}

		return { status: 'error' }
	},

	async destroyPartner(
		{ state, commit }: ActionContext<PartnerStoreState, any>,
		{ partnerIndex }: { partnerIndex: number }) {
		let partnerToDestroy = state.partners[partnerIndex]

		const response = await ActionAPI.PartnerService.destroyPartner(partnerToDestroy.id)

		if (response) {
			return commit('splicePartner', { index: partnerIndex })
		}

		return { status: 'error' }
	}
}

/** @deprecated */
export default { namespaced, state, getters, mutations, actions }