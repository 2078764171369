<template>
  <div class="welcome__user">
    Bem vindo, {{ name }}
    <br>
    <span>{{ role }}</span>
  </div>
</template>

<script>
export default {
	name: 'WelcomeView',

	props: {

		name: {
			required: false,
			default: ''
		},

		role: {
			required: false,
			default: ''
		}
	}
}
</script>

<style lang="scss" scoped>
.welcome__user
{
  font-size: var(--fs-500);
  color: var(--clr-purple-300);

  & span
  {
    font-size: var(--fs-400);
    color: var(--clr-gold-300);
    font-style: oblique;
  }
}
</style>