<template>
  <act-container id="dashboard-operations-list">
    <br>
    <act-row style="margin-inline: 2em">
      <p style="color: #624CAB;">Selecione uma das operações disponíveis</p>
    </act-row>

    <act-row>
      <act-col :w="12">

        <act-list-item v-for="op, opIndex in Object.keys(opertaionsList)" :key="opIndex">
          <action-button square
          :disabled="opertaionsList[op] === 'disabled'"
          @click.native="opertaionsList[op]">
            <template #content>{{ op }}</template>
          </action-button>
        </act-list-item>

      </act-col>
    </act-row>

  </act-container>
</template>

<script>
import ActionButton from '../../../components/ActionButton.vue'
import ActListItem from '../../../components/ActListItem.vue'
import ActCol from '../../../components/layout/ActCol.vue'
import ActContainer from '../../../components/layout/ActContainer.vue'
import ActRow from '../../../components/layout/ActRow.vue'
import routeHandlerMixin from '../../../mixins/routeHandler.vue'

export default {
	name: 'dashboard-operations-list',
	components: { ActContainer, ActRow, ActCol, ActListItem, ActionButton },
	extends: routeHandlerMixin,

	computed: {

		opertaionsList()
		{
			return {
				'Controle de Crédito' :this.goToCreditManagment,
				'Histórico de Crédito' :this.goToCreditHistory,
				'Relatórios' : this.gotToReports
			}
		}
	},

	methods: {

		goToCreditManagment()
		{
			this.goToRoute('/dashboard/operations/credit-management')
		},

		goToCreditHistory()
		{
			this.goToRoute('/dashboard/operations/credit-history')
		},

		gotToReports()
		{
			this.goToRoute('/dashboard/operations/reports')
		}
	}

}
</script>

<style lang="scss" scoped>

</style>