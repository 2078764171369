// CPF Functions
export function maskCPF(e, string){
	var i
	var field
	if (!string){
		field = e.path[0].value
		let keyHandler = e.data
		field = field.replace(/[^0-9,-.]+/g, '')
		if (keyHandler !== null){
			for (i = 0; i < field.length; i++) {
				const text = field
				if (field.length === 4){
					if (i === 3){
						field = text.slice(0, 3) + '.' + text.slice(3)
					}
				}
				if (field.length === 8){
					if (i === 7){
						field = text.slice(0, 7) + '.' + text.slice(7)
					}
				}
				if (field.length === 12){
					if (i === 11){
						field = text.slice(0, 11) + '-' + text.slice(11)
					}
				}
			}
			return field
		}
	} else {
		field = string
		const text1 = field
		field = text1.slice(0, 3) + '.' + text1.slice(3)
		const text2 = field
		field = text2.slice(0, 7) + '.' + text2.slice(7)
		const text3 = field
		field = text3.slice(0, 11) + '-' + text3.slice(11)
		return field
	}
}
export function cleanCPF(CPF){
	let cleaned = CPF.replace(/[-.]+/g, '')
	return cleaned
}
export function capitalizeEvery(arr){
	const str = arr.split(' ')
	for (var i = 0; i < str.length; i++) {
		str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
	}
	const str2 = str.join(' ')
	return str2
}
