import videojs, { VideoJsPlayer } from 'video.js'
const Plugin = videojs.getPlugin('plugin')

import './components/DragNDropComponent'
import './components/InteractionOverlayComponent'
import './components/MultipleChoiceComponent'
import './components/SubmitButtonComponent'

import { /* normalizeMouseCoord_, */ arrayEquals } from '../../utils'
import type { IInteractionElement, InteractionPluginOptions, InteractionPluginState } from './types'
import { InteractionElementType } from './types/enums'
import { Nullable } from '@/types/Utilities'

enum InteractionType {

	MultipleChoice = 1,
	DragNDrop = 3,
	Control = 4
}

export default class InteractionPlugin extends Plugin {

	state: InteractionPluginState

	setState(_state: Partial<InteractionPluginState>): void {

		Object.assign<InteractionPluginState, Partial<InteractionPluginState>>(
			this.state, _state)
	}

	constructor(player: VideoJsPlayer, options: InteractionPluginOptions) {
		super(player, options)

		this.state = {
			elements: [],
			selectedElementId: 0,
			selectedElements: [],
			outputPointer: null,
			hasDropZones: false,
			dropZones: [],
			draggables: [],
			overlapMap: new Map
		}

		if (player) {
			this.initialize_(options)

			//#region Event listeners

			videojs.on(player.el(), 'showsubmitbutton', () => this.toggleSubmitButton(true))

			videojs.on(player.el(), 'click', (event_) => {

				/** Handle `event_` just if it is a Custom Event */
				if ('detail' in event_ && typeof event_.detail === 'object') {
					/** Multiple Choice: Handle selected element to graph node */
					if (options.interaction_type_id === InteractionType.MultipleChoice) {
						//
					}

					/** Drag N Drop */
					if (options.interaction_type_id === InteractionType.DragNDrop) {
						//
					}
				}
			})

			if (options.interaction_type_id === InteractionType.DragNDrop) /** Drag N Drop */ {
				/** Saves for later use */
				this.setState(
					{
						draggables: options.interaction_element
							.filter(({ interaction_element_type_id }) => interaction_element_type_id === InteractionElementType.Draggable)
					})
			}

			/** Emits event to vue parent component to handle buffer swap */
			videojs.on(player.el(), 'submitbtnclick', () => {

				this.toggleSubmitButton(false)

				/** Handle outputPointer for Drag N Drop */
				if (options.interaction_type_id === 3) {
					const { overlapMap } = this.state

					let matchingElements = 0
					overlapMap.forEach((value, key) => {

						let element = this.state.draggables.find(element => element.id === key)

						if (element && element.pair_element_id === value)
							matchingElements = matchingElements + 1
					})

					let segmentToPlay = null

					matchingElements === overlapMap.size ?
						segmentToPlay = options.output.right : segmentToPlay = options.output.wrong

					if (segmentToPlay)
						this.setState({ outputPointer: segmentToPlay })
				}

				/** Will be using as mock container for testing */
				if (options.interaction_type_id === InteractionType.Control) {
					let refIds: Array<number> = []

					this.state.selectedElements.forEach(id => refIds.push(this.state.elements.find(el => el.id === id).segment_reference_id))

					let segmentToPlay = refIds.length > 0 ? refIds[0] : null

					this.setState({ outputPointer: segmentToPlay })

					/** Fix previous iterations states */
					player.el().dispatchEvent(new CustomEvent('unselectall', {}))
				}

				if (options.interaction_type_id === 1) {
					const segmentToPlay = this._validateMultipleChoiceInteraction(
						this.state.elements, this.state.selectedElements,
						{
							correctOutput: options.output.right,
							wrongOutput: options.output.wrong
						}, options.has_multiple)

					if (segmentToPlay)
						this.setState({ outputPointer: segmentToPlay })

					/** Fix previous iterations states */
					player.el().dispatchEvent(new CustomEvent('unselectall', {}))
				}

				player.el().dispatchEvent(new CustomEvent('proxysubmit', {
					detail: {
						isControlEvent: true,
						selectedElementId: this.state.selectedElementId,
						pointerId: this.state.outputPointer
					}
				}))

				/** Clean up state */
				player.el().dispatchEvent(new CustomEvent('resetstate', {}))

				this.resetAppendedElements()

				/** Drag N Drop */
				if (options.interaction_type_id === 3) {
					this.setState({ overlapMap: new Map() })
				}
			})

			const hasRangeLoop = ('loop' in options && options.loop === true)

			videojs.on(player.el(), 'timeupdate', () => {

				if (hasRangeLoop)
					this.setLoopRange(options.loop_start_ms, options.loop_end_ms)
			})

			/** Handle Multiple Choice events */
			videojs.on(player.el(), 'choiceselected', ({ detail: { id } }) => {

				this.setState({ selectedElements: [id, ... this.state.selectedElements] })

				if (this.state.selectedElements.length >= 1)
					this.player.el().dispatchEvent(new CustomEvent('showsubmitbutton', {}))
			})

			videojs.on(player.el(), 'choiceunselected', ({ detail: { id } }) => {

				let tmpArray = this.state.selectedElements
				tmpArray.splice(tmpArray.indexOf(id), 1)

				this.setState({ selectedElements: tmpArray })

				if (this.state.selectedElements.length === 0)
					this.toggleSubmitButton(false)
			})

			videojs.on(player.el(), 'unselectall', () => this.setState({ selectedElements: [] }))


			/** Handle Drag N Drop events */
			videojs.on(player.el(), 'dragstop', (event_) => {

				const { detail: { elementId: draggableId, elementDOMRect: draggableDOMRect } } = event_
				const { dropZones, draggables } = this.state

				/** Checks for elements that are overlapping with the CURRENTLY dragged element */
				const overlaps: Array<{ draggableId: number, dropZoneId: number, coords: { x: number, y: number } }> = []
				dropZones.forEach(dzone => {

					const dropZoneDomRect = dzone.htmlElement.getBoundingClientRect()

					if (this.isOverlapping(draggableDOMRect, dropZoneDomRect)) {
						/** Snap element to position */
						let drgb = document.getElementById(`act-overlay_dragndrop_el__${draggableId}`)

						let drpz = document.getElementById(`vjs-act-overlay__child_wrapper_${dzone.element.id}`)

						if (drgb === null || drpz === null) {
							console.debug('Could not get drgb and/or drpz elements')
							return
						}


						/** Snap to dropzone using append. Requires handling unappeding in the next operations */
						drpz.appendChild(drgb)

						Object.assign(drgb.style,
							{
								'top': 0, 'left': 0,
								'position': 'relative'
							})

						this.handleClassAppend(drpz)

						overlaps.push(
							{
								draggableId: draggableId,
								dropZoneId: dzone.element.id,
								coords: this.getElementCenterPoint(dzone.htmlElement.getBoundingClientRect())
							})
					}
				})

				/** If the overlaps array is empty, it means theres at least one dropZone empty */
				let tmpOverlapMap: Map<number, any> = new Map(this.state.overlapMap) as Map<number, any>

				/** Snap if the event element is overlapping with any of their thresholds. */
				if (overlaps.length > 0) {
					for (let i = 0; i < overlaps.length; i++) {
						tmpOverlapMap.set(overlaps[i].draggableId, overlaps[i].dropZoneId)
					}
				}
				else if (tmpOverlapMap.has(draggableId)) tmpOverlapMap.delete(draggableId)

				this.setState({ overlapMap: tmpOverlapMap })

				let isOverlapValid_ = false

				options.inverse ?
					isOverlapValid_ = this.isOverlapValid(tmpOverlapMap, draggables.map(obj => obj.id), true) :
					isOverlapValid_ = this.isOverlapValid(tmpOverlapMap, dropZones.map(obj => obj.element.id), false)

				/** Number of dropZones and filled elements match */
				isOverlapValid_ ?
					this.toggleSubmitButton(true) : this.toggleSubmitButton(false)
			})

			/**
				* @todo Refactor: currently implementation implicity rely on a parsed manifest file that is continuously changed through the playback cycle.
				*               changes to obscure or deep parts of plugin that rely on these properties that are passed around through evens are bound to
				*               break upon even small changes. */
			videojs.on(player.el(), 'handleclass', ({ detail }) => {

				const _dropZone = document.getElementById(detail.dzone)

				if (_dropZone) {
					this.handleClassAppend(_dropZone, detail.selected, detail.op)
				}
			})

			//#endregion
		}
	}

	/**
	 * Handles grid container and content classes upon snapping.
	 */
	handleClassAppend(dropZoneEl: HTMLElement, selectedEl: Nullable<HTMLElement> = null, options: Nullable<Array<string> | String> = []) {
		/** Adjust size based on n of elements */
		for (let i = 0; i < dropZoneEl.children.length; i++) {

			/** Sets grid container cells */
			this.handleClassCondition(dropZoneEl, dropZoneEl.children.length >= 2, 'two_col_container')
			this.handleClassCondition(dropZoneEl, dropZoneEl.children.length >= 3, 'two_rol_container')

			/** Case: align 3rd element to center */
			this.handleClassCondition(dropZoneEl, dropZoneEl.children.length === 3, 'center_trd_el_col')


			/** Set content to fit grid cells */
			if (options === 'remove' && dropZoneEl.children[i].classList.contains('inherit_container')) {
				if (selectedEl) {
					selectedEl.classList.remove('inherit_container')
				}

				dropZoneEl.children[i].classList.remove('inherit_container')
			}

			if (dropZoneEl.children.length >= 3) {
				if (selectedEl && dropZoneEl.children[i].id === selectedEl.id)
					return

				dropZoneEl.children[i].classList.add('inherit_container')
			}
		}
	}

	initialize_(options: InteractionPluginOptions) {
		let componentName = 'InteractionOverlayComponent'

		this.player.addChild(componentName, { id: options.id })
		const overlayComponent = this.player.getChild(componentName)

		if (overlayComponent === undefined) {
			//
			return
		}

		if ('interaction_submit_button' in options) {
			const { style } = options.interaction_submit_button

			const submitButtonPolyfill = {
				...options.interaction_submit_button,
				scale: {
					...options.d_scale
				},
				screen_y: style.y,
				screen_x: style.x,
				width: style.width,
				height: style.height
			}

			overlayComponent.addChild('SubmitButtonComponent', submitButtonPolyfill)
		}

		/** Add element for each interaction  */
		if ('interaction_element' in options) {
			const API_BASE_URL = `${process.env.VUE_APP_API_BASE_URL}`

			/** Todo: handle different components based on interaction type */
			let interactionElementClass = ''

			options.interaction_type_id === 1 || options.interaction_type_id === 4 ?
				interactionElementClass = 'MultipleChoiceComponent' :
				interactionElementClass = 'DragNDropComponent'

			for (let i = 0; i < options.interaction_element.length; i++) {
				overlayComponent.addChild(interactionElementClass,
					{
						...options.interaction_element[i],
						hasMultiple: options.has_multiple,
						selectionType: options.selection_type,
						scale: options.d_scale,
						API_BASE_URL: API_BASE_URL + '/storage'
					})
			}

			this.state.elements = [...options.interaction_element]
		}

		/** Drag N Drop */
		/** Has to recalculate dropzones positions to ensure overlaps are correct */
		if (options.interaction_type_id === 3)
			videojs.on(this.player.el(), 'playerresize', () => {

				this.mapDropZoneRoutine(options)
				this.resetAppendedElements()

				/** Hotfix when changing screen orientation */
				this.toggleSubmitButton(false)

				// setState(m => m = [])
				this.state.overlapMap.clear()
			})
	}

	mapDropZoneRoutine(options: InteractionPluginOptions) {
		const dropZones = this.mapDropZoneBoudingRect(options.interaction_element)

		this.setState({ hasDropZones: true, dropZones: dropZones })
	}

	/**
 * Loops through a segment of time(ms) in the video.
 */
	setLoopRange(loopStartMs: number, loopEndMs: number) {

		let pDt = this.player.currentTime()

		if (pDt > (loopEndMs - 0.5) && pDt <= loopEndMs)
			return this.player.currentTime(loopStartMs)
	}

	toggleSubmitButton(value: boolean) {
		const submitButtonComponent = this.player.getDescendant(['InteractionOverlayComponent', 'SubmitButtonComponent'])

		if (submitButtonComponent) {
			value === true ?
				submitButtonComponent.el().classList.add('vjs-sbmt_btn__visible') :
				submitButtonComponent.el().classList.remove('vjs-sbmt_btn__visible')
		}
	}

	/**
	 * Asserts two object  coordinates are overlapping.
	 */
	isOverlapping(elementA: DOMRect, elementB: DOMRect) {

		const isOverlapping =
			(elementA.x + (elementA.width / 2)) >= elementB.x &&
			elementA.x <= (elementB.x + (elementB.width) - elementA.width / 2)

			&& (elementA.y + (elementA.height / 2)) >= elementB.y &&
			elementA.y <= (elementB.y + (elementB.height) - (elementA.height / 2))

		return isOverlapping
	}

	/**
 * Returns the center point of an DOM element.
 * 
 * @todo  There is proportional relationship between the divison value and the element proportions that needs to be set programatically for more accurate results.
 * @returns Center coordinates of the the passed element center point.
 */
	getElementCenterPoint(element: DOMRect) {
		return {
			x: element.x + (element.width % 2),
			y: element.y + (element.height % 2)
		}
	}

	/**
 * Maps dropzone elements to its DOM coordinates
 * 
 * @readonly
 * @returns {[{ element: object, boundingClientRect: DOMRect | undefined }]} Map
 */
	mapDropZoneBoudingRect(elements: Array<IInteractionElement> = []) {
		const dropzones =
			elements.filter(element => element.interaction_element_type_id === InteractionElementType.DropZone)

		return dropzones.map(element => {
			const elDOMRect = document.getElementById(`act-overlay_dragndrop_el__${element.id}`)

			if (elDOMRect) {
				elDOMRect.getBoundingClientRect()
			}
			return {
				element: element,
				htmlElement: elDOMRect // might be null
			}
		})
	}


	/** 
 * Uses either draggable or dropzones to validate if interaction is fullfileld.
 * 
 * Inverse as ```true``` requires all draggable elements to be used. ```false``` requires all dropzones to be filled.
 * 
 * The validation follows the schema:
 *
 * - Number of overlapping elements must be equal to validation elements
 * - All validation element identifier should be present in the overlapping map
 */
	isOverlapValid(overlapMap: Map<number, any>, elementArray: Array<IInteractionElement['id']>, isInverse = false): boolean {
		let overlapAttribute = null

		/** Keys: Draggables; Values: Dropzones */
		isInverse ?
			overlapAttribute = [...overlapMap.keys()] :
			overlapAttribute = [...overlapMap.values()]

		/** Sort both arrays so isEqual validation can be performed by index */
		overlapAttribute.sort((a, b) => a - b)

		//@ts-ignore
		elementArray.sort((a, b) => a - b)

		return overlapMap.size === elementArray.length && arrayEquals(overlapAttribute, elementArray)
	}

	resetAppendedElements() {

		const OverlayComponent = this.player.getChild('InteractionOverlayComponent')

		if (OverlayComponent === undefined) {
			console.debug('Could not load baseElement from InteractionOverlayComponent')
			return
		}

		let baseElement = OverlayComponent.el()

		/** Reset appended draggables */
		this.state.draggables.forEach(draggable => {

			let el = document.getElementById(`act-overlay_dragndrop_el__${draggable.id}`)

			if (el !== null) {
				Object.assign(el.style, { 'position': 'absolute' })

				baseElement
					.appendChild(el)
			}
		})
	}

	/**
	 * Adds a specified `class` to a given element, given a condition, or removes it through an `else if` statement.
	 */
	handleClassCondition(htmlElement: HTMLElement, condition: boolean, className: string) {

		if (condition)
			htmlElement.classList.add(className)

		else if (htmlElement.classList.contains(className))
			htmlElement.classList.remove(className)
	}

	/**
 * @todo Most of these implemention are heavily dependent inconsitent object apis.
 *       A lot more error handling, or any code safety measure can improve this a lot.
 * 
 * @param {Array} elements 
 * @param {Array} selectedElements 
 * @param {{ correctOutput?: number, wrongOutput?: number }} options 
 * @returns {number|null} pointer
 */
	_validateMultipleChoiceInteraction(
		elements: Array<IInteractionElement>, selectedElements: Array<number>,
		{ correctOutput = null, wrongOutput = null }: { correctOutput: Nullable<number>, wrongOutput: Nullable<number> }, hasMultiple = false) {
		const correctElements = elements.filter(el => el.segment_reference_id === correctOutput).length

		const refs = elements.filter(el => selectedElements.some(element => element === el.id))

		if (hasMultiple) {
			/** Returns the wrong answer in case the value is found inbetween an array of correct ones */
			return (refs.length === correctElements && !refs.some(({ segment_reference_id }) => segment_reference_id !== correctOutput)) ?
				correctOutput : wrongOutput
		}

		/** Expects only one ref to be selected. Acts like a control flow interaction */
		return refs[0].segment_reference_id
	}
}

videojs.registerPlugin('InteractionPlugin', InteractionPlugin)
