import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import Router from 'vue-router'
import router from './router'
import store from './store'
import { maskCPF, cleanCPF, capitalizeEvery } from './generic_functions'

/** Bind VimeoAPI to Vue instance */
// Vue.prototype.VIMEO_API = new VimeoAPI()


// Todo: update image location settings when implementing new interactions
Vue.prototype.$ImgLink = `${process.env.VUE_APP_API_BASE_URL}/storage` // Symbol linked

Vue.prototype.DOMAIN = process.env.VUE_APP_URL || 'http://localhost:8080'

Vue.config.productionTip = false

Vue.prototype.$capitalizeAll = capitalizeEvery
Vue.prototype.$maskCPF = maskCPF
Vue.prototype.$cleanCPF = cleanCPF

// .use(VueLocalStorage)
Vue.use(Vuex).use(Router)

new Vue({ store, router, render: h => h(App) }).$mount('#app')
