<template>
  <div class="act-card">

    <div class="act-card__img">
      <slot name="img"></slot>
    </div>

    <div class="act-card__content">

      <div class="act-card__title">
        <slot name="title"></slot>
      </div>

      <div class="act-card__text">
        <slot name="text"></slot>
      </div>
    </div>

  </div>
</template>

<script>
export default {
	name: 'ActCard'
}
</script>

<style lang="scss" scoped>
@import '../../styles/scss/mixins.scss';

.act-card
{
  @include fit-container;

  border-radius: .5rem;
  
  padding: 1rem;

  & > * 
  {
    border-radius: inherit;
    
    &:nth-child(1)
    {
      margin-inline-end: .75rem;
    }
  }

  .act-card__img
  {
    @include flex;
    @include fit-container;

    text-align: center;

    img
    {
      @include flex;
    }
  }

  .act-card__content
  {
    width: 100%;
    padding: .25rem .125rem;

    .act-card__title
    {
      margin-bottom: .25rem;
    }

    .act-card__text
    {
      /* */
    }
  }

}
</style>