import Vue from 'vue'
import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { Nullable } from '@/types/Utilities'
import { RootState } from '../index.d'

import ObjectUtils from '@/classes/ObjectUtils'
import { translateProjectStatus } from '../../classes/Translation'
import { ActionAPI } from '../../services/ActionAPI'
import { StoreProjectRequest } from '../../services/ActionAPI/index.d'

export declare interface DashboardStoreState {
	projects: Array<any>,
	currentProject: {
		type_id: Nullable<number>,
		code: Nullable<string>,
		status: {
			id: Nullable<number>
			name: Nullable<string>,
		}
	},
	playlists: Array<any>
}

const namespaced = true
const state: Partial<Readonly<DashboardStoreState>> = {
	projects: [],
	currentProject: {
		type_id: null,
		code: null,
		status: {
			id: null,
			name: null
		}
	},
	playlists: []
}
const getters: GetterTree<DashboardStoreState, RootState> = {

	currentProject: (state) => {

		return state.currentProject
	},

	hasCurrentProject: (state) => {

		return state.currentProject !== null
	},

	allProjects: state => {

		return state.projects
	},

	currentProjectStatus: (state) => {

		if (state.currentProject.status.name !== null) {
			let computedName = translateProjectStatus(state.currentProject.status.name)

			return {
				...state.currentProject.status,
				computedName: computedName
			}
		}

		return {
			id: null,
			name: 'Null',
			computedName: 'null'
		}
	},

	currentProjectType: (state) => {

		return state.currentProject?.type_id
	},

	isProjectEditable: (state) => {

		if (state.currentProject === null) {
			return false
		}

		return state.currentProject.status.id === 1
	},

	playlists: (state) => {
		return state.playlists
	},

	// eslint-disable-next-line no-unused-vars
	recentProjects: (state) => {
		if (state.projects.length > 0) {
			return state.projects
				.sort((a, b) => Date.parse(a.updated_at) - Date.parse(b.updated_at))
				.slice(0, 4)
		}
		return state.projects
	},

	videoProjects: (state) => {
		return state.projects.filter(project => {

			return {
				name: project.name,
				updated_at: project.updated_at
			}
		})
	}

}
const mutations: MutationTree<DashboardStoreState> = {

	//#region Project Mutations

	/**
	 * Pushes a new entry into the projects array.
	 */
	pushIntoProjects(state, { project }: { project: any }) {
		state.projects.push(project)
	},

	/**
	 * Shift a new entry into the projects array.
	 * 
	 * @param {state} state 
	 * @param {project: object} payload
	 */
	unshiftIntoProjects(state, { project }) {
		state.projects.unshift(project)
	},

	/**
	 * Merges an array into projects by overwriting.
	 * 
	 * @param {state} state 
	 * @param {projects: Array} payload
	 */
	mergeIntoProjects(state, { projects }) {
		state.projects = projects
	},

	mergeReplaceProject(state, { indexOfProject, project }) {

		Vue.set(state.projects, indexOfProject, project)
	},

	spliceProject(state, { indexOfProject }) {
		state.projects.splice(indexOfProject, 1)
	},

	mergeUpdateCurrentProject(state, { project }) {
		state.currentProject = project
	},

	//#endregion

	setPlaylists(state, { playlists }) {
		state.playlists = [...playlists]
	},
	//#region Cleanup routines


	clearCurrentProject(state) {
		state.currentProject = {
			type_id: null,
			code: null,
			status: {
				id: null,
				name: null
			}
		}
	},

	clearProjects(state) {
		state.projects = []
	}

	//#endregion
}
const actions: ActionTree<DashboardStoreState, RootState> = {

	async updateProject(
		{ state, dispatch },
		{ projectId, data }: { projectId: number, data: any }) {
		/** @todo Convert function to get code by id into a helper */
		let project = state.projects.find(({ id }) => id === projectId)
		let hashId = project?.code

		/** @todo Perform form validations */
		const response = await ActionAPI.ProjectService.updateProject(hashId, data)
			.catch(error => {

				let errorMessage = error?.message ?? 'Um erro ocorreu, tente novamente em alguns momentos'

				throw { status: false, message: errorMessage }
			})

		dispatch('mergeReplaceProject', {
			indexOfProject: state.projects.indexOf(project),
			project: response.data
		})

		return 'Atualizado com sucesso'
	},

	async duplicateProject({ state, dispatch }, projectId) {
		/** @todo Convert function to get code by id into a helper */
		let project = state.projects.find(({ id }) => id === projectId)
		let hashId = project?.code

		const response = await ActionAPI.ProjectService.duplicate(hashId)

		if (response) {
			dispatch('unshiftIntoProjects', { project: response.data })

			return { status: 'success' }
		}

		console.warn('Um erro occoreu ao duplicar projeto')
		return { status: 'error' }
	},

	async destroyProject({ state, dispatch }, projectId) {
		/** @todo Convert function to get code by id into a helper */
		let project = state.projects.find(({ id }) => id === projectId)
		let hashId = project?.code

		const response = await await ActionAPI.ProjectService.destroyProject(hashId)

		if (response && response.status === 200) {
			dispatch('spliceProject', { indexOfProject: state.projects.indexOf(project) })

			return { status: 'success' }
		}

		return { status: 'error' }
	},

	async fetchProjectsFromCurrentUser({ rootState, dispatch } /*, userId */) {
		/** Supress console warnings */
		if ('user' in rootState.authStore && 'sessionToken' in rootState.authStore) {
			// const userId = rootState.authStore.user.id
			// const sessionToken = rootState.authStore.user.session.token

			const response = await ActionAPI.ProjectService.getProjectsFromLoggedInUser()
				.catch(requestError => {

					throw Error(`An unexpected error happend while fetching user projects. Details: ${requestError}`)

				})

			return dispatch('mergeIntoProjects', { projects: [...response.data] })
		}

		throw Error('Could not find user session in store.')
	},

	async fetchClientVideoLibrary(context, { clientId }) {
		/** Supress console warnings */
		const response = await ActionAPI.ProjectService.getClientVideoLibrary(clientId)

		if (response) {
			return response.data
		}

		return { status: 'error' }
	},

	async storeNewProject({ dispatch },
		{ name, type, partner_id, user_id }: Omit<StoreProjectRequest, 'type_id'> & { type: string }) {
		const typeId = type === 'content' ? 1 : 2

		const storeProjectRequest = {
			name: name,
			type_id: typeId,
			partner_id: partner_id,
			user_id: user_id
		}

		const response = await ActionAPI.ProjectService.storeProject(storeProjectRequest)

		if (response) {
			dispatch('unshiftIntoProjects', { project: response.data })

			return { status: 'success' }
		}

		return { status: 'error' }
	},

	async generateShareableCode(context, { videoId }) {
		const response = await ActionAPI.ProjectService.generateClientProjectShareable(videoId)

		if (response) {
			return response.data
		}

		return { status: 'error' }
	},

	async deactiveShareableCode(context, { videoId }) {
		const response = await ActionAPI.ProjectService.deactivateClientProjectShareable(videoId)

		if (response) {
			return response.data
		}

		return { status: 'error' }
	},

	//#region Playlists actions

	async getPlaylists({ commit }, { clientId }) {
		const response = await ActionAPI.ProjectService.getGroupedProjects(clientId)

		if (response) {
			return commit('setPlaylists',
				{
					playlists: [...response.data]
				})
		}

		return { status: 'error' }
	},

	async storePlaylist({ rootState, state, commit }, { playlistData }) {
		const clientId = rootState.authStore.user.id

		const response = await ActionAPI.ProjectService.storePlaylist(
			clientId as number, playlistData
		)

		if (response) {
			state.playlists.unshift(response.data)

			commit('setPlaylists',
				{
					playlists: [...state.playlists]
				})

			return { status: 'success' }
		}

		return { status: 'error' }
	},

	async updatePlaylist({ state, commit }, { playlistId, playlistDetails }) {
		const response = await ActionAPI.ProjectService.updatePlaylist(
			playlistId,
			{
				data: playlistDetails
			}
		)

		if (response) {
			const playlistIndex = state.playlists.findIndex(({ hash }) => hash === playlistId)

			const filteredResponse = Object.fromEntries(
				Object.entries(response.data).filter(key => !key.includes('videos')))

			Object.assign(state.playlists[playlistIndex], filteredResponse)

			commit('setPlaylists',
				{
					playlists: [...state.playlists]
				}
			)

			return { status: 'success' }
		}

		return { status: 'error' }
	},

	async duplicatePlaylist({ state, commit }, { playlistId }) {
		const response = await ActionAPI.ProjectService.duplicatePlaylist(playlistId)

		if (response) {
			const { name, hash } = response.data

			const duplicatedPlaylist = ObjectUtils.deepCloneObject(
				{
					...state.playlists.find(playlist => playlist.hash === playlistId),
					name: name, hash: hash
				})

			state.playlists.push(duplicatedPlaylist)

			commit('setPlaylists', { playlists: state.playlists })

			return { status: 'success' }
		}
	},

	async destroyPlaylist({ state, commit }, { playlistId }) {
		const response = await ActionAPI.ProjectService.destroyPlaylist(playlistId)

		if (response) {
			commit('setPlaylists',
				{
					playlists: [
						...state.playlists.filter(playlist => playlist.hash !== playlistId)
					]
				}
			)

			return { status: 'success' }

		}

		return { status: 'error' }
	},

	async addVideoToPlaylist({ state, commit }, { video, playlist }) {
		const response = await ActionAPI.ProjectService.storeVideoToPlaylist(video, playlist.hash)

		if (response) {
			let playlistToAddTo = state.playlists.find(({ hash }) => hash === playlist.hash)

			playlistToAddTo.videos.push(video)

			commit('setPlaylists',
				{
					playlists: state.playlists.map(playlist => playlist.hash === playlistToAddTo.hash ? playlistToAddTo : playlist)
				})
		}

		return { status: 'error' }
	},

	async removeVideoFromPlaylist({ state, commit }, { video, playlist }) {
		const response = await ActionAPI.ProjectService.removeVideoFromPlaylist(video, playlist.hash)

		if (response) {
			/** @todo Check for null or invalid length */
			playlist.videos.splice(playlist.videos.findIndex(({ id }: { id: number }) => id === video.id), 1)

			commit('setPlaylists',
				{
					playlists: [...state.playlists]
				})
		}

		return { status: 'error' }
	},

	//#endregion

	//#region Mutation wrappers

	pushIntoProjects({ commit }, { project }) {
		commit('pushIntoProjects', { project: project })
	},

	unshiftIntoProjects({ commit }, { project }) {
		commit('unshiftIntoProjects', { project: project })
	},

	mergeIntoProjects({ commit }, { projects }) {
		commit('mergeIntoProjects', { projects: projects })
	},

	spliceProject({ commit }, { indexOfProject }) {
		commit('spliceProject', { indexOfProject: indexOfProject })
	},

	mergeReplaceProject({ commit }, { indexOfProject, project }) {
		commit('mergeReplaceProject',
			{
				indexOfProject: indexOfProject,
				project: project
			})
	},

	async mergeUpdateCurrentProject({ state, commit, dispatch }, { projectId }) // throws exception
	{
		if (!(state.projects.length > 0)) {
			await dispatch('fetchProjectsFromCurrentUser')
				.catch(error => { throw Error(error) })
		}

		let project = state.projects.find(p => p.id === parseInt(projectId) || p.code === projectId)

		if (!project) {
			throw Error(`Could not find project ${projectId}.`)
		}

		commit('mergeUpdateCurrentProject', { project: project })
	},

	clearCurrentProject({ commit }) {
		commit('clearCurrentProject')
	},

	resetState({ commit }) {
		commit('clearCurrentProject')
		commit('clearProjects')
	}

	//#endregion

}

/** `Dashboard` view state handler module. */
export default {
	namespaced,
	state,
	getters,
	mutations,
	actions
}