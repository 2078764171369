import {AxiosInstance} from 'axios'
import BaseService from './BaseService'
import {Nullable} from '@/types/Utilities.d'
import type { Project, StoreProjectRequest } from '@/services/ActionAPI/index.d'

export default class ProjectService extends BaseService {

	constructor(provider: AxiosInstance) {
		super(provider)

		// Todo: Add project object definition
		this.resourcePrefix = '/projects'
	}

	async getPlaybackManifest(projectId: number, { origin, subdomain } : { origin: string, subdomain: string }) {
		return await this.provider.get(`/videos/${projectId}`,
			{
				headers: {
					'Origin-Domain': origin,
					'Origin-Subdomain': subdomain
				}
			}).catch(() => { throw { status: 'error' } })
	}

	async previewVideo(projectId: number, { origin, subdomain } : { origin: string, subdomain: string }) {
		return await this.provider.get(`/videos/${projectId}/?preview`,
			{
				headers: {
					'Origin-Domain': origin,
					'Origin-Subdomain': subdomain
				}
			}).catch(() => { throw { status: 'error' } })
	}

	/**
	 * @param {number} userId Uses `request.headers` set during authentication to handle who is the user.
	 * During implementation process, receives value from caller.
	 */
	async getProjectsFromLoggedInUser(userId: Nullable<number> = null) {
		return await this.provider.get(`${this.resourcePrefix}`, { headers: { userId: userId } })
			.catch(error => { throw error })
	}

	async storeProject(storeProjectRequest : StoreProjectRequest) {
		return await this.provider.post(`${this.resourcePrefix}`, storeProjectRequest)
	}

	async updateProject(projectId: number, { name, type_id, status_id } : Project) {
		return await this.provider.patch(`${this.resourcePrefix}/${projectId}`,
			{
				name: name,
				type_id: type_id,
				status_id: status_id
			})
			.catch(error => 
			{
				if(error?.response)
				{
					const { status, data: { message } } = error.response

					if(status === 400) { throw Error(message) }
				}

				throw error
			})
	}

	async duplicate(projectId: number) {
		return await this.provider.post(`${this.resourcePrefix}/${projectId}/duplicate`)
	}

	async getClientVideoLibrary(clientId: number) {
		return await this.provider.get(`${this.resourcePrefix}/client-library/${clientId}`)
	}

	async generateClientProjectShareable(videoId: number) {
		return await this.provider.post(`${this.resourcePrefix}/${videoId}/assign`)
	}

	async deactivateClientProjectShareable(videoId: number) {
		return await this.provider.post(`${this.resourcePrefix}/${videoId}/unassign`)
	}

	async destroyProject(projectId: number) {
		return await this.provider.delete(`${this.resourcePrefix}/${projectId}`)
	}

	//#region Group functions
	async getGroupedProjects(clientId: number) {

		console.debug('%crequest made for client ' + clientId, 'color: blue;')

		return await this.provider.get('groups')
			.catch(() => { throw { status: 'error' } })
	}

	async storePlaylist(clientId: number, playlistRequest: any) {
		console.debug('%crequest made for client ' + clientId, 'color: green;')

		return await this.provider.post('groups', playlistRequest)
	}

	async updatePlaylist(playlistId: number, { data } : { data: any }) {
		console.debug('%crequest made for playlist ' + playlistId, 'color: green;')

		return await this.provider.patch(`groups/${playlistId}`, data)
			.catch(() => { throw { status: 'error' } })
	}

	async duplicatePlaylist(playlistId: number) {
		return await this.provider.post(`groups/${playlistId}/duplicate`)
			.catch(() => { throw { status: 'error' } })
	}

	async storeVideoToPlaylist(video : any, playlistId: number) {
		console.debug('%crequest made for video ' + video.id + ' and playlist ' + playlistId, 'color: orange;')

		return this.provider.post(`groups/${playlistId}/add-video`,
			{
				'video_id': video.id
			})
			.catch(() => { return { status: 'error' } })
	}

	async removeVideoFromPlaylist(video: any, playlistId: number) {
		console.debug('%crequest made for video ' + video.id + ' and playlist ' + playlistId, 'color: orange;')

		/** Request confirmation from backend */
		return this.provider.post(`groups/${playlistId}/remove-video`,
			{
				'video_id': video.id
			})
			.catch(() => { return { status: 'error' } })
	}

	async destroyPlaylist(playlistId: number) {
		console.debug('%crequest made for playlist ' + playlistId, 'color: orange;')
		return await this.provider.delete(`groups/${playlistId}`)
	}

	//#endregion

}